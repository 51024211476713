<template>

    <div>
        <div class="c-elearning-video">
            <transition name="fade-in">
                <div v-if="!isPreExamFinished" class="c-elearning-video__cover">
                    <button
                        class="btn btn-primary btn-lg"
                        @click.prevent="startPreExam"
                    >
                        <icon glyph="play-circle" size="xl" class="mr-2" />
                        {{ $t('Elearning.startElearning') }}
                    </button>
                </div>
            </transition>

            <video-player
                ref="player"
                :video="video"
                :controls="false"
                @duration-changed="setPlaybackDuration"
                @time-changed="videoTimeChanged"
                @state-changed="videoStateChanged"
            />

            <transition name="fade-in">
                <div v-if="isPreExamFinished" class="text-center mt-2">
                    <div
                        ref="progressBarContainer"
                        :class="{
                            'progress': true,
                            'cursor-pointer': isAccreditator && !isSeeking,
                            'cursor-grabbing': isAccreditator && isSeeking,
                        }"
                        :style="{
                            'background-color': isAccreditator ? '#ccc' : '#e9ecef',
                        }"
                        @click="progressBarSeek"
                        @mousedown="progressBarStartSeek"
                        @mouseup="progressBarStopSeek"
                        @mouseleave="progressBarStopSeek"
                        @mousemove="progressBarMoveSeek"
                    >
                        <div class="progress-bar" :style="{width: playbackPercent}"></div>
                    </div>

                    <button class="btn btn-primary mt-3" @click.prevent="toggleVideoPlayback">
                        <icon :glyph="(isVideoPlaying) ? 'pause' : 'play'" is-solid />
                    </button>

                    <p class="mt-2">
                        <strong>{{ playbackSeconds | timeFromSeconds }}</strong>
                        <span class="text-muted">&nbsp;/&nbsp;</span>
                        {{ playbackDuration | timeFromSeconds }}
                    </p>
                </div>
            </transition>
        </div>

        <template v-if="preExamQuestions">
            <!--
                Pre exam modal
            -->
            <elearning-exam-modal
                ref="preExam"
                name="pre-exam"
                :uuid="accreditationUuid"
                :title="$t('Elearning.preExam')"
                :questions="preExamQuestions"
                :overview="$t('Elearning.accreditationProcess')"
                @finished="preExamFinished"
            />

            <!--
                Post exam modal - same questions as pre-exam but different finnish
            -->
            <elearning-exam-modal
                ref="postExam"
                name="post-exam"
                :click-to-close="false"
                :uuid="accreditationUuid"
                :title="$t('Elearning.postExam')"
                :questions="preExamQuestions"
                @finished="postExamFinished"
            />
        </template>


        <!--
            During exam modal
        -->
        <modal
            width="600"
            height="auto"
            scrollable
            adaptive
            :name="duringExamQuestionModalName"
        >
            <div v-if="currentDisplayableQuestion" class="b-modal-content">
                <h4>{{ currentDisplayableQuestion.question }}</h4>

                <form-radio-group
                    v-model="duringExamModel[currentDisplayableQuestion.sort]"
                    text-field="answer"
                    value-field="id"
                    :options="currentDisplayableQuestion.accreditation_answers"
                />

                <div class="text-center mt-4">
                    <button
                        class="btn btn-success"
                        :disabled="!duringExamModel[currentDisplayableQuestion.sort]"
                        @click.prevent="answerDuringExamQuestion"
                    >{{ $t('FormFields.submit') }}</button>
                </div>
            </div>
        </modal>


        <!--
            Exam finished modal
        -->
        <modal
            width="600"
            height="auto"
            scrollable
            adaptive
            :click-to-close="false"
            :name="examFinishedModalName"
        >
            <div class="b-modal-content text-center">
                <h4 class="mb-4">{{ $t('Elearning.examFinished') }}</h4>

                <transition name="scale-in">
                    <div v-if="isExamResultsLoading">
                        <loader />
                        <p>{{ $t('Elearning.examLoadingResults') }}</p>
                    </div>
                    <div v-else-if="examResults">
                        <template v-if="examResults.success">
                            <h2 class="mt-3">{{ $t('Elearning.examPassed') }}</h2>
                            <h1 class="text-success my-4" v-if="examResults.score">{{ examResults.score }}%</h1>
                            <button class="btn btn-primary" @click.prevent="closeModal(examFinishedModalName)">{{ $t('Elearning.examComplete') }}</button>
                        </template>

                        <template v-else>
                            <h2 class="mt-3">{{ $t('Elearning.examFailed') }}</h2>
                            <h1 class="text-danger my-4" v-if="examResults.score">{{ examResults.score }}%</h1>

                            <div v-if="examResults.attempts_left >= 0">
                                <button class="btn btn-primary" v-if="examResults.attempts_left > 0" @click.prevent="startPostExam">
                                    {{ $t('Elearning.examRetake') }}
                                </button>
                                <button class="btn btn-primary" v-else-if="examResults.attempts_left === 0" @click.prevent="watchAgain">
                                    {{ $t('Elearning.watchAgain') }}
                                </button>
                                <p v-if="examResults.attempts_left >= 0">{{ $t('Elearning.attemptsLeft') }}: {{ examResults.attempts_left }}</p>
                            </div>
                            <div class="w-50 m-auto text-center" v-else>
                                <h5 class="p-1">{{ $t('Elearning.noAttemptsLeft') }}</h5>
                                <a href="/elearning/" class="btn btn-primary text-white">{{ $t('Elearning.goBackToListing') }}</a>
                            </div>
                        </template>
                    </div>
                </transition>
            </div>
        </modal>
    </div>

</template>


<script>

import { sortBy } from 'lodash-es';
import Service from '../../service.js';

import VideoPlayer from '../Video/VideoPlayer.vue';
import FormRadioGroup from '../Forms/FormRadioGroup.vue';
import ElearningExamModal from './ElearningExamModal.vue';
import ModalActionButtons from '../Common/ModalActionButtons.vue';

export default {
    name: 'ElearningVideo',

    components: {
        VideoPlayer,
        FormRadioGroup,
        ElearningExamModal,
        ModalActionButtons,
    },

    props: {
        video: Object,
        accreditationUuid: String,
        preExamQuestions: Array,
        duringExamQuestions: {
            type: Array,
            required: true,
        },
        isAlreadyCompleted: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isAnswerSending: false,
            isOverviewVisited: false,
            isPreExamFinished: false,
            isVideoPlaying: false,
            isExamResultsLoading: false,
            playbackTime: null,
            playbackDuration: 0,
            duringExamModel: [],
            duringExamQuestionModalName: 'during-exam-modal',
            examFinishedModalName: 'exam-finished-modal',
            preExamResults: null,
            examResults: null,
            examResultSuccessThreshold: .7,
            isAccreditator: siteData.user.info.isAccreditator || false,
            isSeeking: false,
            seekingPercentage: 0,
        }
    },

    computed: {
        /**
         * @returns {String} number with percent character at the end.
         */
        playbackPercent() {
            let percentage = (this.playbackTime) ? this.playbackTime.percent * 100 : 0;
            if (this.isSeeking) {
                percentage = this.seekingPercentage;
            }

            return `${percentage}%`;
        },

        playbackSeconds() {
            return (this.playbackTime) ? this.playbackTime.seconds : 0;
        },

        /**
         * The elearning is completed when it was already completed
         * or when the last result was successfull
         */
        isCompleted() {
            return this.isAlreadyCompleted || (this.examResults && this.examResults.success === true);
        },

        /**
         * Sort questions by time they should appear.
         */
        duringExamQuestionsSorted() {
            return sortBy(this.duringExamQuestions, ({ show_time }) => show_time).reverse();
        },

        /**
         * Pick the data of the question that fits into the timeline spot by its 'show_time' key.
         * @returns {Object|undefined}
         */
        currentDisplayableQuestion() {
            return this.duringExamQuestionsSorted.filter(({ show_time }) => show_time < this.playbackSeconds)[0];
        },
    },

    watch: {
        playbackSeconds(currentSeconds, previousSeconds) {
            if (
                this.playbackDuration > 0 &&
                currentSeconds > (this.playbackDuration - this.video.tolerance_seconds)
            ) {
                this.startPostExam();
            }

            this.isVideoPlaying = (previousSeconds < currentSeconds);
        },

        /**
         * Display question modal when specified time is approached by video player.
         */
        currentDisplayableQuestion(questionCurrent) {
            if (
                this.isVideoPlaying &&
                questionCurrent &&
                !questionCurrent.shown
            ) {
                this.showDuringExamQuestion();
                questionCurrent.shown = true;
            }
        },
    },

    methods: {
        startPreExam() {
            if (this.preExamQuestions && (!this.isCompleted || this.isAccreditator)) {
                this.$refs.preExam.start();
            } else {
                this.isPreExamFinished = true;
            }
        },

        async preExamFinished(data) {
            this.preExamResults = await Service.setAccreditationPreEvaluation(data);
            this.isPreExamFinished = true;
            this.$refs.preExam.hide();
        },

        startPostExam() {
            if (this.preExamQuestions && (!this.isCompleted || this.isAccreditator)) {
                this.$modal.hide(this.examFinishedModalName);
                this.$refs.player.pause();
                this.$refs.postExam.start();
            }
        },

        async postExamFinished(data) {
            this.isExamResultsLoading = true;

            this.$refs.postExam.hide();
            this.$modal.show(this.examFinishedModalName);

            this.examResults = await Service.setAccreditationPostEvaluation(data);

            this.isExamResultsLoading = false;
        },

        videoTimeChanged(time) {
            this.playbackTime = time;
        },

        videoStateChanged(state) {
            this.isVideoPlaying = state;
        },

        seekingStateChanged(state) {
            this.isSeeking = state;
        },

        toggleVideoPlayback() {
            this.$refs.player.toggle();
        },

        setPlaybackDuration(duration) {
            this.playbackDuration = duration;
        },

        showDuringExamQuestion(question) {
            if (!this.isCompleted || this.isAccreditator) {
                this.$modal.show(this.duringExamQuestionModalName);
                this.$refs.player.pause();
            }
        },

        answerDuringExamQuestion() {
            this.$modal.hide(this.duringExamQuestionModalName);
            this.$refs.player.play();
        },

        closeModal(modalName) {
            this.$modal.hide(modalName);
        },

        watchAgain() {
            this.$modal.hide(this.examFinishedModalName);
            this.duringExamModel = [];
            this.$refs.player.replay();
        },

        progressBarSeek(e) {
            if (!this.isAccreditator) {
                return;
            }

            // the amount of pixels between the left side of the browser and the left side of the element
            const positionFromLeftOfScreen = this.$refs.progressBarContainer.getBoundingClientRect().left;

            // the amount of pixels from the left of the element the user clicked
            const clickedPositionInElement = e.clientX - positionFromLeftOfScreen;

            // the fraction that the pixels represent
            const clickedFractionOfFullProgressBar = clickedPositionInElement / this.$refs.progressBarContainer.clientWidth;

            // Update progress bar now, otherwise we have to wait for buffering after setting current time
            this.seekingPercentage = clickedFractionOfFullProgressBar * 100;

            // get the seconds that the position represents
            const clickedTime = clickedFractionOfFullProgressBar * this.playbackTime.duration;

            this.$refs.player.setCurrentTime(clickedTime);
        },

        progressBarStartSeek() {
            this.isSeeking = true;
        },

        progressBarStopSeek() {
            this.isSeeking = false;
        },

        progressBarMoveSeek(e) {
            if (this.isSeeking) {
                this.progressBarSeek(e);
            }
        },
    },
}

</script>

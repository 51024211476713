export default () => {
    return {
        "Diseases": {
            "gaucher": {
                "freeDiagnosticTest": "Request your Disha Diagnostic kit for Gaucher Disease.",
                "personImage": "/site/templates/img/diseases/in/gaucher.jpg",
            },
            "fabry": {
                "freeDiagnosticTest": "Request your Disha Diagnostic kit for Fabry Disease.",
                "personImage": "/site/templates/img/diseases/in/fabry.jpg",
            },
            "pompe": {
                "freeDiagnosticTest": "Request your Disha Diagnostic kit for Pompe Disease.",
                "personImage": "/site/templates/img/diseases/in/pompe.jpg",
            },
            "mps1": {
                "freeDiagnosticTest": "Request your Disha Diagnostic kit for MPS.",
                "personImage": "/site/templates/img/diseases/in/mps1.jpg",
            },
        },
    }
}

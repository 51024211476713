var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-form-radio-group" },
    _vm._l(_vm.options, function(option, optionIndex) {
      return _c("div", { key: optionIndex, staticClass: "form-check" }, [
        _c("label", { staticClass: "form-check-label" }, [
          _c("input", {
            staticClass: "form-check-input",
            attrs: { type: "radio", name: _vm.name },
            domProps: {
              value: option[_vm.valueField] || option[_vm.textField],
              checked:
                _vm.value === (option[_vm.valueField] || option[_vm.textField])
            },
            on: {
              change: function($event) {
                return _vm.$emit(
                  "input",
                  option[_vm.valueField] || option[_vm.textField]
                )
              }
            }
          }),
          _vm._v(
            "\n            " + _vm._s(option[_vm.textField]) + "\n        "
          )
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
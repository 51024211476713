<template>

    <card
        :href="videoDetailsUrl"
        :title="video.title"
        :cover-image="video.thumbnail_url"
        :is-favorite="video.is_favorite"
        :is-covered="isCovered"
        :info-list="[
            video.speaker,
            formatDate(video.event_date),
        ]"
        cover-icon="play"
        use-favorites
        only-logged-in
        @favorite-change="favoriteVideo()"
    />

</template>


<script>

import Service from '../../service';
import { formatDate } from '../../utility/filters.js';

import Card from '../Common/Card.vue';

export default {
    name: 'VideoCard',

    components: {
        Card,
    },

    props: {
        video: {
            type: Object,
            required: true
        },
        isCovered: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            favoriteHovered: false,
        };
    },

    computed: {
        videoDetailsUrl() {
            return window.siteData.videoDetailsPageUrl + this.video.id;
        },
    },

    methods: {
        formatDate,

        async favoriteVideo() {
            // We presume that action will be successfull to have better user experience
            this.$set(this.video, 'is_favorite', !this.video.is_favorite);

            const response = await Service.setFavoriteVideo(this.video.id);

            // Action failed so the state should be reverted
            if (!response.data) {
                this.video.is_favorite = !this.video.is_favorite;
            }

            this.$emit('change-favorite');

            window.GlobalEventBus.$emit('updateFavoriteVideosCount');
        },
    }
}

</script>

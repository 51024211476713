<template>

    <modal
        width="500"
        height="auto"
        :name="modalName"
        :scrollable="true"
        :adaptive="true"
    >
        <modal-action-buttons
            :close-modal="modalName"
        />

        <div class="b-modal-content">
            <login-form
                v-if="loginVisible"
                @forgot-password="passwordReset"
                @register="register"
            />

            <reset-password-form
                v-if="resetVisible"
                @go-back="forgotPasswordBack"
            />

            <set-password
                v-if="setPasswordVisible"
            />
        </div>
    </modal>

</template>


<script>

import LoginForm from './LoginForm.vue';
import ResetPasswordForm from './ResetPasswordForm.vue';
import SetPassword from '../SetPassword/SetPasswordForm.vue';
import ModalActionButtons from '../Common/ModalActionButtons.vue';

export default {
    name: 'LoginModal',

    components: {
        LoginForm,
        ResetPasswordForm,
        SetPassword,
        ModalActionButtons,
    },

    data() {
        return {
            modalName: 'login-modal',
            setPasswordVisible: false,
            loginVisible: true,
            resetVisible: false,
        }
    },

    methods: {
        passwordReset() {
            this.loginVisible = false;
            this.resetVisible = true;
        },

        forgotPasswordBack() {
            this.loginVisible = true;
            this.resetVisible = false;
        },

        register() {
            this.$modal.hide(this.modalName);
            this.$modal.show('register-modal');
        },
    },
}

</script>

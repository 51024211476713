<template>

    <!--
        Progress indicator
    -->
    <ul class="b-progress-steps">
        <li
            v-for="step of stepsNumber"
            :key="step"
            :class="{
                'is-done': (step < currentStep),
                'is-current': (step == currentStep),
            }"
        >
            <span>
                <transition name="scale-in">
                    <i v-if="stepsChecked.indexOf(step) > -1" class="fa fa-check b-progress-steps__check"></i>
                </transition>
            </span>
        </li>
    </ul>

</template>


<script>

export default {
    props: {
        stepsNumber: {
            type: Number,
            required: true
        },
        currentStep: {
            type: Number,
            default: 1,
        },
        stepsChecked: {
            type: Array, // Array of numbers
            default: () => [],
        },
    },
}

</script>

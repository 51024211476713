<template>

    <div class="text-center">
        <div class="accent-icon">
            <img class="icon" src="/site/templates/img/general/rating-survey-start.svg">
        </div>

        <h4>{{ $t('Rating.welcomeTitle') }}</h4>

        <p>
            {{ $t('Rating.welcomeText') }}
        </p>

        <div class="buttons">
            <div>
                <button class="btn btn-primary" @click="startRate">
                    {{ $t('Rating.btnStartRate') }}
                </button>
            </div>

            <div class="mt-3">
                <button class="btn" @click="denyRating">
                    {{ $t('Rating.btnDenyRate') }}
                </button>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: 'WelcomeScreen',

    methods: {
        denyRating() {
            this.$emit('closeModal');
            sessionStorage.setItem("disableRatingModal", "true");
        },

        startRate() {
            this.$emit('startRate');
        },
    }
}

</script>

import { padStart } from 'lodash-es';

const padNumToTwo = (num) => padStart(num, 2, '0');

export const formatDate = (utcDate) => {
    return (utcDate)
        ? new Date(utcDate).toLocaleString(window.navigator.language || 'en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        })
        : '-';
};

/**
 * Convers large number of seconds to human readable format: HH:mm:ss
 */
export const timeFromSeconds = (secondsTotal) => {
    const secNum = parseInt(secondsTotal, 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor((secNum - (hours * 3600)) / 60);
    const seconds = secNum - (hours * 3600) - (minutes * 60);

    return `${padNumToTwo(hours)}:${padNumToTwo(minutes)}:${padNumToTwo(seconds)}`;
};

<template>

    <div class="mb-5">
        <tile-list-page
            :page-title="pageTitle"
            :headline="headline"
            :list="diseasesTranslated"
            @tile-click-action="openRequestModal"
        >
            <loader
                v-if="!isDataFetched"
                class="mt-5"
            />
            <message-box
                v-else-if="!diseases"
                :title="$t('Diseases.listEmpty')"
                icon="times-circle"
            />
        </tile-list-page>

        <slot />

        <modal
            :name="requestModalName"
            :width="400"
            height="auto"
        >
            <modal-action-buttons :close-modal="requestModalName" />

            <div class="b-modal-content text-center">
                <transition name="fade-in">
                    <div v-if="isRequestInProgress">
                        <p>{{ $t('Common.sending') }}</p>
                        <loader />
                    </div>

                    <message-box
                        v-else-if="!isRequestMade"
                        :message="confirmationMessage"
                    >
                        <button @click.prevent="closeRequestModal" class="btn" v-html="$t('Common.no')"></button>
                        <button @click.prevent="submitRequest" class="btn btn-success" v-html="$t('Common.yes')"></button>
                    </message-box>

                    <template v-else-if="isRequestSuccessfull">
                        <message-box
                            v-if="isSouthAfrica"
                            :title="$t('FormFields.requestSubmittedThankYou')"
                            :message="$t('Common.emailSent') + '<br><br>' + $t('GetInTouch.southAfricanSpecialistWillContact')"
                            icon="check-circle"
                            icon-class="text-success"
                        />

                        <message-box
                            v-else
                            :title="$t('FormFields.requestSubmittedThankYou')"
                            :message="$t('Common.emailSent') + '<br><br>' + $t('GetInTouch.specialistWillContact')"
                            icon="check-circle"
                            icon-class="text-success"
                        />
                    </template>

                    <message-box
                        v-else-if="!isRequestSuccessfull"
                        :title="$t('FormFields.requestFailed')"
                        :message="$t('Common.tryAgain')"
                    />
                </transition>
            </div>
        </modal>
    </div>

</template>


<script>

import axios from 'axios';
import apiUrl from '../../utility/apiUrl';
import currentUser from '../../utility/currentUser.js';

import TileListPage from '../Tiles/TileListPage.vue';
import ModalActionButtons from '../Common/ModalActionButtons.vue';

export default {
    name: 'ConsultationRequest',

    components: {
        TileListPage,
        ModalActionButtons,
    },

    props: {
        pageTitle: String,
        headline: String,
        consultationTypeId: {
            type: Number,
            default: 1,
        },
    },

    data() {
        return {
            currentUser,
            isDataFetched: false,
            isRequestInProgress: false,
            isRequestMade: false,
            isRequestSuccessfull: false,
            selectedDiseaseId: null,
            requestModalName: 'consulatation-request-modal',
            regions: null,
            diseases: null,
            consultationTypes: null,
            submitEndpointUrl: apiUrl('add-consultation-request'),
            isSouthAfrica: window.siteData.currentLanguage === 'sa',
            /**
             * Hardcoded map of data assigned to each of the diseases.
             * Each of the keys is mapped to disease ID that comes from
             * Video Channel Manager.
             * @todo
             */
            diseasesInfoMap: {
                // Gaucher
                1: {
                    headline: this.getTranslatedDiseaseName('gaucher'),
                    summary: this.getTranslatedConsultationSummary('gaucher'),
                    image: this.getTranslatedPersonImage('gaucher'),
                },
                // Fabry
                2: {
                    headline: this.getTranslatedDiseaseName('fabry'),
                    summary: this.getTranslatedConsultationSummary('fabry'),
                    image: this.getTranslatedPersonImage('fabry'),
                    theme: 'pink',
                },
                // Pompe
                3: {
                    headline: this.getTranslatedDiseaseName('pompe'),
                    summary: this.getTranslatedConsultationSummary('pompe'),
                    image: this.getTranslatedPersonImage('pompe'),
                    theme: 'orange',
                },
                // MPS 1
                4: {
                    headline: this.getTranslatedDiseaseName('mps1'),
                    summary: this.getTranslatedConsultationSummary('mps1'),
                    image: this.getTranslatedPersonImage('mps1'),
                    theme: 'gray',
                },
            },
        };
    },

    computed: {
        diseasesTranslated() {
            return (this.diseases)
                ? this.diseases
                    .map(({ id }) => {
                        const diseaseInfo = this.diseasesInfoMap[id];

                        return (diseaseInfo)
                            ? { id, ...diseaseInfo }
                            : null;
                    })
                    .filter((disease) => (disease))
                : [];
        },

        confirmationMessage() {
            switch (this.consultationTypeId) {
                case 3:
                    return this.$i18n.i18next.t('Diagnostics.confirmContactSpecialist');
                default:
                    return this.$i18n.i18next.t('Diagnostics.confirmRequestTest');
            }
        },
    },

    methods: {
        fetchDiseases() {
            return axios.get(apiUrl('get-diseases'))
                .then(response => {
                    if (response.data.success) {
                        this.diseases = response.data.data;
                    }
                });
        },

        openRequestModal(diseaseId) {
            if (!diseaseId) {
                return false;
            } else if (!this.currentUser) {
                return this.$modal.show('login-modal');
            } else {
                this.selectedDiseaseId = diseaseId;
                return this.$modal.show(this.requestModalName);
            }
        },

        closeRequestModal() {
            this.$modal.hide(this.requestModalName);
        },

        submitRequest() {
            if (!this.selectedDiseaseId || !this.consultationTypeId || this.isRequestMade) {
                return false;
            }

            this.isRequestInProgress = true;

            return axios.post(apiUrl('add-consultation-request'), {
                disease_id: this.selectedDiseaseId,
                consultation_type_id: this.consultationTypeId,
            })
                .then(response => {
                    this.selectedDiseaseId = null;
                    this.isRequestMade = true;
                    this.isRequestInProgress = false;
                    this.isRequestSuccessfull = (response && response.data.success);
                });
        },

        getTranslatedDiseaseName(diseaseSlug) {
            return this.$i18n.i18next.t(`Diseases.${diseaseSlug}.fullName`);
        },

        getTranslatedPersonImage(diseaseSlug) {
            return this.$i18n.i18next.t(`Diseases.${diseaseSlug}.personImage`);
        },

        getTranslatedConsultationSummary(diseaseSlug) {
            switch (this.consultationTypeId) {
                case 1:
                    return this.$i18n.i18next.t(`Diseases.${diseaseSlug}.freeDiagnosticTest`);
                case 2:
                    return this.$i18n.i18next.t('Diseases.moreInformationInludingBrochure');
                case 3:
                    return this.$i18n.i18next.t(`Diseases.${diseaseSlug}.contactSpecialist`);
                case 4:
                    return this.$i18n.i18next.t('GetInTouch.discussCase');
            }
        },
    },

    created() {
        Promise.all([
            this.fetchDiseases(),
        ])
            .then(results => {
                this.isDataFetched = true;
            });
    },
}

</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "page-links" } },
    [
      _c("subpage-banner", {
        attrs: {
          header: _vm.pageTitle,
          "banner-image-src": [
            { size: 1920, src: "/site/templates/img/general/top-banner.jpg" },
            {
              size: 768,
              src: "/site/templates/img/general/ipad-top-banner.jpg"
            },
            {
              size: 375,
              src: "/site/templates/img/general/mobile-top-banner.jpg"
            }
          ]
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "container-xl mt-5" }, [
        _vm.headline
          ? _c("h4", {
              staticClass: "text-center mb-4",
              domProps: { innerHTML: _vm._s(_vm.headline) }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row mt-5" },
          _vm._l(_vm.list, function(link, index) {
            return _c(
              "div",
              { key: index, staticClass: "col-md-6 col-lg-3 mt-3" },
              [
                _c("strong", [_vm._v(_vm._s(link.title))]),
                _c("br"),
                _vm._v(" "),
                _c("a", { attrs: { href: link.url, target: "_blank" } }, [
                  _vm._v(_vm._s(link.url))
                ])
              ]
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
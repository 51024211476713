<template>

    <div class="c-form-radio-group">
        <div
            v-for="(option, optionIndex) in options"
            class="form-check"
            :key="optionIndex"
        >
            <label class="form-check-label">
                <input
                    class="form-check-input"
                    type="radio"
                    :name="name"
                    :value="option[valueField] || option[textField]"
                    :checked="value === (option[valueField] || option[textField])"
                    @change="$emit('input', option[valueField] || option[textField])"
                >
                {{ option[textField] }}
            </label>
        </div>
    </div>

</template>


<script>

export default {
    name: 'FormRadioGroup',

    props: {
        options: Array,
        name: String,
        value: [String, Number],
        valueField: {
            type: String,
            default: 'value'
        },
        textField: {
            type: String,
            default: 'text'
        },
    },
}

</script>

<template>
    <modal
        width="500"
        height="auto"
        class="c-exit-modal"
        :name="modalName"
        :scrollable="true"
        v-if="isUk"
    >
        <modal-action-buttons
            :close-modal="modalName"
        />

        <div class="b-modal-content">
            <h4 class="mb-4 mr-4 text-center mr-md-0">
                {{ $t('ExitModal.nowLeaving', { hostname }) }}
            </h4>
            <div v-html="$t('ExitModal.content')"></div>
            <div class="text-center">
                <a @click="hideModal" :href="link" class="btn btn-primary" target="_BLANK" data-no-exit-modal="true">
                    {{ $t('ExitModal.proceed') }}
                </a>
            </div>
        </div>
    </modal>
</template>

<script>
import ModalActionButtons from '../Common/ModalActionButtons.vue';

export default {
    name: 'ExitModal',
    components: {
        ModalActionButtons,
    },
    data() {
        return {
            modalName: 'exit-modal',
            link: '',
            hostname: window.location.hostname,
            isUk: window.siteData.currentLanguage === 'uk',
        };
    },
    mounted () {
        // UK only feature
        if (!this.isUk) {
            return;
        }

        // Listen for body clicks and check if it is an a tag because adding this listener
        // to all a tags would not work with links that are added later by vue
        document.body.addEventListener('click', (e) => {
            if (
                // Clicked element is an <a></a> tag
                e.target && e.target.nodeName === 'A' &&
                // Clicked element is outbound
                e.target.hostname && e.target.hostname !== location.hostname &&
                // Clicked element does not have the data-no-exit-modal attribute
                e.target.getAttribute('data-no-exit-modal') === null
            ) {
                e.preventDefault();
                this.showModalWithLink(e.target.href);
            }
        });
    },
    methods: {
        showModalWithLink(link) {
            this.link = link;
            this.$modal.show(this.modalName);
        },
        hideModal() {
            this.$modal.hide(this.modalName);
        },
    },
};
</script>

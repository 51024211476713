var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      staticClass: "ratings-wrapper",
      attrs: {
        width: "800",
        height: "auto",
        scrollable: "",
        adaptive: "",
        name: _vm.modalName,
        "pivot-y": 0.1
      }
    },
    [
      _c("modal-action-buttons", { attrs: { "close-modal": _vm.modalName } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "b-modal-content" },
        [
          _c(_vm.activeScreen, {
            tag: "component",
            on: {
              startRate: _vm.startRate,
              closeModal: _vm.closeModal,
              endRate: _vm.endRate
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
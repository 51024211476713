/**
 * This file contains components that should be available globally - without
 * need of importing them directly in other components.
 */

import Vue from 'vue/dist/vue';


/**
 * Header & Footer
 */

import SiteHeader from './components/SiteHeader/SiteHeader.vue';
Vue.component(SiteHeader.name, SiteHeader);

import SiteFooter from './components/SiteFooter/SiteFooter.vue';
Vue.component(SiteFooter.name, SiteFooter);


/**
 * Modals
 */

import LoginModal from './components/Login/LoginModal.vue';
Vue.component(LoginModal.name, LoginModal);

import RegisterModal from './components/Register/RegisterModal.vue';
Vue.component(RegisterModal.name, RegisterModal);

import UserAccountModal from './components/UserAccount/UserAccountModal.vue';
Vue.component(UserAccountModal.name, UserAccountModal);

import NotificationsModal from './components/Notifications/NotificationsModal.vue';
Vue.component(NotificationsModal.name, NotificationsModal);

import RatingModal from './components/Rating/RatingModal.vue';
Vue.component(RatingModal.name, RatingModal);

import ExitModal from './components/ExitModal/ExitModal.vue';
Vue.component(ExitModal.name, ExitModal);

import SelectCountryModal from './components/SelectCountry/SelectCountryModal.vue';
Vue.component(SelectCountryModal.name, SelectCountryModal);

import RedirectLegacyCountryModal from './components/RedirectLegacyCountry/RedirectLegacyCountryModal.vue';
Vue.component(RedirectLegacyCountryModal.name, RedirectLegacyCountryModal);

/**
 * Pages
 */

import Home from './components/Home/Home.vue';
Vue.component(Home.name, Home);

import SingleTextPage from './components/Common/SingleTextPage.vue';
Vue.component(SingleTextPage.name, SingleTextPage);

import VideoChannel from './components/VideoChannel/VideoChannel.vue';
Vue.component(VideoChannel.name, VideoChannel);

import Links from './components/Links/Links.vue';
Vue.component(Links.name, Links);

import Favorites from './components/Favorites/Favorites.vue';
Vue.component(Favorites.name, Favorites);

import VideoDetails from './components/Video/VideoDetails.vue';
Vue.component(VideoDetails.name, VideoDetails);

import Page404 from './components/404/Page404.vue';
Vue.component(Page404.name, Page404);

import TileListPage from './components/Tiles/TileListPage.vue';
Vue.component(TileListPage.name, TileListPage);

import DiscussCase from './components/DiscussCase/DiscussCase.vue';
Vue.component(DiscussCase.name, DiscussCase);

import ConsultationRequest from './components/ConsultationRequest/ConsultationRequest.vue';
Vue.component(ConsultationRequest.name, ConsultationRequest);


/**
 * Events
 */

import EventsListPage from './components/Events/EventsListPage.vue';
Vue.component(EventsListPage.name, EventsListPage);

import EventDetailsPage from './components/Events/EventDetailsPage.vue';
Vue.component(EventDetailsPage.name, EventDetailsPage);


/**
 * Elearning
 */

import ElearningListPage from './components/Elearning/ElearningListPage.vue';
Vue.component(ElearningListPage.name, ElearningListPage);

import ElearningDetailsPage from './components/Elearning/ElearningDetailsPage.vue';
Vue.component(ElearningDetailsPage.name, ElearningDetailsPage);


/**
 * Common components
 */

import Icon from './components/Common/Icon.vue';
Vue.component(Icon.name, Icon);

import CustomSelect from './components/Common/CustomSelect.vue';
Vue.component(CustomSelect.name, CustomSelect);

import Loader from './components/Common/Loader.vue';
Vue.component(Loader.name, Loader);

import SubpageBanner from './components/SubpageBanner/SubpageBanner.vue';
Vue.component(SubpageBanner.name, SubpageBanner);

import TileList from './components/Tiles/TileList.vue';
Vue.component(TileList.name, TileList);


/**
 * Other app components
 */

import MessageBox from './components/Common/MessageBox.vue';
Vue.component(MessageBox.name, MessageBox);

import ScrollTopBtn from './components/ScrollTopBtn/ScrollTopBtn.vue';
Vue.component(ScrollTopBtn.name, ScrollTopBtn);

import SetPassword from './components/SetPassword/SetPasswordForm.vue';
Vue.component(SetPassword.name, SetPassword);

import NotLoggedUserMessage from './components/Common/NotLoggedUserMessage.vue';
Vue.component(NotLoggedUserMessage.name, NotLoggedUserMessage);

/**
 * Vendor components
 */

import CookieLaw from 'vue-cookie-law';
Vue.component('cookie-law', CookieLaw);

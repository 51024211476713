<template>

    <div id="page-tiles">
        <subpage-banner
            :header="$t('GetInTouch.discussCase')"
        />

        <div class="container-xl mt-5">
            <template v-if="currentUser">
                <div class="u-narrow-content">
                    <form-generator
                        v-if="isDataFetched"
                        :url="submitEndpointUrl"
                        :fields="formFields"
                        :additional-values="formAdditionalValues"
                        :config="{
                            title: $t('GetInTouch.findExpert')
                        }"
                    />

                    <loader
                        v-else
                    />
                </div>
            </template>

            <not-logged-user-message
                v-else
            />
        </div>

    </div>

</template>


<script>

import axios from 'axios';
import apiUrl from '../../utility/apiUrl';
import currentUser from '../../utility/currentUser.js';

import SiteHeader from '../SiteHeader/SiteHeader.vue';
import SubpageBanner from '../SubpageBanner/SubpageBanner.vue';
import FormGenerator from '../Forms/FormGenerator.vue';

/**
 * @todo Temporary solution. This value should be taken from the VCM API.
 */
const DISCUSS_CASE_CONSULTATION_ID = 4;

/**
 * Mapped IDs of the diseases, that comes from Video Channel Manager,
 * to slugs used on front-end translation files.
 */
const diseaseSlugsById = {
    1: 'gaucher',
    2: 'pompe',
    3: 'fabry',
    4: 'mps1',
};

export default {
    name: 'DiscussCase',

    components: {
        SiteHeader,
        SubpageBanner,
        FormGenerator,
    },

    props: {
        list: {
            type: Array,
        },
    },

    data() {
        return {
            currentUser,
            isDataFetched: false,
            regions: null,
            diseases: null,
            consultationTypes: null,
            submitEndpointUrl: apiUrl('add-consultation-request'),
            formFields: {
                country: {
                    type: 'text',
                    label: this.$i18n.i18next.t('GetInTouch.country'),
                    disabled: true,
                },
                disease_id: {
                    type: 'dropdown',
                    label: this.$i18n.i18next.t('GetInTouch.disease'),
                    options: {}, // Fetched
                    required: true,
                },
            },
            formAdditionalValues: {
                consultation_type_id: DISCUSS_CASE_CONSULTATION_ID,
            },
        };
    },

    methods: {
        /**
         * This method fetches list of countries and cities. Some countries
         * has cities and some not. Based on that regions should be a list of
         * cities or just a country.
         */
        fetchCities() {
            return axios.get(apiUrl('get-cities'))
                .then(({ data }) => {
                    if (data && data.success) {
                        this.formFields['country'].default = data.data.country.country_name;

                        if (data.data.cities.length) {
                            this.regions = data.data.cities;
                            const options = {};

                            for (let i = 0; i < this.regions.length; i++) {
                                options[this.regions[i].id] = this.regions[i].name;
                            }

                            this.formFields['city_id'] = {
                                type: 'dropdown',
                                label: this.$i18n.i18next.t('GetInTouch.region'),
                                required: true,
                                options,
                            };
                        }
                    }
                });
        },

        fetchDiseases() {
            return axios.get(apiUrl('get-diseases'))
                .then(({ data }) => {
                    if (data && data.success) {
                        this.diseases = data.data;

                        this.formFields['disease_id'].options = this.diseases.reduce((options, { id, name }) => {
                            options[id] = (diseaseSlugsById[id])
                                ? this.$i18n.i18next.t(`Diseases.${diseaseSlugsById[id]}.fullName`)
                                : name;

                            return options;
                        }, {});
                    }
                });
        },
    },

    created() {
        Promise.all([
            this.fetchCities(),
            this.fetchDiseases(),
        ])
            .then(results => {
                this.isDataFetched = true;
            });
    },
}

</script>

<template>

    <div class="elearning" :class="{ completed: entry.isCompleted, disabled: entry.isDisabled }">
        <card
            :href="elearningDetailsUrl"
            :tag="(entry.accredited) ? $t('Elearning.accredited') : null"
            :title="entry.title"
            :description="entry.description"
            :cover-image="entry.thumbnail_url"
            :marked-by-icon="(entry.finished) ? 'check-circle' : null"
            :marked-by-text="(entry.finished) ? $t('Elearning.finished') : null"
            :is-covered="isCovered"
            cover-icon="play"
            tag-icon="graduation-cap"
            only-logged-in
        >
            <div class="b-accreditation-mark" v-if="entry.is_accredited && !entry.isDisabled">
                <span class="e-accreditation-mark--title" v-if="entry.isCompleted">{{ $t('Elearning.completed').toUpperCase() }}</span>
                <span class="e-accreditation-mark--title" v-else>{{ $t('Elearning.accredited').toUpperCase() }}</span>
                <div class="e-accreditation-mark--stamp">
                    <p>
                        <span class="e-accreditation-mark--stamp-mark">{{ entry.accreditation_points }}</span>
                        <span class="e-accreditation-mark--stamp-note">{{ $t('Elearning.points').toUpperCase() }}</span>
                    </p>
                </div>
            </div>
            <div class="b-accreditation-mark" v-else-if="entry.isDisabled">
                <span class="e-accreditation-mark--title">{{ $t('Elearning.disabled').toUpperCase() }}</span>
            </div>
        </card>
    </div>

</template>


<script>
import i18n from "../../i18n/index";
import { formatDate } from '../../utility/filters.js';

import Card from '../Common/Card.vue';

export default {
    name: 'ElearningCard',

    components: {
        Card,
    },

    props: {
        entry: {
            type: Object,
            required: true,
        },
        isCovered: Boolean,
    },

    computed: {
        elearningDetailsUrl() {
            // If id instead of uuid the entry is a video
            if (!this.entry.uuid && this.entry.id) {
                return `${window.siteData.videoDetailsPageUrl}${this.entry.id}`;
            }

            return (!this.entry.isDisabled) ? `${window.siteData.elearningPageUrl}${this.entry.uuid}`: "";
        },
    },

    methods: {
        formatDate,
    },
};

</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "page-tiles" } },
    [
      _c("subpage-banner", { attrs: { header: _vm.pageTitle } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container-xl mt-5" },
        [
          _vm.headline
            ? _c("h4", {
                staticClass: "mb-4",
                domProps: { innerHTML: _vm._s(_vm.headline) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.list
            ? _c("tile-list", {
                attrs: { list: _vm.list, "tiles-style": _vm.tilesStyle },
                on: {
                  "tile-click-action": function(payload) {
                    return _vm.$emit("tile-click-action", payload)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
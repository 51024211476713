<template>

    <modal
        class="user-account-modal"
        width="800"
        height="auto"
        scrollable
        adaptive
        :name="modalName"
    >
        <modal-action-buttons
            :close-modal="modalName"
        />

        <transition name="fade-in">
            <message-box
                v-if="isLoggedOut"
                class="py-5"
                icon="check-circle"
                icon-class="text-success"
                :title="$t('User.loggedOut')"
            >
                <button v-html="$t('Common.ok')" class="btn btn-primary mt-3" @click.prevent="refreshPage" />
            </message-box>

            <div v-else-if="isLoggingOut" class="text-center py-5">
                <loader />
                <p class="mt-3">{{ $t('User.loggingOut') }}</p>
            </div>

            <div v-else class="b-modal-content">
                <inline-radio-select
                    name="tabs"
                    class="mb-4"
                    :default="activeForm"
                    :options="tabsOptions"
                    @input="tabChange"
                />

                <transition name="fade-in">
                    <user-account-form
                        v-if="activeForm == 'account'"
                        @logout="logout"
                    />

                    <!-- <user-activities v-if="activeForm == 'activities'" /> -->

                    <user-contact-us-form
                        v-else-if="activeForm == 'contact'"
                    />
                </transition>
            </div>
        </transition>
    </modal>

</template>


<script>

import Service from '../../service';
import JanrainIntegration from '../../integrations/janrain';

import ModalActionButtons from '../Common/ModalActionButtons.vue';
import UserAccountForm from './UserAccountForm.vue';
import UserContactUsForm from './UserContactUsForm.vue';
// import UserActivities from './UserActivities.vue';
import InlineRadioSelect from '../Common/InlineRadioSelect.vue';

export default {
    name: 'UserAccountModal',

    components: {
        ModalActionButtons,
        UserAccountForm,
        UserContactUsForm,
        // UserActivities,
        InlineRadioSelect,
    },

    data() {
        return {
            modalName: 'user-account-modal',
            activeForm: 'account',
            tabsOptions: {
                account: this.$i18n.i18next.t('User.myProfile'),
                // activities: this.$i18n.i18next.t('Activities.title'),
                contact: (window.siteData.messagingEnabled)
                    ? this.$i18n.i18next.t('UserAccount.contactUs')
                    : null,
            },
            isLoggingOut: false,
            isLoggedOut: false,
        }
    },

    methods: {
        tabChange(option) {
            this.activeForm = option;
        },

        async logout() {
            this.isLoggingOut = true;

            await Service.userLogout();
            await JanrainIntegration.logout();

            this.isLoggingOut = false;
            this.isLoggedOut = true;

            setTimeout(this.refreshPage, 2000);
        },

        refreshPage() {
            window.location.reload();
        },
    }
}

</script>

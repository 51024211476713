var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "b-progress-steps" },
    _vm._l(_vm.stepsNumber, function(step) {
      return _c(
        "li",
        {
          key: step,
          class: {
            "is-done": step < _vm.currentStep,
            "is-current": step == _vm.currentStep
          }
        },
        [
          _c(
            "span",
            [
              _c("transition", { attrs: { name: "scale-in" } }, [
                _vm.stepsChecked.indexOf(step) > -1
                  ? _c("i", {
                      staticClass: "fa fa-check b-progress-steps__check"
                    })
                  : _vm._e()
              ])
            ],
            1
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { attrs: { id: "video-channel" } },
    [
      _c("subpage-banner", { attrs: { header: _vm.$t("VideoChannel.title") } }),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade-in" } },
        [
          !_vm.currentUser
            ? _c(
                "div",
                { staticClass: "container-xl" },
                [_c("not-logged-user-message")],
                1
              )
            : _vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "container-xl mt-5" },
                  [_c("loader")],
                  1
                )
              : !_vm.videos || !_vm.videos.all
                ? _c("message-box", {
                    attrs: {
                      image: "/site/templates/img/general/coming-soon-home.svg",
                      title: _vm.$t("ChannelComingSoon.willAddSoon"),
                      message: _vm.$t("ChannelComingSoon.letYouKnow")
                    }
                  })
                : _c("div", { staticClass: "container-fluid" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-md-2 t-bg-light pt-5 pb-5 position-relative"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "u-sticky text-center" },
                            [
                              _c(
                                "ul",
                                { staticClass: "b-submenu" },
                                _vm._l(_vm.primaryCategories, function(
                                  category
                                ) {
                                  return _c(
                                    "li",
                                    {
                                      key: category.slug,
                                      class: {
                                        "is-active":
                                          category.slug ===
                                          _vm.currentCategorySlug
                                      }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.setCategory(
                                                category.slug
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(category.title))
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              ),
                              _vm._v(" "),
                              _c("hr"),
                              _vm._v(" "),
                              _vm.additionalCategories &&
                              _vm.additionalCategories.length
                                ? [
                                    _c(
                                      "ul",
                                      { staticClass: "b-submenu" },
                                      _vm._l(_vm.additionalCategories, function(
                                        category
                                      ) {
                                        return _c(
                                          "li",
                                          {
                                            key: category.slug,
                                            class: {
                                              "is-active":
                                                category.slug ===
                                                _vm.currentCategorySlug
                                            }
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.setCategory(
                                                      category.slug
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(_vm._s(category.title))]
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _c("hr")
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _c("filters", {
                                attrs: { videos: _vm.currentVideos },
                                on: { filter: _vm.handleFiltering }
                              })
                            ],
                            2
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-md-10" },
                        [
                          _vm.filterResults
                            ? [
                                _vm.filterResults.length > 0
                                  ? _c("video-list-section", {
                                      attrs: {
                                        title:
                                          _vm.$t("Common.searchResults") +
                                          " (" +
                                          _vm.filterResults.length +
                                          ")",
                                        videos: _vm.filterResults,
                                        "show-video-number": true
                                      }
                                    })
                                  : _c("message-box", {
                                      staticClass: "mt-5",
                                      attrs: {
                                        image:
                                          "/site/templates/img/general/no-results.svg",
                                        title: _vm.$t("Common.noResults")
                                      }
                                    })
                              ]
                            : [
                                _c(
                                  "transition",
                                  { attrs: { name: "fade-in" } },
                                  [
                                    _c("video-list-section", {
                                      key: _vm.currentCategory.slug,
                                      attrs: {
                                        title: _vm.currentCategory.title,
                                        videos: _vm.currentVideos,
                                        "banner-text":
                                          _vm.currentCategory.bannerText,
                                        "banner-image-src": _vm.currentCategory
                                          .bannerImage
                                          ? [
                                              {
                                                src:
                                                  _vm.currentCategory
                                                    .bannerImage
                                              }
                                            ]
                                          : null
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                        ],
                        2
                      )
                    ])
                  ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-subpage-banner",
      class: { "has-image": _vm.bannerImageSrc }
    },
    [
      _c(
        "div",
        { staticClass: "container-xl" },
        [
          _c("div", { staticClass: "c-subpage-banner__content" }, [
            _c("div", [
              _vm.header
                ? _c(
                    "h1",
                    {
                      staticClass: "c-subpage-banner__header",
                      class: { h2: _vm.smaller }
                    },
                    [_vm._v(_vm._s(_vm.header))]
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "c-subpage-banner__buttons" },
            _vm._l(_vm.buttons, function(button, index) {
              return _c(
                "a",
                {
                  key: index,
                  staticClass: "btn btn-raised",
                  class: ["btn-" + button.color],
                  attrs: { href: button.url }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(button.name) +
                      "\n            "
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      ),
      _vm._v(" "),
      _vm.bannerImageSrc
        ? _c(
            "div",
            { staticClass: "c-subpage-banner__image" },
            [
              _c("responsive-image", {
                attrs: { src: _vm.bannerImageSrc, alt: _vm.header || "" }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.success
        ? _c("div", { staticClass: "thank-you-message" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "message",
                domProps: { innerHTML: _vm._s(_vm.$t("SingleVideo.msgThanks")) }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("UserAccount.msgThanks")) +
                    "\n        "
                )
              ]
            )
          ])
        : _c("form-generator", {
            attrs: {
              url: _vm.contactApiUrl,
              fields: _vm.formFields,
              config: {
                title: _vm.$t("UserAccount.contactUs"),
                submitTitle: _vm.$t("Common.send")
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shape" }, [
      _c("img", {
        attrs: { src: "/site/templates/img/general/paper-plane.svg" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
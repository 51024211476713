var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-message-box text-center mt-4 mb-3" },
    [
      _vm.image
        ? _c("img", { attrs: { src: _vm.image } })
        : _vm.icon
          ? _c("icon", {
              class: _vm.iconClass,
              attrs: { glyph: _vm.icon, size: "xxl" }
            })
          : _vm._e(),
      _vm._v(" "),
      _vm.title
        ? _c("h5", { staticClass: "mt-4" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _vm._v(" "),
      _vm.message
        ? _c("p", { domProps: { innerHTML: _vm._s(_vm.message) } })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
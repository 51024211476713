<template>

    <div class="container-xl">
        <h3 v-if="title" v-html="title"></h3>

        <transition name="slide-from-bottom">
            <loader
                v-if="isLoading"
                class="mt-5 mb-5"
            />

            <events-list
                v-else-if="events && events.length"
                :events="eventsLimited"
                :number-of-featured="numberOfFeatured"
            />

            <message-box
                v-else
                :title="$t('Common.emptyList')"
                icon="times-circle"
            />
        </transition>
    </div>

</template>


<script>

import EventsList from './EventsList.vue';

export default {
    components: {
        EventsList,
    },

    props: {
        title: String,
        events: Array,
        isLoading: Boolean,
        limit: Number,
        numberOfFeatured: Number,
    },

    computed: {
        eventsWithDates() {
            return this.events.filter(({ date }) => (date));
        },

        eventsLimited() {
            return (this.limit && this.limit > 0)
                ? [...this.eventsWithDates].splice(0, this.limit)
                : this.eventsWithDates;
        },
    },
}

</script>

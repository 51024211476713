/**
 * @param {String} scriptUrl
 */
export const loadScript = (scriptUrl) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');

        script.onload = resolve;
        script.onerror = (event) => {
            console.error(`Failed to load external script: ${scriptUrl}`);
            reject(event);
        }
        script.src = scriptUrl;

        document.head.appendChild(script);
    });
}



/**
 * Load CSS file without blocking document render. Usefull for loading external resources like icon fonts.
 * @param {String} styleUrl
 */
export const loadCss = (styleUrl) => {
    return new Promise((resolve, reject) => {
        const styleElem = document.createElement('link');

        styleElem.onload = resolve;
        styleElem.onerror = (event) => {
            console.error(`Failed to load external style: ${styleUrl}`);
            reject(event);
        }

        styleElem.rel = 'stylesheet';
        styleElem.href = styleUrl;

        document.head.appendChild(styleElem);
    });
}

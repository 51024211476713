var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("card", {
    attrs: {
      href: _vm.videoDetailsUrl,
      title: _vm.video.title,
      "cover-image": _vm.video.thumbnail_url,
      "is-favorite": _vm.video.is_favorite,
      "is-covered": _vm.isCovered,
      "info-list": [_vm.video.speaker, _vm.formatDate(_vm.video.event_date)],
      "cover-icon": "play",
      "use-favorites": "",
      "only-logged-in": ""
    },
    on: {
      "favorite-change": function($event) {
        return _vm.favoriteVideo()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>

    <div id="page-tiles">
        <subpage-banner
            :header="pageTitle"
        />

        <div class="container-xl mt-5">
            <h4 v-if="headline" v-html="headline" class="mb-4"></h4>

            <tile-list
                v-if="list"
                :list="list"
                :tiles-style="tilesStyle"
                @tile-click-action="payload => $emit('tile-click-action', payload)"
            />

            <slot />
        </div>
    </div>

</template>


<script>

import SiteHeader from '../SiteHeader/SiteHeader.vue';
import SubpageBanner from '../SubpageBanner/SubpageBanner.vue';
import TileList from './TileList.vue';

export default {
    name: 'TileListPage',

    components: {
        SiteHeader,
        SubpageBanner,
        TileList,
    },

    props: {
        pageTitle: {
            type: String,
        },
        headline: {
            type: String,
        },
        list: {
            type: Array,
        },
        tilesStyle: {
            type: String,
        },
    },
}

</script>

<template>

    <div class="thanks-screen">
        <div class="accent-icon">
            <img class="icon" src="/site/templates/img/general/rating-survey-end.svg">
        </div>

        <div class="title-text">
            <span v-html="$t('Rating.thanksTitle')"></span>
            <br>
            {{ $t('Rating.thanksText') }}
        </div>
    </div>

</template>


<script>

export default {
    name: 'ThanksScreen',
}

</script>

<template>

    <section class="c-video-list-section">
        <div v-if="bannerImageSrc" class="c-video-list-section__banner row">
            <h2
                v-if="bannerText"
                v-html="bannerText"
                class="c-video-list-section__banner__text"
            ></h2>
            <responsive-image
                :src="bannerImageSrc"
                :alt="title || ''"
                class="c-video-list-section__banner__image"
            />
        </div>

        <div class="container-xl c-video-list-section__content">
            <h3 v-if="title" v-html="title"></h3>

            <transition name="fade-in">
                <loader v-if="isLoading" class="mt-5 mb-5" />

                <video-list
                    v-else-if="videos && videos.length"
                    :videos="videosLimited"
                    :number-of-featured="numberOfFeatured"
                    @change-favorite="$emit('change-favorite')"
                />

                <message-box
                    v-else
                    :title="$t('Common.emptyList')"
                    icon="times-circle"
                />
            </transition>
        </div>
    </section>

</template>


<script>

import VideoList from './VideoList.vue';
import ResponsiveImage from '../Common/ResponsiveImage.vue';

export default {
    components: {
        VideoList,
        ResponsiveImage,
    },

    props: {
        isLoading: Boolean,
        title: String,
        bannerText: String,
        bannerImageSrc: [String, Array],
        videos: Array,
        limit: Number,
        numberOfFeatured: Number,
    },

    computed: {
        videosLimited() {
            return (this.limit && this.limit > 0)
                ? [...this.videos].splice(0, this.limit)
                : this.videos;
        },
    },
}

</script>

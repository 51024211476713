var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isUk
    ? _c(
        "modal",
        {
          staticClass: "c-exit-modal",
          attrs: {
            width: "500",
            height: "auto",
            name: _vm.modalName,
            scrollable: true
          }
        },
        [
          _c("modal-action-buttons", {
            attrs: { "close-modal": _vm.modalName }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "b-modal-content" }, [
            _c("h4", { staticClass: "mb-4 mr-4 text-center mr-md-0" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.$t("ExitModal.nowLeaving", { hostname: _vm.hostname })
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.$t("ExitModal.content")) }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "text-center" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary",
                  attrs: {
                    href: _vm.link,
                    target: "_BLANK",
                    "data-no-exit-modal": "true"
                  },
                  on: { click: _vm.hideModal }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("ExitModal.proceed")) +
                      "\n            "
                  )
                ]
              )
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
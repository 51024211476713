<template>

    <label class="c-custom-checkbox">
        <input
            class="form-control"
            type="checkbox"
            :id="name"
            :name="name"
            :required="required"
            :checked="checked"
            @change="onChange"
        >

        <span class="c-custom-checkbox__marker"></span>

        <div class="c-custom-checkbox__label" :class="{'font-weight-bold': isLarge}">
            <span v-if="required">*</span>
            <span v-html="label"></span>
        </div>
    </label>

</template>


<script>

export default {
    props: {
        label: String,
        name: String,
        required: Boolean,
        checked: Boolean,
        isLarge: Boolean,
    },

    methods: {
        onChange(event) {
            // Allow of binding v-model to this component
            this.$emit('input', (event.target.checked) ? event.target.value : '');
        },
    },
}

</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-xl" },
    [
      _vm.title
        ? _c("h3", { domProps: { innerHTML: _vm._s(_vm.title) } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "slide-from-bottom" } },
        [
          _vm.isLoading
            ? _c("loader", { staticClass: "mt-5 mb-5" })
            : _vm.events && _vm.events.length
              ? _c("events-list", {
                  attrs: {
                    events: _vm.eventsLimited,
                    "number-of-featured": _vm.numberOfFeatured
                  }
                })
              : _c("message-box", {
                  attrs: {
                    title: _vm.$t("Common.emptyList"),
                    icon: "times-circle"
                  }
                })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
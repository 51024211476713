<template>

    <div
        :class="{'row': !isHorizontal}"
    >
        <template v-for="(event, index) in events">

            <!--
                Featured event row
            -->
            <div
                v-if="numberOfFeatured > 0 && (index < numberOfFeatured)"
                :key="event.uuid || index"
                :class="{'col-xs-12': !isHorizontal}"
                class="mt-3 mb-5"
            >
                <div class="row">
                    <div :class="(event.thumbnail_url) ? 'col-lg-6 pt-4 pb-4' : 'col-12'">
                        <ul class="b-inline-list font-weight-bold text-primary">
                            <li v-if="event.speaker">{{ event.speaker }}</li>
                            <li>{{ formatDate(event.date) }}</li>
                        </ul>
                        <h2 class="mt-4 mb-4">{{ event.title }}</h2>
                        <div class="u-truncate-text" v-html="event.description"></div>
                        <a :href="event.url" class="btn btn-primary mt-4">{{ $t('Common.details') }}</a>
                    </div>
                    <div class="col-lg-6 u-image-cover" v-if="event.thumbnail_url">
                        <img :src="event.thumbnail_url" alt="">
                    </div>
                </div>
            </div>

            <!--
                Regular event card
            -->
            <div
                v-else
                :key="event.uuid || index"
                :class="{'col-md-6 col-xl-4': !isHorizontal}"
                class="mb-4"
            >
                <card
                    :tag="event.type"
                    :title="event.title"
                    :description="event.description"
                    :cover-image="event.thumbnail_url"
                    :info-list="[
                        `${$t('Common.date')}: ${formatDate(event.date)}`,
                        (event.duration) ? `${$t('Common.duration')}: ${event.duration}` : null,
                    ]"
                    :btnText="$t('Common.details')"
                    :btnHref="event.url"
                    :is-horizontal="isHorizontal"
                    target-blank
                    only-logged-in
                />
            </div>
        </template>
    </div>

</template>


<script>

import { formatDate } from '../../utility/filters.js';

import Card from '../Common/Card.vue';

export default {
    components: {
        Card,
    },

    props: {
        events: Array,
        numberOfFeatured: Number,
        isHorizontal: Boolean,
    },

    methods: {
        formatDate,

        eventDetailsUrl(eventUuid) {
            return window.siteData.eventsPageUrl + eventUuid;
        },
    },
}

</script>

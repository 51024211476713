<template>

    <main>
        <not-logged-user-message
            v-if="!currentUser"
            class="mb-5"
        />
        <div v-else>
            <div class="u-medium-content text-center mt-5">
                <h2>{{ $t('Elearning.introText') }}</h2>
            </div>

            <div class="container-xl mt-5 mb-5">
                <hr class="mb-5">

                <transition name="fade-in">
                    <loader v-if="isLoading" />

                    <div v-else-if="elearnings">
                        <div class="row">
                            <div
                                v-for="(entry, entryIndex) in elearnings"
                                class="col-xs-12 col-md-6 mb-4"
                                :key="entry.uuid || entryIndex"
                            >
                                <elearning-card
                                    :entry="entry"
                                    is-covered
                                />
                            </div>
                        </div>
                    </div>

                    <message-box
                        v-else
                        :title="$t('Common.emptyList')"
                        icon="times-circle"
                    />
                </transition>
            </div>
        </div>
    </main>

</template>


<script>

import Service from '../../service';
import currentUser from '../../utility/currentUser.js';
import ElearningCard from './ElearningCard.vue';

export default {
    name: 'ElearningListPage',

    components: {
        ElearningCard,
    },

    data() {
        return {
            isLoading: false,
            elearnings: [],
            currentUser,
        };
    },

    methods: {
        async fetchElearning() {
            this.isLoading = true;
            this.elearnings = await Service.getElearnings();
            this.isLoading = false;
        },
    },

    created() { 
        this.fetchElearning();
    },
};

</script>

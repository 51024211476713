var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "b-modal-content b-modal-content--centered" },
    [
      _c("img", {
        staticClass: "mb-3",
        attrs: {
          src: "/site/templates/img/general/paper-plane.svg",
          alt: "Confirmation sent"
        }
      }),
      _vm._v(" "),
      _c("div", {
        domProps: { innerHTML: _vm._s(_vm.$t("RegisterNoBig.thankYou")) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
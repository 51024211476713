<template>
    <div>
        <subpage-banner smaller></subpage-banner>
        <main class="c-page-404">
            <div class="c-page-404__top">
                <h1>404</h1>
                <h5>{{ $t('NotFound.notFound') }}</h5>
            </div>

            <div class="c-page-404__animation">
                <div class="ghost-copy">
                    <div class="one"></div>
                    <div class="two"></div>
                    <div class="three"></div>
                    <div class="four"></div>
                </div>
                <div class="ghost">
                    <div class="face">
                        <div class="eye"></div>
                        <div class="eye-right"></div>
                        <div class="mouth"></div>
                    </div>
                </div>
                <div class="shadow"></div>
            </div>

            <div class="c-page-404__bottom">
                <button class="btn btn-primary" onClick="location.href='/'">
                    {{ $t('NotFound.back') }}
                </button>
            </div>
        </main>
    </div>
</template>

<script>

export default {
    name: 'Page404',
}

</script>

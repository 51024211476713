var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-filters" }, [
    _c("label", { staticClass: "form-group c-filters__search" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.search,
            expression: "search"
          }
        ],
        staticClass: "form-control",
        attrs: { type: "text", placeholder: _vm.$t("Common.search") },
        domProps: { value: _vm.search },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.search = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _vm.search
        ? _c("i", {
            staticClass: "fas fa-undo",
            on: { click: _vm.clearSearch }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("i", { staticClass: "fas fa-search" })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("custom-select", {
          staticClass: "c-filters__select",
          attrs: {
            name: "disease",
            options: _vm.diseaseOptions,
            label: _vm.$t("Common.filterDisease"),
            "show-reset": true
          },
          model: {
            value: _vm.disease,
            callback: function($$v) {
              _vm.disease = $$v
            },
            expression: "disease"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("custom-select", {
          staticClass: "c-filters__select",
          attrs: {
            name: "year",
            options: _vm.yearOptions,
            label: _vm.$t("Common.filterYear"),
            "show-reset": true
          },
          model: {
            value: _vm.year,
            callback: function($$v) {
              _vm.year = $$v
            },
            expression: "year"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <i
        class="c-icon"
        :class="[iconStyle, fullGlyphName, fullSizeName, (hoverable ? 'fa-hoverable' : '')]"
    >
        <transition name="scale-in">
            <span
                class="c-icon__counter"
                v-if="counter"
            >{{ counter }}</span>
        </transition>
    </i>
</template>


<script>

/**
 * Icon component based on Font Awesome.
 * @link https://fontawesome.com/how-to-use/on-the-web/referencing-icons/basic-use
 */

export default {
    name: 'Icon',

    props: {
        glyph: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            default: null,
        },
        isSolid: {
            type: Boolean,
            default: false,
        },
        isBrand: {
            type: Boolean,
            default: false,
        },
        counter: {
            default: false,
        },
        hoverable: {
            type: Boolean,
        },
    },

    computed: {
        /**
         * Decide if icon should be solid or outline
         */
        iconStyle() {
            if (this.isBrand) {
                return 'fab'; // Brand icon
            }
            else if (this.isSolid) {
                return 'fas'; // Solid - filled in icon
            }
            return 'far'; // Default: outline regular
        },

        /**
         * All Font Awesome icons are prefixes with 'fa'.
         */
        fullGlyphName() {
            return 'fa-' + this.glyph;
        },

        fullSizeName() {
            return (this.size)
                ? 'fa-' + this.size
                : null
        },
    },
}

</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "c-elearning-video" },
        [
          _c("transition", { attrs: { name: "fade-in" } }, [
            !_vm.isPreExamFinished
              ? _c("div", { staticClass: "c-elearning-video__cover" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.startPreExam($event)
                        }
                      }
                    },
                    [
                      _c("icon", {
                        staticClass: "mr-2",
                        attrs: { glyph: "play-circle", size: "xl" }
                      }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("Elearning.startElearning")) +
                          "\n                "
                      )
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("video-player", {
            ref: "player",
            attrs: { video: _vm.video, controls: false },
            on: {
              "duration-changed": _vm.setPlaybackDuration,
              "time-changed": _vm.videoTimeChanged,
              "state-changed": _vm.videoStateChanged
            }
          }),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade-in" } }, [
            _vm.isPreExamFinished
              ? _c("div", { staticClass: "text-center mt-2" }, [
                  _c(
                    "div",
                    {
                      ref: "progressBarContainer",
                      class: {
                        progress: true,
                        "cursor-pointer": _vm.isAccreditator && !_vm.isSeeking,
                        "cursor-grabbing": _vm.isAccreditator && _vm.isSeeking
                      },
                      style: {
                        "background-color": _vm.isAccreditator
                          ? "#ccc"
                          : "#e9ecef"
                      },
                      on: {
                        click: _vm.progressBarSeek,
                        mousedown: _vm.progressBarStartSeek,
                        mouseup: _vm.progressBarStopSeek,
                        mouseleave: _vm.progressBarStopSeek,
                        mousemove: _vm.progressBarMoveSeek
                      }
                    },
                    [
                      _c("div", {
                        staticClass: "progress-bar",
                        style: { width: _vm.playbackPercent }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary mt-3",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.toggleVideoPlayback($event)
                        }
                      }
                    },
                    [
                      _c("icon", {
                        attrs: {
                          glyph: _vm.isVideoPlaying ? "pause" : "play",
                          "is-solid": ""
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "mt-2" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm._f("timeFromSeconds")(_vm.playbackSeconds))
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-muted" }, [_vm._v(" / ")]),
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm._f("timeFromSeconds")(_vm.playbackDuration)
                        ) +
                        "\n                "
                    )
                  ])
                ])
              : _vm._e()
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.preExamQuestions
        ? [
            _c("elearning-exam-modal", {
              ref: "preExam",
              attrs: {
                name: "pre-exam",
                uuid: _vm.accreditationUuid,
                title: _vm.$t("Elearning.preExam"),
                questions: _vm.preExamQuestions,
                overview: _vm.$t("Elearning.accreditationProcess")
              },
              on: { finished: _vm.preExamFinished }
            }),
            _vm._v(" "),
            _c("elearning-exam-modal", {
              ref: "postExam",
              attrs: {
                name: "post-exam",
                "click-to-close": false,
                uuid: _vm.accreditationUuid,
                title: _vm.$t("Elearning.postExam"),
                questions: _vm.preExamQuestions
              },
              on: { finished: _vm.postExamFinished }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            width: "600",
            height: "auto",
            scrollable: "",
            adaptive: "",
            name: _vm.duringExamQuestionModalName
          }
        },
        [
          _vm.currentDisplayableQuestion
            ? _c(
                "div",
                { staticClass: "b-modal-content" },
                [
                  _c("h4", [
                    _vm._v(_vm._s(_vm.currentDisplayableQuestion.question))
                  ]),
                  _vm._v(" "),
                  _c("form-radio-group", {
                    attrs: {
                      "text-field": "answer",
                      "value-field": "id",
                      options:
                        _vm.currentDisplayableQuestion.accreditation_answers
                    },
                    model: {
                      value:
                        _vm.duringExamModel[
                          _vm.currentDisplayableQuestion.sort
                        ],
                      callback: function($$v) {
                        _vm.$set(
                          _vm.duringExamModel,
                          _vm.currentDisplayableQuestion.sort,
                          $$v
                        )
                      },
                      expression:
                        "duringExamModel[currentDisplayableQuestion.sort]"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-center mt-4" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: {
                          disabled: !_vm.duringExamModel[
                            _vm.currentDisplayableQuestion.sort
                          ]
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.answerDuringExamQuestion($event)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("FormFields.submit")))]
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            width: "600",
            height: "auto",
            scrollable: "",
            adaptive: "",
            "click-to-close": false,
            name: _vm.examFinishedModalName
          }
        },
        [
          _c(
            "div",
            { staticClass: "b-modal-content text-center" },
            [
              _c("h4", { staticClass: "mb-4" }, [
                _vm._v(_vm._s(_vm.$t("Elearning.examFinished")))
              ]),
              _vm._v(" "),
              _c("transition", { attrs: { name: "scale-in" } }, [
                _vm.isExamResultsLoading
                  ? _c(
                      "div",
                      [
                        _c("loader"),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("Elearning.examLoadingResults")))
                        ])
                      ],
                      1
                    )
                  : _vm.examResults
                    ? _c(
                        "div",
                        [
                          _vm.examResults.success
                            ? [
                                _c("h2", { staticClass: "mt-3" }, [
                                  _vm._v(_vm._s(_vm.$t("Elearning.examPassed")))
                                ]),
                                _vm._v(" "),
                                _vm.examResults.score
                                  ? _c(
                                      "h1",
                                      { staticClass: "text-success my-4" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.examResults.score) + "%"
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.closeModal(
                                          _vm.examFinishedModalName
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("Elearning.examComplete"))
                                    )
                                  ]
                                )
                              ]
                            : [
                                _c("h2", { staticClass: "mt-3" }, [
                                  _vm._v(_vm._s(_vm.$t("Elearning.examFailed")))
                                ]),
                                _vm._v(" "),
                                _vm.examResults.score
                                  ? _c(
                                      "h1",
                                      { staticClass: "text-danger my-4" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.examResults.score) + "%"
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.examResults.attempts_left >= 0
                                  ? _c("div", [
                                      _vm.examResults.attempts_left > 0
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-primary",
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.startPostExam(
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "Elearning.examRetake"
                                                    )
                                                  ) +
                                                  "\n                            "
                                              )
                                            ]
                                          )
                                        : _vm.examResults.attempts_left === 0
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-primary",
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.watchAgain(
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Elearning.watchAgain"
                                                      )
                                                    ) +
                                                    "\n                            "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                      _vm._v(" "),
                                      _vm.examResults.attempts_left >= 0
                                        ? _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("Elearning.attemptsLeft")
                                              ) +
                                                ": " +
                                                _vm._s(
                                                  _vm.examResults.attempts_left
                                                )
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "w-50 m-auto text-center"
                                      },
                                      [
                                        _c("h5", { staticClass: "p-1" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("Elearning.noAttemptsLeft")
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-primary text-white",
                                            attrs: { href: "/elearning/" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Elearning.goBackToListing"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                              ]
                        ],
                        2
                      )
                    : _vm._e()
              ])
            ],
            1
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
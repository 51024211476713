var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      staticClass: "legacy-country-modal",
      attrs: {
        width: "500",
        height: "auto",
        name: _vm.modalName,
        scrollable: true,
        clickToClose: false
      }
    },
    [
      _c("div", { staticClass: "b-modal-content" }, [
        _c("h2", { staticClass: "mb-1 mt-2" }, [
          _vm._v(_vm._s(_vm.$t("LegacyCountry.title")))
        ]),
        _vm._v(" "),
        _vm.mapToNewCountryCode()
          ? _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("LegacyCountry.description")) +
                  "\n        "
              )
            ])
          : _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("LegacyCountry.description_alt")) +
                  "\n        "
              )
            ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-primary",
            attrs: { href: "#" },
            on: { click: _vm.buttonClickHandler }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("LegacyCountry.button")) +
                "\n        "
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
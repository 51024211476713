<template>
    <div>
        <loader class="my-4" v-if="isLoading" />
        <form-generator
            v-else
            :url="endpointUrl"
            :fields="formFields"
            :config="formConfig"
            @success="onSuccess"
        >
            <template v-slot:footer>
                <!-- Shows only in UK, that's why it doesn't use translations -->
                <div class="privacy-police mt-3 mb-5 text-left" v-if="isUK">
                    <h4>Privacy Notice</h4>
                    <p>
                        We will use the personal data you provide above to register you and provide you with access to the RD Nexus UK Site.
                        Sanofi will process your personal data on the basis that it is necessary for our legitimate interests (for provision of administration and IT services and network security,
                        to keep our website updated and relevant, to study how customers use our products/services and to develop our business).
                        Aventis Pharma Limited is the controller and responsible for your personal data. Please see our Privacy Policy for more detailed information about our data processing activities.
                        <a href="https://www.sanofi.co.uk/privacy-policy" target="_blank">Privacy Policy</a>
                    </p>
                    <p>
                        If you tick the box above to receive emails from Sanofi, we will also use your personal data to send you information by email about goods and services
                        which we feel may be of interest to you on the basis that it is necessary for our legitimate interests (to develop our products/services and grow our business).
                        For more details specifically about how we use your data to send you these emails, please see our separate Staying in Touch Privacy Notice.
                        <a href="https://www.sanofi.co.uk/en/about-us/sanofi-in-the-uk/staying-in-touch" target="_blank">Staying in Touch Privacy Notice</a>
                    </p>
                </div>
            </template>
        </form-generator>
    </div>
</template>


<script>
import Service from '../../service';
import apiUrl from '../../utility/apiUrl';

import FormGenerator from './../Forms/FormGenerator.vue';
import Loader from './../Common/Loader.vue';

export default {
    name: 'RegisterStep2',

    components: {
        FormGenerator,
        Loader,
    },

    data() {
        return {
            endpointUrl: apiUrl('register-no-big'),
            formConfig: {
                subtitle: this.$i18n.i18next.t('FormFields.allRequired'),
                submitTitle: this.$i18n.i18next.t('User.register'),
                errorMessage: this.$i18n.i18next.t('RegisterNoBigStep2.errorsInForm'),
                errorMessages: {
                    'email': {
                        '_isUnique': this.$i18n.i18next.t('RegisterNoBigStep2.emailExists'),
                    },
                },
                columns: true
            },
            fields: [],
            isUK: window.siteData.currentLanguage === 'uk',
            isLoading: false,
            country: localStorage.getItem('country'),
            channelId: localStorage.getItem('channelId'),
            rowTypes: Object.freeze({ left: 'LEFT', right: 'RIGHT', full: 'FULL', }),
        };
    },

    computed: {
        formFields() {
            return this.sortedFields.reduce((fields, field) => {
                fields[field.name] = {
                    type: field.type.toLowerCase(),
                    required: field.required,
                    forceFullWidth: field.row === this.rowTypes.full,
                    label: field.label,
                    value: field.value
                };

                return fields;
            }, {});
        },

        /**
         * Sort fields by position and then by row, lower position comes first and the left row comes first.
         */
        sortedFields() {
            // creates an array for each position/row and adds its fields from left to right
            const sorted = this.fields.reduce((fields, field) => {
                // initialize an array for every position/row in the form
                if (!fields[field.position]) {
                    fields[field.position] = [];
                }

                // add to it's position/row array
                fields[field.position].push(field);

                // Sort so left comes before right
                fields[field.position] = fields[field.position].sort((fieldA) => {
                    if (fieldA.row === this.rowTypes.left) {
                        return -1;
                    }
                    return 1;
                });

                return fields;
            }, [])
            // Filter out undefined (happens when position 2 and 4 exist but not 3)
            .filter((row) => row !== undefined);

            // go through every row
            for (let rowIndex = 0; rowIndex < sorted.length; rowIndex++) {
                const checkingField = sorted[rowIndex][0];

                // Only go through when the field is left or right and it is alone on the row
                if (sorted[rowIndex].length !== 1 && checkingField.row !== this.rowTypes.full) {
                    continue;
                }

                // Empty field that wil fill up the rest of the row in the form
                const emptyField = {
                    row: checkingField.row === this.rowTypes.left ? this.rowTypes.right : this.rowTypes.left,
                    name: `hidden-${rowIndex}`,
                    type: 'hidden',
                    label: '',
                    required: false,
                    value: '',
                };

                // Show it last if the new field is row right
                if (checkingField.row === this.rowTypes.left) {
                    sorted[rowIndex].push(emptyField);
                    continue;
                }

                // Show it first if the new field is row left
                sorted[rowIndex].unshift(emptyField);
            }

            // put all fields one after another instead of per row
            return sorted.flat();
        },
    },

    methods: {
        onSuccess({ formModel }) {
            // Gather analytics information about the successful registration
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'formSubmissionSuccess',
                formID: 'register',
                formValue: formModel.country
            });

            this.$emit('success');
        },

        async getFields() {
            try {
                this.isLoading = true;
                const dynamicFields = await Service.getRegistrationFields(this.channelId);
                this.fields = [
                    ...this.setTranslatedLabels(dynamicFields),
                    // Add hidden country field
                    {
                        position: 999,
                        row: this.rowTypes.full,
                        type: 'HIDDEN',
                        required: false,
                        label: '',
                        value: this.channelId,
                        name: 'channel_id',
                    },
                    {
                        position: 998,
                        row: this.rowTypes.full,
                        type: 'HIDDEN',
                        required: false,
                        label: '',
                        value: this.country,
                        name: 'country',
                    },
                ];
            } finally {
                this.isLoading = false;
            }
        },

        /**
         * @param {Array<Object>} fields
         */
        setTranslatedLabels(fields) {
            fields.forEach((field, index) => {
                field.registration_field_translations.forEach((translation) => {
                    if (
                        translation.country.country_name === this.country ||
                        translation.country.name === this.country
                    ) {
                        fields[index].label = translation.label;
                    }
                });
                delete field.registration_field_translations;
            });
            return fields;
        },
    },

    created() {
        this.getFields();
    },
};
</script>

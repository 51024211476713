<template>

    <div class="c-message-box text-center mt-4 mb-3">
        <img v-if="image" :src="image">
        <icon v-else-if="icon" :glyph="icon" :class="iconClass" size="xxl" />

        <h5 v-if="title" class="mt-4">{{ title }}</h5>
        <p v-if="message" v-html="message"></p>

        <slot />
    </div>

</template>


<script>

export default {
    name: 'MessageBox',

    props: {
        title: String,
        message: String,
        icon: String,
        iconClass: String,
        image: String,
    },
}

</script>

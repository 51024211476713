<template>

    <main id="video-channel">
        <subpage-banner
            :header="$t('VideoChannel.title')"
        />

        <transition name="fade-in">

            <div v-if="!currentUser" class="container-xl">
                <not-logged-user-message />
            </div>

            <div v-else-if="isLoading" class="container-xl mt-5">
                <loader />
            </div>

            <message-box
                v-else-if="!videos || !videos.all"
                image="/site/templates/img/general/coming-soon-home.svg"
                :title="$t('ChannelComingSoon.willAddSoon')"
                :message="$t('ChannelComingSoon.letYouKnow')"
            />

            <div v-else class="container-fluid">
                <div class="row">
                    <div class="col-xs-12 col-md-2 t-bg-light pt-5 pb-5 position-relative">
                        <div class="u-sticky text-center">
                            <ul class="b-submenu">
                                <li
                                    v-for="category in primaryCategories"
                                    :key="category.slug"
                                    :class="{'is-active': category.slug === currentCategorySlug}"
                                >
                                    <a href="#" @click.prevent="setCategory(category.slug)"><strong>{{ category.title }}</strong></a>
                                </li>
                            </ul>

                            <hr>

                            <template v-if="additionalCategories && additionalCategories.length">
                                <ul class="b-submenu">
                                    <li
                                        v-for="category in additionalCategories"
                                        :key="category.slug"
                                        :class="{'is-active': category.slug === currentCategorySlug}"
                                    >
                                        <a href="#" @click.prevent="setCategory(category.slug)">{{ category.title }}</a>
                                    </li>
                                </ul>

                                <hr>
                            </template>

                            <filters
                                :videos="currentVideos"
                                @filter="handleFiltering"
                            />
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-10">
                        <template v-if="filterResults">
                            <video-list-section
                                v-if="filterResults.length > 0"
                                :title="`${$t('Common.searchResults')} (${filterResults.length})`"
                                :videos="filterResults"
                                :show-video-number="true"
                            />
                            <message-box
                                v-else
                                class="mt-5"
                                image="/site/templates/img/general/no-results.svg"
                                :title="$t('Common.noResults')"
                            />
                        </template>

                        <template v-else>
                            <transition name="fade-in">
                                <video-list-section
                                    :key="currentCategory.slug"
                                    :title="currentCategory.title"
                                    :videos="currentVideos"
                                    :banner-text="currentCategory.bannerText"
                                    :banner-image-src="currentCategory.bannerImage ? [
                                        {src: currentCategory.bannerImage},
                                    ] : null"
                                />
                            </transition>
                        </template>
                    </div>
                </div>
            </div>
        </transition>
    </main>

</template>


<script>

import Service from '../../service';
import currentUser from '../../utility/currentUser.js';

import SubpageBanner from '../SubpageBanner/SubpageBanner.vue';
import VideoListSection from '../Video/VideoListSection.vue';
import VideoList from '../Video/VideoList.vue';
import Filters from '../Filters/Filters.vue';
import InlineRadioSelect from '../Common/InlineRadioSelect.vue';

export default {
    name: 'VideoChannel',

    components: {
        SubpageBanner,
        Filters,
        VideoListSection,
        VideoList,
        InlineRadioSelect,
    },

    data() {
        return {
            currentUser,
            isLoading: false,
            videos: [],
            category: '',
            filterResults: false,
            currentCategorySlug: 'featured',
            // Predefined categories displayed as first
            primaryCategories: [
                {
                    slug: 'featured',
                    videosKey: 'featured',
                    title: this.$i18n.i18next.t('VideoChannel.featuredVideos'),
                    bannerText: this.$i18n.i18next.t('VideoChannel.featuredVideosSlogan'),
                },
                {
                    slug: 'new',
                    videosKey: 'new',
                    title: this.$i18n.i18next.t('VideoChannel.newUploads'),
                    bannerText: this.$i18n.i18next.t('VideoChannel.newUploadsSlogan'),
                    bannerImage: '/site/templates/img/general/new-uploads-banner.jpg',
                },
                {
                    slug: 'all',
                    videosKey: 'all',
                    title: this.$i18n.i18next.t('VideoChannel.allVideos'),
                    bannerText: this.$i18n.i18next.t('VideoChannel.moreVideosSlogan'),
                    bannerImage: '/site/templates/img/general/more-videos-banner.jpg',
                },
            ],
        }
    },

    computed: {
        // Categories managed on the VCM side
        additionalCategories() {
            if (!this.videos || !this.videos.categories) {
                return [];
            }

            return Object.keys(this.videos.categories).map((categoryName) => {
                return {
                    slug: categoryName,
                    title: categoryName,
                }
            });
        },

        currentVideos() {
            return this.videos[this.currentCategorySlug] || this.videos.categories[this.currentCategorySlug] || this.videos.all || [];
        },

        allCategories() {
            return [...this.primaryCategories, ...this.additionalCategories];
        },

        currentCategory() {
            return this.allCategories.find(({slug}) => slug === this.currentCategorySlug);
        },
    },

    methods: {
        async fetchVideos() {
            this.isLoading = true;
            this.videos = await Service.getUserVideos();
            this.isLoading = false;
        },

        handleFiltering(videos) {
            this.filterResults = videos;
        },

        setCategory(categorySlug) {
            if (this.allCategories.find(({ slug }) => categorySlug === slug)) {
                this.currentCategorySlug = categorySlug;
            } else {
                console.warn(`Category "${categorySlug}" does not exist`);
            }
        },
    },

    created() {
        this.fetchVideos();
    },
}

</script>

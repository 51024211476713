<template>

    <main class="container-xl">
        <transition name="fade-in">
            <not-logged-user-message
                v-if="!currentUser"
                class="mt-5"
            />

            <message-box
                v-else-if="!showEvents"
                :message="$('Common.featureNotAvailableForChannel')"
                icon="times-circle"
            />

            <loader
                v-else-if="isLoading"
                class="my-5"
            />

            <div
                v-else-if="events && events.length"
                class="b-timeline"
            >
                <div v-for="(monthGroup, monthIndex) in eventsGrouped" :key="monthIndex">
                    <div class="b-timeline__row">
                        <div class="b-timeline__row__track b-timeline__row__track--main">
                            <p class="h4">{{ $t('MonthNames.' + monthGroup.month) }}</p>
                            <p class="h6">{{ monthGroup.year }}</p>
                        </div>
                    </div>

                    <div v-for="(dayGroup, dayIndex) in monthGroup.days" :key="dayIndex">
                        <div class="b-timeline__row">
                            <div class="b-timeline__row__track b-timeline__row__track--sub">
                                <p><strong>{{ dayGroup.day }}</strong> {{ $t('MonthNames.' + monthGroup.month) }}</p>
                            </div>
                            <div class="b-timeline__row__content">
                                <events-list
                                    :events="dayGroup.events"
                                    is-horizontal
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <message-box
                v-else
                class="mt-5"
                icon="times-circle"
                :title="$t('Common.emptyList')"
            />
        </transition>
    </main>

</template>


<script>

import Service from '../../service';
import currentUser from '../../utility/currentUser.js';

import EventsList from './EventsList.vue';

export default {
    name: 'EventsListPage',

    components: {
        EventsList,
    },

    data() {
        return {
            isLoading: false,
            events: [],
            currentUser,
            showEvents: window.siteData.showEvents,
        }
    },

    computed: {
        /**
         * We need to display the events grouped by months and then by mont days. So here we're creating
         * multi-level structure that can be easilly traversable on the HTML side.
         */
        eventsGrouped() {
            let currentYear;
            let currentMonth;
            let currentDay;

            return this.events.reduce((list, event) => {
                // Old MCM events has no date field so they should be skipped.
                if (!event.date) {
                    return list;
                }

                const eventDate = new Date(event.date);
                const eventYear = eventDate.getFullYear();
                const eventMonth = eventDate.getMonth();
                const eventDay = eventDate.getDate();

                // Jump to next month
                if (eventMonth !== currentMonth) {
                    currentMonth = eventMonth;
                    currentDay = 0;

                    list.push({
                        year: eventYear,
                        month: currentMonth + 1,
                        days: [],
                    });
                }

                const currentMonthKey = list.length - 1;

                // Jump to next day
                if (eventDay !== currentDay) {
                    currentDay = eventDay;

                    list[currentMonthKey].days.push({
                        month: currentMonth,
                        day: currentDay,
                        events: [],
                    });
                }

                const currentDayKey = list[currentMonthKey].days.length - 1;

                // Finally add event to calculated place in this multi-level structure
                list[currentMonthKey].days[currentDayKey].events.push(event);

                return list;
            }, []);
        },
    },

    methods: {
        async fetchEvents() {
            this.isLoading = true;
            this.events = await Service.getEvents();
            this.isLoading = false;
        },
    },

    created() {
        if (this.currentUser && this.showEvents) {
            this.fetchEvents();
        }
    },
};

</script>

<template>
    <section class="c-hero-banner">
        <div class="c-hero-banner__content container">
            <div class="row justify-content-between align-items-center">

                <div class="col-12 col-md-7">
                    <img src="/site/templates/img/general/rare-disease-nexus-logo-white.svg" class="c-hero-banner__content__logo">
                    <h1>{{ headline }}</h1>
                </div>

                <div class="c-hero-banner__buttons [ col-12 col-md-5 col-xl-4 ]">
                    <a
                        v-for="(button, index) in buttons"
                        class="btn btn-lg btn-raised"
                        :class="[
                            `btn-${button.color}`,
                            ...(button.image) ? ['btn-with-image'] : [],
                        ]"
                        :key="index"
                        :href="button.url"
                    >
                        <span class="btn-image" v-if="button.image"><img :src="button.image" :alt="button.name"></span>
                        {{ button.name }}
                    </a>
                </div>

            </div>
        </div>

        <a
            v-if="scrollDownTarget"
            class="c-hero-banner__cta b-action-btn"
            :href="scrollDownTarget"
        >
            <icon glyph="chevron-down" :is-solid="true" />
        </a>
    </section>
</template>


<script>

export default {
    name: 'HeroBanner',

    props: {
        headline: String,
        scrollDownTarget: String,
    },

    data() {
        return {
            buttons: siteData.suggestedPages || [], // Taken from global scope head.php
        };
    },
}

</script>

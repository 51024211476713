var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "c-site-header",
      class: { "c-site-header--home": _vm.isHomePage },
      attrs: { id: "site-header" }
    },
    [
      _c("div", { staticClass: "container-xl c-site-header__container" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("nav", { staticClass: "c-site-header__menu" }, [
          _c(
            "ul",
            [
              _vm._l(_vm.menuLinks, function(link, index) {
                return _c("li", { key: index }, [
                  _c("a", { attrs: { href: link.url } }, [
                    _vm._v(_vm._s(link.name))
                  ])
                ])
              }),
              _vm._v(" "),
              _c("li", { staticClass: "c-site-header__menu__divider" }),
              _vm._v(" "),
              _vm.isUserLoggedIn
                ? [
                    _c("li", [
                      _c(
                        "a",
                        { on: { click: _vm.openNotifications } },
                        [
                          _c("icon", {
                            staticClass: "c-site-header__menu__icon",
                            attrs: {
                              glyph: "bell",
                              counter: _vm.notificationsCount,
                              hoverable: true
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        { attrs: { href: "/favorites" } },
                        [
                          _c("icon", {
                            staticClass: "c-site-header__menu__icon",
                            attrs: {
                              glyph: "heart",
                              counter: _vm.favoriteVideosCount,
                              hoverable: true
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "c-site-header__menu__user",
                          on: { click: _vm.openUserProfile }
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.userName))])]
                      )
                    ])
                  ]
                : [
                    _c("li", [
                      _c("a", { on: { click: _vm.openLoginForm } }, [
                        _vm._v(_vm._s(_vm.$t("Login.login")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("a", { on: { click: _vm.openRegisterForm } }, [
                        _vm._v(_vm._s(_vm.$t("User.register")))
                      ])
                    ])
                  ]
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "c-site-header__toggle",
            on: { click: _vm.toggleSlideNavigation }
          },
          [_c("span")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "c-site-header__logo", attrs: { href: "/" } },
      [
        _c("img", {
          attrs: {
            src: "/site/templates/img/general/rare-disease-nexus-logo-white.svg"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>

    <footer class="c-site-footer">
        <!--
            Upper part
        -->
        <div class="c-site-footer__top">
            <div class="container-xl">
                <div class="c-site-footer__row">
                    <img src="/site/templates/img/general/sanofi-logo-white.png" class="c-site-footer__logo">

                    <div v-if="socialMediaLinks.length" class="c-site-footer__social">
                        <span class="c-site-footer__column">
                            {{ $t('Footer.follow') }}
                        </span>

                        <a
                            v-for="(socialMediaLink, socialMediaLinkIndex) in socialMediaLinks"
                            :key="socialMediaLinkIndex"
                            :href="socialMediaLink.url"
                            :title="socialMediaLink.name"
                            target="_blank"
                        >
                            <icon :glyph="socialMediaLink.icon" is-brand></icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!--
            Bottom part
        -->
        <div class="c-site-footer__bottom">
            <div class="container-xl">
                <div class="c-site-footer__row">
                    <nav class="c-site-footer__column c-site-footer__nav" v-if="footerLinks">
                        <a v-for="footerLink in footerLinks"
                           v-bind:key="footerLink.url"
                           :href="footerLink.url"
                           target="_blank"
                        >{{ footerLink.label }}</a>
                    </nav>
                    <nav class="c-site-footer__column c-site-footer__nav" v-else>
                        <a href="/legal-notice">{{ $t('Footer.legal') }}</a>
                        <a href="/privacy-policy">{{ $t('Footer.privacy') }}</a>
                        <a href="/cookies-policy">{{ $t('Footer.cookies') }}</a>
                    </nav>

                    <div class="text-right c-site-footer__column c-site-footer__text">
                        <div v-if="footerText" v-html="footerText"></div>
                        <p v-if="metas.zincCode || this.zinc">
                            {{ this.zinc || metas.zincCode }}
                        </p>
                        <p>Copyright&#169; Sanofi {{ new Date().getFullYear() }}. {{ $t('Footer.rights') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>

</template>


<script>
import Service from '../../service';

export default {
    name: 'SiteFooter',

    props: {
        socialMediaLinks: Array,

        /**
         * CMS
         */
        footerText: String,
        footerLinks: Array,
        zinc: String,
    },

    data() {
        return {
            isGulf: window.siteData.currentLanguage === 'gf',
            isLoggedIn: !!siteData.user,
            gulfChannelId: 65,
            metas: {},
        };
    },

    methods: {
        /**
         * If user is logged out on the gulf channel
         * we need to fetch and show the promomats/zinc code
         */
        async fetchChannelMetas() {
            if (!this.isLoggedIn && !this.isGulf) {
                return;
            }

            this.metas = await Service.getChannelMetas(
                this.isLoggedIn ? window.siteData.user.info.channel_id : this.gulfChannelId
            );
        },
    },

    created() {
        this.fetchChannelMetas();
    },
}

</script>

<template>

    <div class="login-box">
        <form @submit.prevent="submit" v-if="!successfullySubmitted" ref='form' class="vue-modal" @submit='submit' method="POST">
            <div class="vue-modal__title">
                <div class="vue-modal__title">
                    <div class="title">
                        {{ $t('Forgot.setUp')}}
                    </div>
                </div>
            </div>

            <div class="form-group has-float-label">
                <input class="form-control" id="password" type="password" placeholder=" " required v-model="password">
                <label for="password">{{ $t('FormFields.password')}}</label>
            </div>

            <div class="form-group has-float-label">
                <input class="form-control" id="passwordConfirm" type="password" placeholder=" " required v-model="passwordConfirm">
                <label for="passwordConfirm">{{ $t('FormFields.passwordConfirm')}}</label>
            </div>

            <div v-if="password && !strongEnough" style="color:red;font-size:12px;">
                {{ $t('FormFields.passwordStrength')}}
            </div>
            <div class="vue-modal__submit">
                <button
                    type="submit"
                    class="btn btn-success"
                    :class="{active: canSubmitForm}"
                    :disabled="!canSubmitForm || submitting"
                >
                    <i class="fas fa-circle-notch fa-spin" v-if="submitting"></i> {{ $t('Forgot.goTo')}}
                </button>
            </div>
        </form>

        <div v-if="successfullySubmitted">
            {{ $t('FormFields.yourPasswordSet') }}
        </div>
    </div>

</template>


<script>

import axios from 'axios';
import apiUrl from '../../utility/apiUrl';

export default {
    name: "SetPasswordForm",

    props: {
        hash: {
            type: [String],
            required: true
        }
    },

    data() {
        return {
            url: apiUrl('set-user-password'),
            password: '',
            passwordConfirm: '',
            submitting: false,
            successfullySubmitted: false
        }
    },

    computed: {
        canSubmitForm() {
            const areEqual = this.password && this.passwordConfirm && this.password === this.passwordConfirm;

            return areEqual && this.strongEnough;
        },
        strongEnough() {
            return this.validatePassword(this.password);
        }
    },

    methods: {
        async submit(e) {
            if (!this.canSubmitForm) {
                return;
            }
            this.submitting = true;
            const response = await axios.post(this.url, {
                password: this.password,
                hash: this.hash
            });

            this.submitting = false;

            if (response.data.success === true) {
                this.successfullySubmitted = true;
                //open login
                setTimeout(() => {
                    window.location.reload();
                    //this.$modal.show('login-form-step1');
                }, 4000)
            } else {
                //TODO handle error
                if (response.data.errors.hasOwnProperty('user')) {
                    //alert('This password link is not valid');
                }
            }
        },

        validatePassword(value) {
            let pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

            if (pattern.test(value)) {
                return true;
            }
            return false;
        },
    },

    mounted() {
        //so hash is not visible on address bar
        window.history.replaceState({}, document.title, siteData.pageUrl);
    },
}

</script>

<template>

    <label class="c-custom-text-input form-group has-float-label">
        <input
            class="form-control"
            :type="type"
            :name="name"
            :value="value"
            :class="[{'is-invalid': isInvalid}]"
            :required="required"
            :placeholder="placeholder"
            :disabled="disabled"
            :aria-describedby="`tooltip_${name}`"
            @input="onChange"
            @blur="onBlur"
        >

        <span>
            <span v-if="required">*</span>
            {{ label }}
        </span>

        <span
            v-if="tooltip"
            class="tooltip tooltip-over-input"
            role="tooltip"
            :id="`tooltip_${name}`"
        >
            <i class="fas fa-exclamation-circle"></i>
            <span class="tooltip-text">
                {{ tooltip }}
            </span>
        </span>
    </label>

</template>


<script>

export default {
    props: {
        value: {
            type: String,
        },
        type: {
            type: String,
            default: 'text',
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
        },
        default: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: ' ' // Without space floating labels does not work properly
        },
        tooltip: {
            type: String,
        },
    },

    data() {
        return {
            touched: false, // Indicates if any change was made in this input
        };
    },

    computed: {
        isInvalid() {
            return (this.type == 'email')
                ? (this.touched && (this.required || this.value) && this.validateEmail(this.value))
                : (this.touched && this.required && !this.value);
        },
    },

    methods: {
        onChange(event) {
            // Allow of binding v-model to this component
            this.$emit('input', event.target.value);
        },

        onBlur() {
            this.touched = true;
            this.$emit('blur');
        },

        /**
         * Front-end validation should not be too complex. Real validation
         * should be done on back-end side. Here we accept strings with "@".
         *
         * @param {String} email
         * @returns {Boolean}
         */
        validateEmail(email) {
            return !/\S+@\S+/.test(this.value);
        },
    },

    created() {
        if (this.default) {
            this.$emit('input', this.default);
        }
    }
}

</script>

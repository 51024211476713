var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "b-tile",
      class: [
        _vm.layout ? "b-tile--" + _vm.layout : "",
        _vm.theme ? "b-tile--" + _vm.theme : ""
      ],
      attrs: { href: _vm.href },
      on: {
        click: function(event) {
          return _vm.handleClick(event, _vm.id)
        }
      }
    },
    [
      _vm.image
        ? _c("div", { staticClass: "b-tile__image" }, [
            _c("img", { attrs: { src: _vm.image } })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "b-tile__content" }, [
        _c("div", [
          _vm.subtitle
            ? _c("p", {
                staticClass: "h6 b-tile__content__subtitle",
                domProps: { innerHTML: _vm._s(_vm.subtitle) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.headline
            ? _c("h4", { domProps: { innerHTML: _vm._s(_vm.headline) } })
            : _vm._e(),
          _vm._v(" "),
          _vm.summary
            ? _c("p", {
                staticClass: "b-tile__content__summary",
                domProps: { innerHTML: _vm._s(_vm.summary) }
              })
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
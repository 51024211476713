<template>
    <button
        class="c-scroll-top-btn b-action-btn"
        @click.prevent="scrollTop"
    >
        <icon glyph="chevron-up" :is-solid="true"></icon>
    </button>
</template>


<script>

export default {
    name: 'ScrollTopBtn',

    methods: {
        scrollTop() {
            const scrollToTop = window.setInterval(() => {
                let pos = window.pageYOffset;

                if (pos > 0) {
                    window.scrollTo(0, pos - 40);
                } else {
                    window.scrollTo(0, 0);
                    window.clearInterval(scrollToTop);
                }
            }, 2);
        },
    }
}

</script>

<template>

    <div>
        <div v-if="success" class="thank-you-message">
            <div class="shape">
                <img src='/site/templates/img/general/paper-plane.svg'>
            </div>
            <div class="message" v-html="$t('SingleVideo.msgThanks')">
                {{ $t('UserAccount.msgThanks') }}
            </div>
        </div>

        <form-generator
            v-else
            :url="contactApiUrl"
            :fields="formFields"
            :config="{
                title: $t('UserAccount.contactUs'),
                submitTitle: $t('Common.send'),
            }"
        />
    </div>

</template>


<script>

import apiUrl from '../../utility/apiUrl';

import FormGenerator from '../Forms/FormGenerator.vue';

export default {
    name: 'UserContactUsForm',

    components: {
        FormGenerator,
    },

    data() {
        return {
            success: false,
            contactApiUrl: apiUrl('send-change-request'),
            formFields: {
                subject: {
                    type: 'text',
                    required: true,
                    label: this.$i18n.i18next.t('Common.subject'),
                },
                message: {
                    type: 'textarea',
                    required: true,
                    label: this.$i18n.i18next.t('Common.message'),
                },
            },
        }
    },

    methods: {
        closeForm() {
            this.$emit('close');
        }
    }
}

</script>

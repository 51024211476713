var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.href ? "a" : "div",
    {
      tag: "component",
      staticClass: "c-card",
      class: {
        "c-card--covered": _vm.isCovered,
        "c-card--horizontal": _vm.isHorizontal && !_vm.isCovered,
        "is-favorite-hovered": _vm.favoriteHovered
      },
      attrs: {
        href: _vm.href,
        target: _vm.href && _vm.targetBlank ? "_blank" : null
      },
      on: { click: _vm.verifyUser }
    },
    [
      _vm.coverImage || _vm.isCovered || _vm.isHorizontal
        ? _c("div", { staticClass: "c-card__cover" }, [
            _vm.coverIcon && !_vm.isCovered
              ? _c(
                  "span",
                  { staticClass: "c-card__btn" },
                  [
                    _c("icon", {
                      attrs: { glyph: _vm.coverIcon, "is-solid": "" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "c-card__cover__inner" }, [
              _vm.coverImage
                ? _c("img", { attrs: { src: _vm.coverImage, alt: "" } })
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "c-card__content" }, [
        _vm.tag
          ? _c(
              "p",
              { staticClass: "c-card__content__tag" },
              [
                _vm.tagIcon
                  ? _c("icon", {
                      attrs: { glyph: _vm.tagIcon, size: "lg", "is-solid": "" }
                    })
                  : _vm._e(),
                _vm._v("\n            " + _vm._s(_vm.tag) + "\n        ")
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("p", {
          staticClass: "c-card__content__title",
          domProps: { innerHTML: _vm._s(_vm.title) }
        }),
        _vm._v(" "),
        _vm.description
          ? _c("div", {
              staticClass: "c-card__content__desc u-truncate-text",
              domProps: { innerHTML: _vm._s(_vm.description) }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "c-card__content__bottom" }, [
          _c(
            "ul",
            { staticClass: "b-inline-list" },
            _vm._l(_vm.infoListFiltered, function(info, infoIndex) {
              return _c("li", {
                key: infoIndex,
                staticClass: "b-inline-list__item",
                domProps: { innerHTML: _vm._s(info) }
              })
            }),
            0
          ),
          _vm._v(" "),
          _vm.btnText && _vm.btnHref
            ? _c(
                "a",
                {
                  staticClass: "btn btn-primary",
                  attrs: {
                    href: _vm.btnHref,
                    target: _vm.btnHref && _vm.targetBlank ? "_blank" : null
                  }
                },
                [_vm._v(_vm._s(_vm.btnText))]
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-card__icons" }, [
        _vm.markedByIcon
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.markedByText,
                    expression: "markedByText"
                  }
                ],
                staticClass: "c-card__icons__icon c-card__icons__icon--marker"
              },
              [
                _c("icon", {
                  attrs: { glyph: _vm.markedByIcon, size: "lg", "is-solid": "" }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.useFavorites
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.isFavorite
                      ? _vm.$t("Favorites.remove")
                      : _vm.$t("Favorites.add"),
                    expression:
                      "(isFavorite) ? $t('Favorites.remove') : $t('Favorites.add')"
                  }
                ],
                staticClass: "c-card__icons__icon c-card__icons__icon--action",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.favorite($event)
                  },
                  mouseover: function($event) {
                    _vm.favoriteHovered = true
                  },
                  mouseleave: function($event) {
                    _vm.favoriteHovered = false
                  }
                }
              },
              [
                _c("icon", {
                  attrs: {
                    glyph: "heart",
                    "is-solid": _vm.isFavorite,
                    size: "lg",
                    hoverable: ""
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>

    <div class="row">
        <div
            v-for="(tile, index) in list"
            class="col-lg-6 mb-5"
            :key="index"
        >
            <tile
                :id="tile.id"
                :image="tile.image"
                :headline="tile.headline"
                :subtitle="tile.subtitle"
                :summary="tile.summary"
                :href="tile.href"
                :layout="layout"
                :theme="tile.theme"
                @tile-click-action="payload => $emit('tile-click-action', payload)"
            />
        </div>
    </div>

</template>


<script>

import SiteHeader from '../SiteHeader/SiteHeader.vue';
import SubpageBanner from '../SubpageBanner/SubpageBanner.vue';
import Tile from './Tile.vue';

export default {
    name: 'TileList',

    components: {
        SiteHeader,
        SubpageBanner,
        Tile,
    },

    props: {
        list: {
            type: Array,
        },
        layout: {
            type: String,
        },
    },
}

</script>

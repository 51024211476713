<template>

    <main class="c-video-details">
        <subpage-banner
            :header="videoTitle"
            smaller
        >
            <div class="c-video-details__header" v-if="video">
                <ul class="b-inline-list">
                    <li>{{ video.speaker }}</li>
                    <li>{{ eventTime }}</li>
                </ul>

                <div class="c-video-details__header__favorite">
                    <i
                        class="fa-heart"
                        aria-hidden="true"
                        :class="video.is_favorite ? 'fas' : 'far'"
                        @click="favoriteVideo"
                    ></i>
                </div>
            </div>
        </subpage-banner>

        <transition name="fade-in">
            <not-logged-user-message
                v-if="!currentUser"
            />

            <loader
                v-else-if="isLoading"
                class="mt-5"
            />

            <div
                v-else-if="video"
                class="container-xl c-video-details__container"
            >
                <div class="row">

                    <div class="col-lg-9">
                        <!--
                            Video player
                        -->
                        <video-player
                            :video="video"
                        />

                        <!--
                            Zinc code
                        -->
                        <p class="mt-3">
                            {{ video.approval_code }}
                        </p>

                        <!--
                            Video description
                        -->
                        <div class="c-video-details__description">
                            <h3>
                                {{ $t('Common.details') }}

                                <div
                                    v-if="messagingEnabled"
                                    class="b-action-btn c-video-details__message-btn"
                                    @click="openForm"
                                >
                                    <i class="fas fa-comment-alt"></i>
                                </div>
                            </h3>

                            <video-message
                                :modal-name="videoMessageModalName"
                                :video-id="videoId"
                                :video-title="videoTitle"
                            />

                            <div
                                v-html="video.description"
                                class="c-video-details__description__text"
                            >
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3">
                        <aside class="c-video-details__aside">
                            <h3 class="h6" v-if="!isUk">
                                {{ $t('SingleVideo.related') }}
                            </h3>

                            <h3 class="h6" v-else>
                                {{ $t('SingleVideo.relatedUk') }}
                            </h3>

                            <div class="row">
                                <div
                                    v-for="(relatedVideo, videoIndex) in video.related_videos"
                                    :key="videoIndex"
                                    class="col-sm-6 col-lg-12 col-ul-6 mt-4"
                                >
                                    <video-card :video="relatedVideo" />
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>

            <div v-else-if="!isLoading && !video">
                <message-box
                    class="mt-5"
                    icon="times-circle"
                    :title="$t('SingleVideo.notFound')"
                />
            </div>
        </transition>
    </main>

</template>


<script>

import Service from '../../service';
import currentUser from '../../utility/currentUser';

import SiteHeader from '../SiteHeader/SiteHeader.vue';
import VideoPlayer from './VideoPlayer.vue';
import VideoCard from '../Video/VideoCard.vue';
import VideoMessage from './VideoMessage.vue';

export default {
    name: 'VideoDetails',

    components: {
        SiteHeader,
        VideoPlayer,
        VideoCard,
        VideoMessage,
    },

    props: {
        videoId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            currentUser,
            isLoading: false,
            video: null,
            videoMessageModalName: 'videoMessageForm',
            isUk: window.siteData.currentLanguage === 'uk',
            messagingEnabled: Boolean(window.siteData.messagingEnabled),
        }
    },

    computed: {
        videoTitle() {
            return (this.video) ? this.video.title : '';
        },

        eventTime() {
            return new Date(this.video.event_date).toLocaleString('en-us', {
                year: 'numeric',
                month: 'short'
            });
        }
    },

    methods: {
        async fetchVideo() {
            this.isLoading = true;
            this.video = await Service.getSingleVideo(this.videoId);
            this.isLoading = false;
        },

        async favoriteVideo() {
            const result = await Service.setFavoriteVideo(this.video.id);

            if (result) {
                this.video.is_favorite = !this.video.is_favorite;
                window.GlobalEventBus.$emit('updateFavoriteVideosCount');
                this.$emit('change-favorite');
            }
        },

        openForm() {
            this.$modal.show(this.videoMessageModalName);
        },
    },

    created() {
        if (this.currentUser) {
            this.fetchVideo();
        }
    },
}

</script>

import axios from 'axios';

/**
 * @typedef Channel
 * @property {number} id
 * @property {string} title
 * @property {string} language
 */

/**
 * @typedef EventParticipation
 * @property {string}                  event_title
 * @property {Date}                    event_date
 * @property {string}                  status
 * @property {Date}                    registered_at
 * @property {Object<string, boolean>} optins
 * @property {number}                  watchTime
 */

/**
 * @typedef Accreditation
 * @property {string}  title
 * @property {number}  attempt
 * @property {boolean} passed
 * @property {number}  percentage
 * @property {Date}    date
 * @property {number}  accreditation_points
 */

class Service {
    /**
     * @param {String} action Action names are the file names located in /site/templates/api-endpoints/
     */
    prepareUrl(action) {
        return `/api/?action=${action}&jsonapi=true`;
    }

    handleError(err) {
        console.group('Failed to fetch the API data');
        console.error(err);
        console.log('Response', err.response);
        console.groupEnd();

        return false;
    }

    /**
     * This interface assumes that the response data should contain `success` and `data` keys.
     * If success is true the `data` will be passed further.
     * @param fallbackValue if call fails this value will be passed further
     */
    handleCommonResponseData(fallbackValue = null) {
        return ({ data }) => (data && data.success) ? data.data : fallbackValue;
    }

    getData(action) {
        return axios.get(this.prepareUrl(action))
            .catch(this.handleError);
    }

    postData(action, data = {}) {
        return axios.post(this.prepareUrl(action), data)
            .catch(this.handleError);
    }

    async userLogout() {
        return await this.postData('logout')
            .then(this.handleCommonResponseData());
    }

    /**
     * @returns Object|null
     */
    async getSingleVideo(video_id) {
        return await this.postData('get-single-video', { video_id })
            .then(this.handleCommonResponseData());
    }

    /**
     * @returns Array|null
     */
    async getFeaturedVideos() {
        return await this.getData('get-featured-videos')
            .then(this.handleCommonResponseData());
    }

    /**
     * @returns Array|null
     */
    async getFavoriteVideos() {
        return await this.getData('get-favorite-videos')
            .then(this.handleCommonResponseData());
    }

    /**
     * @returns Array|null
     */
    async getUserVideos() {
        return await this.getData('get-user-videos')
            .then(this.handleCommonResponseData());
    }

    /**
     * @returns Number
     */
    async getFavoriteVideosCount() {
        return await this.getData('get-favorite-videos-count')
            .then(this.handleCommonResponseData(0));
    }

    /**
     * @returns Number
     */
    async getNotificationsCount() {
        return await this.getData('get-notifications')
            .then(this.handleCommonResponseData([]))
            .then((notifications) => notifications.length);
    }

    async getNotifications() {
        return await this.getData('get-notifications')
            .then(this.handleCommonResponseData([]));
    }

    async clearNotifications() {
        return await this.postData('clear-notifications-count')
            .then(this.handleCommonResponseData(false));
    }

    /**
     * @param {Number} video_id
     * @returns Boolean
     */
    async setFavoriteVideo(video_id) {
        return await this.postData('set-favorite-video', { video_id })
            .then(({ data }) => data);
    }

    async getEvents() {
        return await this.getData('get-events')
            .then(({ data }) => data);
    }

    async getElearnings() {
        // Works like forEach but runs callback for each entry asynchronously
        // and then waits for all executions
        const asyncForEach = async (array, callback) => {
            if (!Array.isArray(array)) {
                return null;
            }

            const tasks = [];
            array.forEach((entry) => { tasks.push(callback(entry)); } );
            return await Promise.all(tasks);
        };

        let data = await this.getData('get-elearnings')
            .then(({ data }) => data);
        if (window.siteData && window.siteData.user) {
            let email = window.siteData.user.email;
            await asyncForEach(data, async (entry) => {
                try {
                    const details = await this.getElearningData(
                        entry.accreditation_uuid, email
                    );
                    entry.isCompleted = details.filter((item) => item.success).length > 0;
                    entry.isDisabled =  details.filter((item) => item.attempts_left === -1).length > 0;
                } catch (err) {
                    entry.isCompleted = false;
                    entry.isDisabled = false;
                    console.log(`Get details for elearning error: ${err}`);
                }
            })
        }
        return data;
    }

    /**
     * Get details about elearning attempts
     * @param {String} uuid
     * @param {String} client
     * @param {String} email
     * @param {String} big
     * @returns {}
     */
    async getElearningData(uuid, email) {
        const big = '1234567890';
        return await this.postData('get-elearning-details', {
            uuid, email, big
        }).then(({ data }) => data)

    }

    /**
     * Fetches the video data and exam that should be displayed during the playback
     * @returns Object
     */
    async getElearningDuringExam(uuid) {
        return await this.postData('get-elearning-during-exam', { uuid })
            .then(({ data }) => data);
    }

    /**
     * Fetches the pre- and post-exam questions that should be used to accreditate HCP
     * @returns Object
     * @param {String} uuid of the elearnig
     */
    async getElearningAccreditation(uuid) {
        return await this.postData('get-accreditation', { uuid })
            .then(({ data }) => data);
    }

    async setAccreditationPreEvaluation({ uuid, answers }) {
        // Temporary solution, cause process wire doesn't support multi-dimensional arrays
        answers = JSON.stringify(answers);

        return await this.postData('create-evaluation', {
            type: 'pre',
            uuid,
            answers
        })
            .then(({ data }) => data);
    }

    async setAccreditationPostEvaluation({ uuid, answers }) {
        // Temporary solution, cause process wire doesn't support multi-dimensional arrays
        answers = JSON.stringify(answers);

        return await this.postData('create-evaluation', {
            type: 'post',
            uuid,
            answers
        })
            .then(({ data }) => data);
    }

    /**
     * @returns {Promise<Channel[]>}
     */
    getChannels() {
        return this.getData('get-channels').then(this.handleCommonResponseData([]));
    }

    getRegistrationFields(channelId) {
        return this.postData('get-registration-fields', { channelId })
            .then(this.handleCommonResponseData([]));
    }

    /**
     * @returns {Promise<Accreditation[]>}
     */
    async getAccreditations() {
        const accreditations = await this.getData('get-accreditations').then(this.handleCommonResponseData([]));

        // Parse the date string into a JS date
        return accreditations.map(element => ({...element, date: new Date(element.date)}));
    }

    /**
     * @returns {Promise<EventParticipation[]>}
     */
    async getEventParticipations() {
        const eventParticipations = await this.getData('get-event-participations').then(this.handleCommonResponseData([]));

        // Parse the dates
        return eventParticipations.map(element => ({
                ...element,
                registered_at: new Date(element.registered_at),
                event_date: new Date(element.event_date),
        }));
    }

    getChannelMetas(channelId) {
        return this.postData('get-channel-metas', { channelId })
            .then(this.handleCommonResponseData({}));
    }
}

// Use service class as a singleton
export default new Service();

<template>

    <main id="home" :class="{
        'mb-5': !isUk && currentUser,
    }">
        <hero-banner
            :headline="headline"
            scroll-down-target="#under-banner-target"
        />

        <div id="under-banner-target"></div>

        <div v-if="currentUser && showEvents" class="t-bg-light pt-5 pb-5">
            <events-list-section
                :title="$t('Events.upcomingEvents')"
                :events="featuredEvents"
                :is-loading="isEventsLoading"
                :limit="4"
                :number-of-featured="1"
            />

            <div class="text-center mt-3">
                <a v-if="eventsPageUrl" :href="eventsPageUrl" class="btn btn-lg btn-primary">
                    {{ $t('Events.allEvents') }}
                    <icon glyph="arrow-alt-circle-right" class="ml-3" />
                </a>
            </div>
        </div>

        <div v-if="!isUk && currentUser">
            <video-list-section
                :title="$t('VideoChannel.featuredVideos')"
                :videos="featuredVideos"
                :is-loading="isVideosLoading"
                :limit="3"
                :number-of-featured="1"
            />

            <div class="text-center mt-3">
                <a v-if="videoChannelPageUrl" :href="videoChannelPageUrl" class="btn btn-lg btn-primary">
                    {{ $t('VideoChannel.allVideos') }}
                    <icon glyph="arrow-alt-circle-right" class="ml-3" />
                </a>
            </div>
        </div>
    </main>

</template>


<script>

import Service from '../../service';
import currentUser from '../../utility/currentUser.js';

import HeroBanner from '../HeroBanner/HeroBanner.vue';
import VideoListSection from '../Video/VideoListSection.vue';
import EventsListSection from '../Events/EventsListSection.vue';

export default {
    name: 'Home',

    components: {
        HeroBanner,
        EventsListSection,
        VideoListSection,
    },

    props: {
        headline: String,
    },

    data() {
        return {
            currentUser,
            isVideosLoading: false,
            featuredVideos: [],
            isEventsLoading: false,
            featuredEvents: [],
            videoChannelPageUrl: window.siteData.videoChannelPageUrl,
            showEvents: window.siteData.showEvents,
            eventsPageUrl: window.siteData.eventsPageUrl,
            isUk: window.siteData.currentLanguage === 'uk',
        };
    },

    methods: {
        async fetchFeauredVideos() {
            this.isVideosLoading = true;
            this.featuredVideos = await Service.getFeaturedVideos();
            this.isVideosLoading = false;
        },

        async fetchFeaturedEvents() {
            this.isEventsLoading = true;
            this.featuredEvents = await Service.getEvents();
            this.isEventsLoading = false;
        },
    },

    created() {
        this.fetchFeauredVideos();

        if (this.currentUser && this.showEvents) {
            this.fetchFeaturedEvents();
        }
    },
}

</script>

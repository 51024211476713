<template>
    <div>
        <select-country
            :languages="languages"
            @redirect="setRegistering()"
            @no-redirect="$emit('success')"
        />
    </div>
</template>


<script>
import SelectCountry from '../SelectCountry/SelectCountry.vue';

export default {
    name: 'RegisterStep1',

    components: {
        SelectCountry,
    },

    props: {
        languages: {
            type: Array,
            required: true,
        },

        isFromNextStep: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        hasSetCountryAlready() {
            return Boolean(
                localStorage.getItem('country') &&
                localStorage.getItem('channelId')
            );
        },

        setRegistering() {
            localStorage.setItem('registering', true);
        },
    },

    created() {
        if (this.hasSetCountryAlready() && !this.isFromNextStep) {
            this.$emit('success');
        }
    },
}

</script>

<template>

    <modal
        width="400"
        height="auto"
        scrollable
        adaptive
        :name="modalName"
    >
        <modal-action-buttons :close-modal="modalName" />

        <div class="b-modal-content">
            <div v-if="success" class="text-center">
                <img src="/site/templates/img/general/paper-plane.svg">
                <div class="mt-4" v-html="$t('SingleVideo.msgThanks')"></div>
            </div>

            <form-generator
                v-else
                :url="formEndpointUrl"
                :fields="formFields"
                :config="formConfig"
                :additional-values="formAdditionalValues"
                @success="onSuccess"
            />
        </div>
    </modal>

</template>


<script>

import apiUrl from '../../utility/apiUrl.js';

import ModalActionButtons from '../Common/ModalActionButtons.vue';
import FormGenerator from '../Forms/FormGenerator.vue';

export default {
    name: 'VideoMessage',

    components: {
        ModalActionButtons,
        FormGenerator,
    },

    props: {
        modalName: {
            type: String,
            required: true,
        },
        videoId: {
            type: String,
            required: true,
        },
        videoTitle: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            success: false,
            formEndpointUrl: apiUrl('send-video-message'),
            formConfig: {
                title: this.$i18n.i18next.t('SingleVideo.msgTitle'),
                submitTitle: this.$i18n.i18next.t('Common.send'),
            },
            formFields: {
                message: {
                    type: 'textarea',
                    placeholder: this.$i18n.i18next.t('SingleVideo.msgPlaceholder'),
                    required: true,
                },
            },
            formAdditionalValues: {
                video_id: this.videoId,
            },
        }
    },

    methods: {
        onSuccess() {
            this.success = true;
        },
    },

    created() {
        this.formConfig.subtitle = '"' + this.videoTitle + '"';
    },
}

</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "c-scroll-top-btn b-action-btn",
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.scrollTop($event)
        }
      }
    },
    [_c("icon", { attrs: { glyph: "chevron-up", "is-solid": true } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <h4 class="mb-1 mt-2 text-center">
            {{ $t('SelectCountry.title') }}
        </h4>
        <loader v-if="isLoading" />
        <div v-else>
            <form-generator
                :fields="formFields"
                :config="formConfig"
                @success="save"
            />
        </div>
    </div>
</template>

<script>
import Service from '../../service';
import FormGenerator from '../Forms/FormGenerator.vue';
import Loader from '../Common/Loader.vue';

export default {
    name: 'SelectCountry',

    components: {
        FormGenerator,
        Loader,
    },

    props: {
        languages: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            formConfig: {
                subtitle: this.$i18n.i18next.t('SelectCountry.subtitle'),
                submitTitle: this.$i18n.i18next.t('SelectCountry.save'),
            },
            formFields: {
                country: {
                    type: 'dropdown',
                    options: {},
                    label: this.$i18n.i18next.t('FormFields.country')
                },
            },
            isLoading: false,
        }
    },

    methods: {
        save({ formModel: { country } }) {
            const [channelId, countryTitle] = country.split('|');
            localStorage.setItem('country', countryTitle);
            localStorage.setItem('channelId', channelId);
            const language = this.getLanguageFor(countryTitle, parseInt(channelId, 10));

            if (language.name !== 'default' && !window.location.pathname.includes(language.name)) {
                this.$emit('redirect');
                const url = new URL(window.location.href);
                url.pathname = language.name;
                window.location.replace(url.toString());
                return;
            }

            this.$emit('no-redirect');
        },

        async populateCountryOptions() {
            this.isLoading = true;
            const channels = await Service.getChannels();
            this.formFields.country.options = channels.reduce((channels, channel) => {
                const id = `${channel.id}|${channel.title}`;
                channels[id] = channel.title;
                return channels;
            }, {});
            this.isLoading = false;
        },

        getLanguageFor(country, channelId) {
            // Try to find language where the title matches
            const language = this.languages.find(({ title }) => {
                if (
                    typeof title === 'string' &&
                    country.toLowerCase().trim() === title.toLowerCase().trim()
                ) {
                    return true;
                }
            });

            if (language) {
                return language;
            }

            // Try to find language with the channel id
            const channelLanguage = this.languages.find(({ channel }) => channel === channelId);
            if (channelLanguage) {
                return channelLanguage;
            }

            // Try to find the default language
            const defaultLanguage = this.languages.find(({ name }) => name === 'default');
            if (defaultLanguage) {
                return defaultLanguage;
            }

            // If all else fails, return the first language
            return this.languages[0];
        },
    },

    created() {
        this.populateCountryOptions();
    },
};
</script>

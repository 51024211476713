<template>

    <div class="c-inline-radio-select form-group">
        <template
            v-if="Object.keys(filteredOptions).length > 1"
            v-for="(label, radioValue) in filteredOptions"
        >
            <input
                type="radio"
                class="c-inline-radio-select__radio"
                :key="`radio_${radioValue}`"
                :ref="radioValue"
                :name="name"
                :id="radioValue"
                :value="radioValue"
                @change="onChange"
            >
            <label
                :key="`label_${radioValue}`"
                :for="radioValue"
                class="c-inline-radio-select__label"
            >
                {{ label }}
            </label>
        </template>
    </div>

</template>


<script>
import { pickBy } from 'lodash-es';

export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        options: {
            type: Object,
            required: true,
        },
        value: {
            type: String,
        },
        default: {
            type: String
        },
    },

    computed: {
        filteredOptions: function () {
            // Return only options with a value
            return pickBy(this.options);
        }
    },

    methods: {
        onChange(event) {
            this.$emit('input', event.target.value);
        },
    },

    mounted() {
        if (this.default && this.$refs[this.default]) {
            this.$refs[this.default][0].checked = true;
        }
    },
}

</script>

<template>

    <component
        class="c-card"
        :is="(href) ? 'a' : 'div'"
        :class="{
            'c-card--covered': isCovered,
            'c-card--horizontal': isHorizontal && !isCovered,
            'is-favorite-hovered': favoriteHovered
        }"
        :href="href"
        :target="(href && targetBlank) ? '_blank' : null"
        @click="verifyUser"
    >

        <!--
            Cover image
        -->
        <div
            v-if="coverImage || isCovered || isHorizontal"
            class="c-card__cover"
        >
            <span v-if="coverIcon && !isCovered" class="c-card__btn">
                <icon :glyph="coverIcon" is-solid />
            </span>

            <div class="c-card__cover__inner">
                <img v-if="coverImage" :src="coverImage" alt="">
            </div>
        </div>

        <!--
            Content
        -->
        <div class="c-card__content">
            <p v-if="tag" class="c-card__content__tag">
                <icon v-if="tagIcon" :glyph="tagIcon" size="lg" is-solid />
                {{ tag }}
            </p>

            <p class="c-card__content__title" v-html="title"></p>
            <div v-if="description" v-html="description" class="c-card__content__desc u-truncate-text"></div>

            <div class="c-card__content__bottom">
                <ul class="b-inline-list">
                    <li
                        v-for="(info, infoIndex) in infoListFiltered" :key="infoIndex"
                        v-html="info"
                        class="b-inline-list__item"
                    ></li>
                </ul>

                <a
                    v-if="btnText && btnHref"
                    class="btn btn-primary"
                    :href="btnHref"
                    :target="(btnHref && targetBlank) ? '_blank' : null"
                >{{ btnText }}</a>
            </div>
        </div>

        <!--
            Top icons
        -->
        <div class="c-card__icons">
            <div
                v-if="markedByIcon"
                v-tooltip="markedByText"
                class="c-card__icons__icon c-card__icons__icon--marker"
            >
                <icon :glyph="markedByIcon" size="lg" is-solid />
            </div>

            <div
                v-if="useFavorites"
                class="c-card__icons__icon c-card__icons__icon--action"
                v-tooltip="(isFavorite) ? $t('Favorites.remove') : $t('Favorites.add')"
                @click.prevent="favorite"
                @mouseover="favoriteHovered = true"
                @mouseleave="favoriteHovered = false"
            >
                <icon glyph="heart" :is-solid="isFavorite" size="lg" hoverable />
            </div>
        </div>

        <!-- 
            Additional content
        -->
        <slot></slot>
        
    </component>

</template>


<script>

import currentUser from '../../utility/currentUser.js';

export default {
    name: 'card',

    props: {
        tag: String,
        tagIcon: String,
        title: String,
        description: String,
        href: String,
        coverImage: String,
        coverIcon: String,
        markedByIcon: String,
        markedByText: String,
        isFavorite: Boolean,
        isCovered: Boolean, // Decides if the image should be put in the background and move the text over it
        isHorizontal: Boolean,
        onlyLoggedIn: Boolean,
        useFavorites: Boolean,
        targetBlank: Boolean,
        btnText: String,
        btnHref: String,
        infoList: {
            type: Array, // Array of strings
            default() {
                return [];
            } 
        }
    },

    data() {
        return {
            favoriteHovered: false,
        };
    },

    computed: {
        infoListFiltered() {
            return this.infoList.filter(Boolean); // Remove empty/falsy values
        },
    },

    methods: {
        /**
         * Check if user is logged in. If not show login form.
         */
        verifyUser(event) {
            if (this.onlyLoggedIn && !currentUser) {
                event.preventDefault();
                this.$modal.show('login-modal');
            }
        },

        favorite() {
            this.$emit('favorite-change');
        },
    }
}

</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      !_vm.currentUser
        ? [
            _c("subpage-banner", {
              staticClass: "mb-5",
              attrs: {
                header: _vm.event.title || _vm.$t("Events.details"),
                smaller: ""
              }
            }),
            _vm._v(" "),
            _c("not-logged-user-message", { staticClass: "mb-5" })
          ]
        : _vm.isLoading
          ? _c("loader", { staticClass: "mt-5" })
          : _vm.event
            ? [
                _c(
                  "subpage-banner",
                  {
                    staticClass: "mb-5",
                    attrs: {
                      header: _vm.event.title,
                      "banner-image-src": _vm.event.thumbnail_url,
                      smaller: ""
                    }
                  },
                  [
                    _c("ul", { staticClass: "b-inline-list" }, [
                      _c("li", [_vm._v(_vm._s(_vm.event.type))]),
                      _vm._v(" "),
                      _c("li", [
                        _c("strong", [_vm._v(_vm._s(_vm.dateFormatted))])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "container-xl mt-5 mb-5" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 col-lg-8" }, [
                      _c("article", {
                        staticClass: "t-article mt-2",
                        domProps: { innerHTML: _vm._s(_vm.event.description) }
                      })
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "t-bg-light mt-5 mb-5 pt-5 pb-4" }, [
                  _c("div", { staticClass: "container-xl" }, [
                    _c("h2", { staticClass: "mb-5" }, [
                      _vm._v(_vm._s(_vm.$t("Events.agenda")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-8" }, [
                        _c("table", { staticClass: "table" }, [
                          _c(
                            "tbody",
                            _vm._l(_vm.event.agenda, function(
                              entry,
                              entryIndex
                            ) {
                              return _c("tr", { key: entryIndex }, [
                                _c("td", [
                                  _c("strong", [_vm._v(_vm._s(entry.time))])
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("strong", [_vm._v(_vm._s(entry.title))]),
                                  _vm._v(" "),
                                  _vm.getSpeaker(entry.speaker_id)
                                    ? _c("div", [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.showSpeakerInfo(
                                                  entry.speaker_id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(
                                                  _vm.getSpeaker(
                                                    entry.speaker_id
                                                  ).name
                                                ) +
                                                "\n                                            "
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            }),
                            0
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-4 text-center pt-5 pb-5" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary btn-lg",
                              on: { click: _vm.showRegisterForm }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("Events.registerForTheEvent")) +
                                  "\n                            "
                              ),
                              _c("icon", {
                                staticClass: "ml-2",
                                attrs: { glyph: "user-plus", "is-solid": "" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "container-xl mb-5" }, [
                  _c("h2", { staticClass: "mb-3" }, [
                    _vm._v(_vm._s(_vm.$t("Events.speakers")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.event.speakers, function(speaker, speakerIndex) {
                      return _c(
                        "div",
                        {
                          key: speakerIndex,
                          staticClass: "col-12 col-lg-6 col-xxl-4"
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "b-speaker",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.showSpeakerInfo(speaker.id)
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "b-speaker__photo" }, [
                                _c("img", {
                                  attrs: {
                                    src: speaker.photo,
                                    alt: "Speaker photo"
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "b-speaker__info" }, [
                                _c("h4", [_vm._v(_vm._s(speaker.name))]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(speaker.desc))]),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticClass: "text-primary" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.$t("Events.moreAboutMe")) +
                                        "\n                                "
                                    ),
                                    _c("icon", {
                                      attrs: {
                                        glyph: "chevron-right",
                                        "is-solid": ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ])
              ]
            : _vm._e(),
      _vm._v(" "),
      _c("event-registration-form", {
        attrs: { "modal-name": _vm.registerModalName }
      }),
      _vm._v(" "),
      _c(
        "modal",
        { attrs: { name: _vm.speakerInfoModalName, height: "auto" } },
        [
          _c("modal-action-buttons", {
            attrs: { "close-modal": _vm.speakerInfoModalName }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "b-modal-content" }, [
            _c("img", {
              staticClass: "mb-4",
              attrs: { src: _vm.currentSpeaker.photo, alt: "Speaker photo" }
            }),
            _vm._v(" "),
            _c("h2", [_vm._v(_vm._s(_vm.currentSpeaker.name))]),
            _vm._v(" "),
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.currentSpeaker.desc) }
            })
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-video-details" },
    [
      _c("subpage-banner", {
        attrs: {
          header: _vm.currentElearning
            ? _vm.currentElearning.title
            : _vm.$t("Elearning.details"),
          smaller: ""
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container-xl c-video-details__container mb-5" },
        [
          !_vm.currentUser
            ? _c("not-logged-user-message", { staticClass: "mb-5" })
            : _c("div", { staticClass: "row" }, [
                _c(
                  "main",
                  { staticClass: "col-lg-9" },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade-in" } },
                      [
                        _vm.isLoading
                          ? _c("loader", { staticClass: "my-5" })
                          : _vm.video
                            ? _c(
                                "div",
                                [
                                  _c("elearning-video", {
                                    staticClass: "mb-5",
                                    attrs: {
                                      video: _vm.video,
                                      "pre-exam-questions":
                                        _vm.preExamQuestions,
                                      "during-exam-questions":
                                        _vm.duringExamQuestions,
                                      "accreditation-uuid":
                                        _vm.accreditationUuid,
                                      "is-already-completed":
                                        _vm.currentElearning.isCompleted
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("h3", [
                                    _vm._v(_vm._s(_vm.$t("Common.details")))
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.currentElearning.description
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("message-box", {
                                attrs: {
                                  icon: "times-circle",
                                  title: _vm.$t("Elearning.doesNotExist")
                                }
                              })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("aside", { staticClass: "col-lg-3" }, [
                  _c("h3", { staticClass: "h6" }, [
                    _vm._v(_vm._s(_vm.$t("Common.relatedInformation")))
                  ]),
                  _vm._v(" "),
                  _vm.isLoading
                    ? _c(
                        "div",
                        { staticClass: "text-center mt-4" },
                        [_c("loader")],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "row" },
                        _vm._l(_vm.relatedElearnings, function(entry) {
                          return _c(
                            "div",
                            {
                              key: entry.id,
                              staticClass: "col-sm-6 col-lg-12 col-ul-6 mt-4"
                            },
                            [_c("elearning-card", { attrs: { entry: entry } })],
                            1
                          )
                        }),
                        0
                      )
                ])
              ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            width: "600",
            height: "auto",
            scrollable: "",
            adaptive: "",
            "click-to-close": false,
            name: _vm.blockedModalName
          }
        },
        [
          _c("div", { staticClass: "b-modal-content text-center" }, [
            _c("h4", { staticClass: "mb-4" }, [
              _vm._v(_vm._s(_vm.$t("Elearning.examFinished")))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-50 m-auto text-center" }, [
              _c("h5", { staticClass: "p-2" }, [
                _vm._v(_vm._s(_vm.$t("Elearning.noAttemptsLeft")))
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary text-white",
                  attrs: { href: "/elearning/" }
                },
                [_vm._v(_vm._s(_vm.$t("Elearning.goBackToListing")))]
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
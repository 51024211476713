var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "container-xl" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-in" } },
        [
          !_vm.currentUser
            ? _c("not-logged-user-message", { staticClass: "mt-5" })
            : !_vm.showEvents
              ? _c("message-box", {
                  attrs: {
                    message: _vm.$("Common.featureNotAvailableForChannel"),
                    icon: "times-circle"
                  }
                })
              : _vm.isLoading
                ? _c("loader", { staticClass: "my-5" })
                : _vm.events && _vm.events.length
                  ? _c(
                      "div",
                      { staticClass: "b-timeline" },
                      _vm._l(_vm.eventsGrouped, function(
                        monthGroup,
                        monthIndex
                      ) {
                        return _c(
                          "div",
                          { key: monthIndex },
                          [
                            _c("div", { staticClass: "b-timeline__row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "b-timeline__row__track b-timeline__row__track--main"
                                },
                                [
                                  _c("p", { staticClass: "h4" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("MonthNames." + monthGroup.month)
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "h6" }, [
                                    _vm._v(_vm._s(monthGroup.year))
                                  ])
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(monthGroup.days, function(
                              dayGroup,
                              dayIndex
                            ) {
                              return _c("div", { key: dayIndex }, [
                                _c("div", { staticClass: "b-timeline__row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "b-timeline__row__track b-timeline__row__track--sub"
                                    },
                                    [
                                      _c("p", [
                                        _c("strong", [
                                          _vm._v(_vm._s(dayGroup.day))
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "MonthNames." + monthGroup.month
                                              )
                                            )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "b-timeline__row__content" },
                                    [
                                      _c("events-list", {
                                        attrs: {
                                          events: dayGroup.events,
                                          "is-horizontal": ""
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ])
                            })
                          ],
                          2
                        )
                      }),
                      0
                    )
                  : _c("message-box", {
                      staticClass: "mt-5",
                      attrs: {
                        icon: "times-circle",
                        title: _vm.$t("Common.emptyList")
                      }
                    })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
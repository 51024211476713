<template>

    <main>
        <template v-if="!currentUser">
            <subpage-banner
                class="mb-5"
                :header="event.title || $t('Events.details')"
                smaller
            />

            <not-logged-user-message class="mb-5" />
        </template>

        <loader v-else-if="isLoading" class="mt-5" />

        <template v-else-if="event">
            <subpage-banner
                class="mb-5"
                :header="event.title"
                :banner-image-src="event.thumbnail_url"
                smaller
            >
                <ul class="b-inline-list">
                    <li>{{ event.type }}</li>
                    <li><strong>{{ dateFormatted }}</strong></li>
                </ul>
            </subpage-banner>

            <!--
                About
            -->
            <div class="container-xl mt-5 mb-5">
                <div class="row">
                    <div class="col-12 col-lg-8">
                        <article
                            v-html="event.description"
                            class="t-article mt-2"
                        ></article>
                    </div>
                </div>
            </div>

            <!--
                Agenda
            -->
            <div class="t-bg-light mt-5 mb-5 pt-5 pb-4">
                <div class="container-xl">
                    <h2 class="mb-5">{{ $t('Events.agenda') }}</h2>

                    <div class="row">
                        <div class="col-8">
                            <table class="table">
                                <tbody>
                                    <tr v-for="(entry, entryIndex) in event.agenda" :key="entryIndex">
                                        <td><strong>{{ entry.time }}</strong></td>
                                        <td>
                                            <strong>{{ entry.title }}</strong>
                                            <div v-if="getSpeaker(entry.speaker_id)">
                                                <a href="#" @click.prevent="showSpeakerInfo(entry.speaker_id)">
                                                    {{ getSpeaker(entry.speaker_id).name }}
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-4 text-center pt-5 pb-5">
                            <button class="btn btn-primary btn-lg" @click="showRegisterForm">
                                {{ $t('Events.registerForTheEvent') }}
                                <icon glyph="user-plus" class="ml-2" is-solid />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!--
                Speakers
            -->
            <div class="container-xl mb-5">
                <h2 class="mb-3">{{ $t('Events.speakers') }}</h2>

                <div class="row">
                    <div
                        v-for="(speaker, speakerIndex) in event.speakers"
                        class="col-12 col-lg-6 col-xxl-4"
                        :key="speakerIndex"
                    >
                        <a class="b-speaker" @click.prevent="showSpeakerInfo(speaker.id)">
                            <div class="b-speaker__photo">
                                <img :src="speaker.photo" alt="Speaker photo">
                            </div>
                            <div class="b-speaker__info">
                                <h4>{{ speaker.name }}</h4>
                                <p>{{ speaker.desc }}</p>
                                <p class="text-primary">
                                    {{ $t('Events.moreAboutMe') }}
                                    <icon glyph="chevron-right" is-solid />
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </template>

        <event-registration-form
            :modal-name="registerModalName"
        />

        <modal
            :name="speakerInfoModalName"
            height="auto"
        >
            <modal-action-buttons :close-modal="speakerInfoModalName" />

            <div class="b-modal-content">
                <img :src="currentSpeaker.photo" alt="Speaker photo" class="mb-4">
                <h2>{{ currentSpeaker.name }}</h2>
                <p v-html="currentSpeaker.desc"></p>
            </div>
        </modal>
    </main>

</template>


<script>

import apiUrl from '../../utility/apiUrl';
import axios from 'axios';
import currentUser from '../../utility/currentUser.js';
import { formatDate } from '../../utility/filters.js';

import EventRegistrationForm from './EventRegistrationForm.vue';
import ModalActionButtons from '../Common/ModalActionButtons.vue';

export default {
    name: 'EventDetailsPage',

    components: {
        ModalActionButtons,
        EventRegistrationForm,
    },

    props: {
        eventId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            isLoading: false,
            event: null,
            registerModalName: 'register-modal',
            speakerInfoModalName: 'speaker-info-modal',
            currentSpeaker: {},
            currentUser,
        };
    },

    computed: {
        speakers() {
            return (this.event && this.event.speakers) ? this.event.speakers : [];
        },

        dateFormatted() {
            return (this.event) ? formatDate(this.event.date) : '';
        }
    },

    methods: {
        async fetchEventData() {
            this.isLoading = true;

            await axios.get(apiUrl('get-event-details'))
                .then(({ data }) => {
                    if (data.success) {
                        this.event = data.data;
                    }
                })
                .catch((error) => {
                    console.error(error);
                });

            this.isLoading = false;
        },

        showRegisterForm() {
            this.$modal.show(this.registerModalName);
        },

        getSpeaker(speakerId) {
            return (this.speakers.length)
                ? this.speakers.find(({ id }) => (id === speakerId))
                : null;
        },

        showSpeakerInfo(speakerId) {
            this.currentSpeaker = this.getSpeaker(speakerId);

            if (this.currentSpeaker) {
                this.$modal.show(this.speakerInfoModalName);
            }
        },
    },

    created() {
        if (this.currentUser) {
            this.fetchEventData();
        }
    },
};

</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("select-country", {
        attrs: { languages: _vm.languages },
        on: {
          redirect: function($event) {
            return _vm.setRegistering()
          },
          "no-redirect": function($event) {
            return _vm.$emit("success")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>

    <div class="b-modal-content b-modal-content--centered">
        <img class="mb-3" src="/site/templates/img/general/paper-plane.svg" alt="Confirmation sent">
        <div v-html="$t('RegisterNoBig.thankYou')"></div>
    </div>

</template>


<script>

export default {
    name: 'RegisterStep3',
}

</script>

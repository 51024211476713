var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { row: !_vm.isHorizontal } },
    [
      _vm._l(_vm.events, function(event, index) {
        return [
          _vm.numberOfFeatured > 0 && index < _vm.numberOfFeatured
            ? _c(
                "div",
                {
                  key: event.uuid || index,
                  staticClass: "mt-3 mb-5",
                  class: { "col-xs-12": !_vm.isHorizontal }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        class: event.thumbnail_url
                          ? "col-lg-6 pt-4 pb-4"
                          : "col-12"
                      },
                      [
                        _c(
                          "ul",
                          {
                            staticClass:
                              "b-inline-list font-weight-bold text-primary"
                          },
                          [
                            event.speaker
                              ? _c("li", [_vm._v(_vm._s(event.speaker))])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(_vm._s(_vm.formatDate(event.date)))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("h2", { staticClass: "mt-4 mb-4" }, [
                          _vm._v(_vm._s(event.title))
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "u-truncate-text",
                          domProps: { innerHTML: _vm._s(event.description) }
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-primary mt-4",
                            attrs: { href: event.url }
                          },
                          [_vm._v(_vm._s(_vm.$t("Common.details")))]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    event.thumbnail_url
                      ? _c("div", { staticClass: "col-lg-6 u-image-cover" }, [
                          _c("img", {
                            attrs: { src: event.thumbnail_url, alt: "" }
                          })
                        ])
                      : _vm._e()
                  ])
                ]
              )
            : _c(
                "div",
                {
                  key: event.uuid || index,
                  staticClass: "mb-4",
                  class: { "col-md-6 col-xl-4": !_vm.isHorizontal }
                },
                [
                  _c("card", {
                    attrs: {
                      tag: event.type,
                      title: event.title,
                      description: event.description,
                      "cover-image": event.thumbnail_url,
                      "info-list": [
                        _vm.$t("Common.date") +
                          ": " +
                          _vm.formatDate(event.date),
                        event.duration
                          ? _vm.$t("Common.duration") + ": " + event.duration
                          : null
                      ],
                      btnText: _vm.$t("Common.details"),
                      btnHref: event.url,
                      "is-horizontal": _vm.isHorizontal,
                      "target-blank": "",
                      "only-logged-in": ""
                    }
                  })
                ],
                1
              )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { loadScript } from '../utility/loadExternalResource';

const janrainSSOlibrary = 'https://d1v9u0bgi1uimx.cloudfront.net/static/sso_lite.js';
const clientData = {
    client_id: '3vaka5qsar97wdy2fbtpgtup7fsjp6yn',
    flow_name: 'hcp_standard',
    flow_version: '20201102085916231018',
    locale: 'en-US',
    redirect_uri: 'http://localhost/',
    sso_server: 'https://sanofi-dev.us.janrainsso.com/',
    xd_receiver: 'http://rdnexus.com/',
};

/**
 * Simple integration of Janrain Single Sign On library.
 * @link https://janrain-education-center.knowledgeowl.com/home/single-sign-on-for-the-registration-apis
 */
class JanrainIntegration {
    constructor() {
        this.initiated = false;
    }

    /**
     * Prepare the environment for Janrain to be able of running
     */
    async init() {
        if (this.initiated) {
            return true;
        }

        await loadScript(janrainSSOlibrary);

        this.initiated = true;

        /**
         * Setup the session and the callbacks for the actions.
         * This callbacks are fired when for example we are trying to log in.
         */
        JANRAIN.SSO.check_session({
            ...clientData,
            capture_success(data) {
                console.log('Janrain: session captured', data);
            },
            capture_error(data) {
                console.log('Janrain: capture error', data);
            },
            // Login successfull
            callback_success(data) {
                console.log('Janrain: session callback', data);
            },
            // Login failure
            callback_failure(data) {
                console.log('Janrain: session check fail', data);
            },
        });

        return true;
    }

    /**
     * @param {String} ssoCode
     */
    async login(ssoCode) {
        if (!ssoCode) {
            throw new Error('Janrain: missing "sso_code".');
        }
        await this.init();
        return JANRAIN.SSO.set_session(ssoCode);
    }

    async logout() {
        await this.init();
        return JANRAIN.SSO.end_session(() => console.info('Janrain: session terminated'));
    }
};

export default new JanrainIntegration();

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.questions.length
        ? _c("div", { staticClass: "all-question-loader" }, [_c("loader")], 1)
        : [
            _vm.questions.length > 1
              ? [
                  _c("progress-steps", {
                    attrs: {
                      "steps-number": _vm.questions.length,
                      "current-step": _vm.activeQuestionIndex + 1,
                      "steps-checked": _vm.questionsVoted
                    }
                  }),
                  _vm._v(" "),
                  _c("p", [
                    _c("small", [
                      _vm._v(_vm._s(_vm.$t("Rating.ratingInfoText")))
                    ])
                  ])
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.loading
              ? _c("loader")
              : _c("div", { staticClass: "single-question" }, [
                  _c("div", { staticClass: "title-text" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.activeQuestion.body) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ratings-stars" },
                    _vm._l(_vm.starVotes, function(starVote, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "rate-item",
                          on: {
                            click: function($event) {
                              return _vm.voteForQuestion(starVote)
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "icon" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(starVote.label))
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
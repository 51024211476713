var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "scale-in" } },
    [
      _vm.loggedInSuccessful
        ? _c(
            "message-box",
            {
              attrs: {
                icon: "check-circle",
                "icon-class": "text-success",
                title: _vm.$t("Login.successMessage")
              }
            },
            [
              _c("button", {
                staticClass: "btn btn-primary mt-3",
                domProps: { innerHTML: _vm._s(_vm.$t("Common.ok")) },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.refreshPage($event)
                  }
                }
              })
            ]
          )
        : _c("form-generator", {
            attrs: {
              url: _vm.endpointUrl,
              fields: _vm.formFields,
              config: _vm.formConfig
            },
            on: {
              "forgot-password": _vm.linkActionForgot,
              register: _vm.linkActionRegister,
              success: _vm.onSuccess
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
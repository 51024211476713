var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        width: "800",
        height: "auto",
        scrollable: "",
        adaptive: "",
        name: _vm.modalName
      }
    },
    [
      _c("modal-action-buttons", {
        attrs: {
          "back-action": _vm.currentStep > 1 ? "go-back" : null,
          "close-modal": _vm.modalName
        },
        on: { "go-back": _vm.switchToPreviousStep }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "b-modal-content" },
        [
          _c("h4", { staticClass: "mb-4 text-center" }, [
            _vm._v(_vm._s(_vm.$t("RegisterNoBig.title")))
          ]),
          _vm._v(" "),
          _c("progress-steps", {
            attrs: {
              "steps-number": _vm.stepsNumber,
              "current-step": _vm.currentStep
            }
          }),
          _vm._v(" "),
          _vm.currentStep === 1
            ? _c("reg-step-1", {
                attrs: {
                  languages: _vm.languages,
                  "is-from-next-step": _vm.goneBackToStepOne
                },
                on: {
                  success: _vm.switchToNextStep,
                  "go-back": _vm.switchToPreviousStep
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.currentStep === 2
            ? _c("reg-step-2", {
                on: {
                  success: _vm.switchToNextStep,
                  "go-back": _vm.switchToPreviousStep
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.currentStep === 3
            ? _c("reg-step-3", { on: { "go-back": _vm.switchToPreviousStep } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "thanks-screen" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "title-text" }, [
      _c("span", {
        domProps: { innerHTML: _vm._s(_vm.$t("Rating.thanksTitle")) }
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v("\n        " + _vm._s(_vm.$t("Rating.thanksText")) + "\n    ")
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "accent-icon" }, [
      _c("img", {
        staticClass: "icon",
        attrs: { src: "/site/templates/img/general/rating-survey-end.svg" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>

    <div class="c-single-text-page">
        <subpage-banner
            :header="title"
            smaller
        />

        <div class="container-xl mt-5">
            <div class="u-medium-content">
                <slot />
            </div>
        </div>
    </div>

</template>


<script>

import SubpageBanner from '../SubpageBanner/SubpageBanner.vue';

export default {
    name: 'SingleTextPage',

    components: {
        SubpageBanner,
    },

    props: {
        title: {
            type: [String],
            required: true
        },
    },
}

</script>

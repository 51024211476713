var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.sourceType === "vimeo"
        ? _c(
            "vimeo-player",
            _vm._g(
              {
                ref: "player",
                attrs: {
                  "video-id": _vm.video.vimeoId,
                  "video-url": _vm.video.url,
                  controls: _vm.controls
                }
              },
              _vm.$listeners
            )
          )
        : _vm.sourceType === "brightcove"
          ? _c(
              "brightcove-player",
              _vm._g(
                {
                  ref: "player",
                  attrs: {
                    "video-id": _vm.video.external_id,
                    controls: _vm.controls,
                    "account-id": _vm.video.brightcove.account_id
                  }
                },
                _vm.$listeners
              )
            )
          : _c("div", { staticClass: "c-video-player" }, [
              _c("div", { staticClass: "c-video-player__error" }, [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$t("SingleVideo.cannotBeDisplayed")))
                ]),
                _c("br"),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("SingleVideo.notSupported")) +
                    "\n        "
                )
              ])
            ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>

    <header
        class="c-site-header"
        id="site-header"
        :class="{'c-site-header--home': isHomePage}"
    >
        <div class="container-xl c-site-header__container">
            <a href="/" class="c-site-header__logo"><img src="/site/templates/img/general/rare-disease-nexus-logo-white.svg"></a>

            <!--
                Menu links
            -->
            <nav class="c-site-header__menu">
                <ul>
                    <!--
                        Regular menu links
                    -->
                    <li
                        v-for="(link, index) in menuLinks"
                        :key="index"
                    >
                        <a :href="link.url">{{ link.name }}</a>
                    </li>

                    <li class="c-site-header__menu__divider"></li>

                    <!--
                        Logged in user toolbar
                    -->
                    <template v-if="isUserLoggedIn">
                        <li>
                            <a @click="openNotifications">
                                <icon
                                    glyph="bell"
                                    class="c-site-header__menu__icon"
                                    :counter="notificationsCount"
                                    :hoverable="true"
                                />
                            </a>
                        </li>

                        <li>
                            <a href="/favorites">
                                <icon
                                    glyph="heart"
                                    class="c-site-header__menu__icon"
                                    :counter="favoriteVideosCount"
                                    :hoverable="true"
                                />
                            </a>
                        </li>

                        <li>
                            <a class="c-site-header__menu__user" @click="openUserProfile">
                                <span>{{ userName }}</span>
                            </a>
                        </li>
                    </template>

                    <!--
                        Not logged in user operations
                    -->
                    <template v-else>
                        <li><a @click="openLoginForm">{{ $t('Login.login') }}</a></li>
                        <li><a @click="openRegisterForm">{{ $t('User.register') }}</a></li>
                    </template>
                </ul>
            </nav>

            <a
                class="c-site-header__toggle"
                @click="toggleSlideNavigation"
            >
                <span></span>
            </a>
        </div>
    </header>

</template>


<script>

import Service from '../../service';

const mobileMenuOpenClass = 'is-mobile-menu-open';

export default {
    name: 'SiteHeader',

    props: {
        menuLinks: Array,
    },

    data() {
        return {
            mobileMenuIsOpen: false,
            favoriteVideosCount: false,
            notificationsCount: false,
            isHomePage: false,
        };
    },

    computed: {
        isUserLoggedIn() {
            return (siteData.user !== false);
        },

        userName() {
            return (this.isUserLoggedIn)
                ? siteData.user.firstname.charAt(0) + siteData.user.lastname.charAt(0)
                : '';
        },
    },

    methods: {
        openLoginForm() {
            this.$modal.show('login-modal');
        },

        openRegisterForm() {
            this.$modal.show('register-modal');
        },

        openUserProfile() {
            this.$modal.show('user-account-modal');
        },

        openNotifications() {
            this.$modal.show('notifications-modal');
        },

        toggleSlideNavigation() {
            this.mobileMenuIsOpen = !this.mobileMenuIsOpen;
            document.body.classList.toggle(mobileMenuOpenClass, (this.mobileMenuIsOpen));
        },

        async fetchNotificationsCount() {
            this.notificationsCount = await Service.getNotificationsCount();
        },

        async fetchFavoriteVideosCount() {
            this.favoriteVideosCount = await Service.getFavoriteVideosCount();
        },
    },

    created() {
        if (this.isUserLoggedIn) {
            this.fetchFavoriteVideosCount();
            this.fetchNotificationsCount();
        }

        window.GlobalEventBus.$on('updateFavoriteVideosCount', () => this.fetchFavoriteVideosCount());
        window.GlobalEventBus.$on('clearNotifications', () => { this.notificationsCount = 0; });
    },

    mounted() {
        this.isHomePage = !!document.getElementById('home');
    },
}

</script>

<template>

    <div v-if="emailSent">
        <div class="vue-modal">
            <img src="/site/templates/img/general/email-sent.svg">

            <div v-html="$t('Forgot.sentEmail')">
                {{ $t('Forgot.sentEmail') }}
            </div>
        </div>
    </div>

    <div v-else>
        <form-generator
            :fields="formFields"
            :config="formConfig"
            :url="endpointUrl"
            @go-back="goBack"
            @success="onSuccess"
        />
    </div>

</template>


<script>

import FormGenerator from './../Forms/FormGenerator.vue';
import ModalActionButtons from './../Common/ModalActionButtons.vue';
import apiUrl from '../../utility/apiUrl';

export default {
    name: 'LoginFormForgotPassword',

    components: {
        FormGenerator,
        ModalActionButtons
    },

    data() {
        return {
            endpointUrl: apiUrl("password-reset"),
            emailSent: false,
            formConfig: {
                title: this.$i18n.i18next.t("Forgot.title"),
                subtitle: this.$i18n.i18next.t("Forgot.fillEmail"),
                submitTitle: this.$i18n.i18next.t("Forgot.setUpNew"),
                errorMessage: this.$i18n.i18next.t("Forgot.resetErrorMessage"),
                successMessage: this.$i18n.i18next.t("Login.resetSuccessMessage"),
                links: [
                    {
                        title: this.$i18n.i18next.t('Login.returnToLoginForm'),
                        action: 'go-back',
                    },
                ],
            },
            formFields: {
                email: {
                    type: 'email',
                    label: this.$i18n.i18next.t("FormFields.email"),
                    required: true,
                },
            },
        }
    },

    methods: {
        goBack() {
            this.$emit('go-back');
        },

        onSuccess() {
            this.emailSent = true;
        }
    }
}

</script>

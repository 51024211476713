export default () => {
    return {
        "Common": {
            "yes": "Sim",
            "no": "Não",
            "ok": "OK",
            "confirm": "Confirme",
            "cancel": "Cancelar",
            "success": "Sucesso",
            "error": "Erro",
            "search": "Busca",
            "next": "PRÓXIMO",
            "filterDisease": "Filtrar por doença",
            "filterYear": "Filtrar por ano",
            "incorrectEmail": "Um e-mail válido é requerido!",
            "terms": "Termos e Condições",
            "details": "Detalhes",
            "subject": "Assunto",
            "loading": "Carregando...",
            "searchResults": "Resultados da busca",
            "noResults": "Nenhum resultado encontrado",
            "comingSoon": "Em breve...",
            "message": "Mensagem",
            "emailSent": "An email has been sent to your inbox.",
            "send": "Enviar",
            "sending": "Sending...",
            "tryAgain": "Please try again.",
            "emptyList": "This list is empty",
            "featureNotAvailableForChannel": "This feature is not available for current country",
        },
        "User": {
            "myProfile": "Meu perfil",
            "register": "Registrar",
        },
        "MonthNames": {
            1: "January",
            2: "February",
            3: "March",
            4: "April",
            5: "May",
            6: "June",
            7: "July",
            8: "August",
            9: "September",
            10: "October",
            11: "November",
            12: "December",
        },
        "FormFields": {
            "firstName": "Nome",
            "lastName": "Sobrenome",
            "password": "Senha",
            "passwordConfirm": "Confirmar senha",
            "title": "Título",
            "expertise": "Área de especialidade",
            "country": "País",
            "institution": "Instituição",
            "email": "E-mail",
            "termsGdpr": "EU ACEITO",
            "saveCompleted": "Salvamento concluído ",
            "saveFailed": "Falha ao salvar",
            "keepLogged": "Manter-me logado",
            "forgotPasswordTitle": "Esqueceu sua senha?",
            "passwordStrength": "Senha muito fraca. Sua senha deve conter necessariamente um número, uma letra maíuscula, uma letra minúscula, e no mínimo 8 caracteres",
            "yourPasswordSet": "Sua senha foi salva com sucesso! Você será redirecionado para a página de login automaticamente.",
            "agree": "Eu concordo em receber comunicações futuras sobre novos vídeos e atualizações educacionais.",
            "allRequired": "Todos os campos são obrigatórios",
            "someRequired": "* Required fields",
            "submit": "Submit",
            "requestSubmittedThankYou": "Thank you for submitting your request.",
            "requestFailed": "Request failed",
            "generalError": "O campo do {{field}} é inválido.",
        },
        "RegisterNoBig": {
            "title": "REGISTRO",
            "terms": "Por favor leia os Termos e Condições",
            "errorMessage": "Registro falhou. Por favor, tente novamente",
            "successMessage": "Registro efetuado com êxito",
            "thankYou": `
                <h4>Obrigado por registrar,</h4>
                <p>por favor verifique sua caixa de entrada para mais informações.</p>
                <p><strong>Esperamos vê-lo em breve em nossa plataforma</strong></p>
            `,
        },
        "RegisterNoBigStep2": {
            "errorsInForm": "Existem erros no formulário.",
            "emailExists": "Este e-mail já esta em uso!.",
        },
        "Login": {
            "login": "Login",
            "loginAcc": "ENTRAR EM SUA CONTA",
            "errorMessage": "E-mail ou senha incorretos",
            "successMessage": "Login efetuado com sucesso",
            "featureRequiresLogin": "In order to use this feature you should be logged in.",
        },
        "Forgot": {
            "title": "ESQUECEU SUA SENHA?",
            "fillEmail": "Por gentileza, preencha seu endereço de e-mail",
            "sentEmail": `
                <p class="vue-modal__password-reset-success"><strong>Nós lhe enviamos um e-mail,</strong></p>
                <p class="vue-modal__password-reset-success">por gentileza, verifique sua caixa de entrada para continuar.</p>
            `,
            "resetErrorMessage": "Este e-mail não existe em nossos registros",
            "resetSuccessMessage": "Recuperação de senha efetuada com sucesso",
            "setUpNew": "CADASTRAR NOVA SENHA",
            "setUp": "CADASTRE SUA SENHA",
            "goTo": "IR PARA A PLATAFORMA DE VÍDEO"
        },
        "UserAccount": {
            "errorMessage": "Seus dados não foram gravados. Por gentileza, tente novamente.",
            "successMessage": "Seus dados foram gravados com sucesso",
            "contactUsLink": "Entre em contato para alterar seu {{input}}",
            "update": "Atualizar",
            "logout": "Sair",
            "contactUs": "Entre em contato",
            "requestEmail": "RDNexus, Eu gostaria de mudar meu endereço de e-mail",
            "requestCountry": "RDNexus, Eu gostaria de mudar meu país",
            "placeholderEmailMessage": "Por favor, escreva seu novo endereço de e-mail e qualquer comentário adicional",
            "placeholderCountryMessage": "Por favor, escreva seu novo país e quaisquer comentários adicionais",
            "msgThanks": `
                <p><b>Obrigado pela sua mensagem,</b></p>
                <p>nós entraremos em contato o mais breve</p>
                <p>possível via e-maill</p>
            `,
        },
        "NotFound": {
            "notFound": "página não encontrada",
            "back": "Voltar",
        },
        "Favorites": {
            "favoriteVideos": "Videos favoritos",
            "iconText": "Use o ícone de coração para adicionar aos seus favoritos"
        },
        "Home": {
            "title": "Página principal",
            "favorites": "Favoritos",
        },
        "VideoChannel": {
            "title": "Canal de vídeo",
            "featuredVideos": "Vídeos em Destaque",
            "featuredVideosSlogan": "Vídeos em Destaque",
            "newUploads": "Novos envios",
            "newUploadsSlogan": "We make sure our content is<br><strong>up to date</strong>",
            "moreVideos": "Mais vídeos",
            "moreVideosSlogan": "There is so much more we want<br><strong>to show you</strong>",
            "allVideos": "All videos",
        },
        "Diagnostics": {
            "confirmRequestTest": "Please confirm you want to receive diagnostic test",
            "confirmContactSpecialist": "Are you sure you want to contact a Sanofi Genzyme specialist?",
        },
        "GetInTouch": {
            "discussCase": "Discuss Case With Expert",
            "findExpert": "Please enter the following information to find expert best suited to your needs.",
            "region": "Region",
            "country": "Country",
            "disease": "Disease",
            "consultationType": "Consultation type",
            "specialistWillContact": "A Sanofi Genzyme Specialist will contact you shortly.",
            "southAfricanSpecialistWillContact": "A Sanofi Medical Specialist will contact you shortly.",
        },
        "Notifications": {
            "title": "Notificações",
            "hasNew": "Nós enviamos <span class=\"count-num\">{{notificationsCount}}</span> novos vídeos para você.",
            "noNew": "Você está atualizado com nossos novos vídeos.",
            "clear": "clear"
        },
        "Languages": {
            "en_US": "Inglês",
            "fr_Fr": "Francês",
            "es-Es": "Espanhol",
            "pt_Br": "Português brasileiro"
        },
        "SingleVideo": {
            "watch": "ASSISTIR VÍDEO",
            "related": "Vídeos relacionados",
            "msgTitle": "Você tem uma pergunta em relação a este vídeo?",
            "msgPlaceholder": "Você pode escrever seus comentários ou perguntas aqui...",
            "msgThanks": `
                <p><b>Obrigado pela sua mensagem,</b></p>
                <p>nós entraremos em contato o mais breve</p>
                <p>possível via e-maill</p>
            `,
            "notFound": "Video not found",
        },
        "Footer": {
            "follow": "Siga-nos",
            "contact": "Contato",
            "sitemap": "Mapa do site",
            "legal": "Aviso legal",
            "privacy": "Privacidade & Proteção dos dados",
            "cookies": "Política de Cookies",
            "rights": "Todos os direitos reservados",
            "corporate": "Código de Ética corporativo",
            "websites": "Nossos websites",
            "publications": "Publicações",
            "zinc": "Promomats",
            "cookiesText": "Este website utiliza cookies para acompanhar a audiência e aprimorar seu conteúdo. Ao utilizar este website, você concorda com a utilização destes cookies.",
            "cookiesMore": "MAIS INFORMAÇÕES SOBRE COOKIES",
            "cookiesOK": "OK",
            "siteIntendedFor": "For Healthcare Professionals only",
        },
        "Rating": {
            "welcomeTitle": "Nós gostaríamos de ouvir o que você pensa sobre a nossa plataforma de vídeo",
            "welcomeText": "Você pode, por favor, responder algumas perguntas - levará apenas alguns minutos",
            "btnStartRate": "CERTO",
            "btnDenyRate": "Não, obrigado",
            "ratingInfoText": "Clique nas estrelas para ir para a próxima pergunta",
            "rateVeryUnlikely": "Muito Improvável",
            "rateUnlikely": "Improvável",
            "rateNeutrally": "Neutralmente",
            "rateLikely": "Provável",
            "rateVeryLikely": "Muito Provável",
            "thanksTitle": "<strong>Obrigado</strong> pelo seu feedback,",
            "thanksText": "Agradecemos por você dedicar um tempo para responder nossas perguntas."
        },
        "ChannelComingSoon": {
            "comingSoon": "EM BREVE",
            "willAddSoon": "Vamos adicionar vídeos para este canal em breve.",
            "letYouKnow": "Avisaremos você assim que estiverem disponíveis.",
        },
        "Diseases": {
            "gaucher": {
                "fullName": "Enfermedad de Gaucher",
                "freeDiagnosticTest": "Request your diagnostic test for Gaucher Disease.",
                "contactSpecialist": "Contact Sanofi specialist about Gaucher Disease.",
            },
            "pompe": {
                "fullName": "Enfermedad de Pompe",
                "freeDiagnosticTest": "Request your diagnostic test for Pompe Disease.",
                "contactSpecialist": "Contact Sanofi specialist about Pompe Disease.",
            },
            "fabry": {
                "fullName": "Enfermedad de Fabry",
                "freeDiagnosticTest": "Request your diagnostic test for Fabry Disease.",
                "contactSpecialist": "Contact Sanofi specialist about Fabry Disease.",
            },
            "mps1": {
                "fullName": "Mucopolysaccharidoses",
                "freeDiagnosticTest": "Request your diagnostic test for MPS.",
                "contactSpecialist": "Contact Sanofi specialist about MPS.",
            },
            "moreInformationInludingBrochure": "Request more information. Including brochure.",
        },
        "Events": {
            "allEvents": "All events",
            "upcomingEvents": "Upcoming events",
            "registerForTheEvent": "Register for the event",
        },
    };
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-box" }, [
    !_vm.successfullySubmitted
      ? _c(
          "form",
          {
            ref: "form",
            staticClass: "vue-modal",
            attrs: { method: "POST" },
            on: {
              submit: [
                function($event) {
                  $event.preventDefault()
                  return _vm.submit($event)
                },
                _vm.submit
              ]
            }
          },
          [
            _c("div", { staticClass: "vue-modal__title" }, [
              _c("div", { staticClass: "vue-modal__title" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("Forgot.setUp")) +
                      "\n                "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group has-float-label" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "password",
                  type: "password",
                  placeholder: " ",
                  required: ""
                },
                domProps: { value: _vm.password },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "password" } }, [
                _vm._v(_vm._s(_vm.$t("FormFields.password")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group has-float-label" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.passwordConfirm,
                    expression: "passwordConfirm"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "passwordConfirm",
                  type: "password",
                  placeholder: " ",
                  required: ""
                },
                domProps: { value: _vm.passwordConfirm },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.passwordConfirm = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "passwordConfirm" } }, [
                _vm._v(_vm._s(_vm.$t("FormFields.passwordConfirm")))
              ])
            ]),
            _vm._v(" "),
            _vm.password && !_vm.strongEnough
              ? _c(
                  "div",
                  { staticStyle: { color: "red", "font-size": "12px" } },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("FormFields.passwordStrength")) +
                        "\n        "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "vue-modal__submit" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  class: { active: _vm.canSubmitForm },
                  attrs: {
                    type: "submit",
                    disabled: !_vm.canSubmitForm || _vm.submitting
                  }
                },
                [
                  _vm.submitting
                    ? _c("i", { staticClass: "fas fa-circle-notch fa-spin" })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.$t("Forgot.goTo")) + "\n            ")
                ]
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.successfullySubmitted
      ? _c("div", [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("FormFields.yourPasswordSet")) +
              "\n    "
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      staticClass: "user-account-modal",
      attrs: {
        width: "800",
        height: "auto",
        scrollable: "",
        adaptive: "",
        name: _vm.modalName
      }
    },
    [
      _c("modal-action-buttons", { attrs: { "close-modal": _vm.modalName } }),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade-in" } },
        [
          _vm.isLoggedOut
            ? _c(
                "message-box",
                {
                  staticClass: "py-5",
                  attrs: {
                    icon: "check-circle",
                    "icon-class": "text-success",
                    title: _vm.$t("User.loggedOut")
                  }
                },
                [
                  _c("button", {
                    staticClass: "btn btn-primary mt-3",
                    domProps: { innerHTML: _vm._s(_vm.$t("Common.ok")) },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.refreshPage($event)
                      }
                    }
                  })
                ]
              )
            : _vm.isLoggingOut
              ? _c(
                  "div",
                  { staticClass: "text-center py-5" },
                  [
                    _c("loader"),
                    _vm._v(" "),
                    _c("p", { staticClass: "mt-3" }, [
                      _vm._v(_vm._s(_vm.$t("User.loggingOut")))
                    ])
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "b-modal-content" },
                  [
                    _c("inline-radio-select", {
                      staticClass: "mb-4",
                      attrs: {
                        name: "tabs",
                        default: _vm.activeForm,
                        options: _vm.tabsOptions
                      },
                      on: { input: _vm.tabChange }
                    }),
                    _vm._v(" "),
                    _c(
                      "transition",
                      { attrs: { name: "fade-in" } },
                      [
                        _vm.activeForm == "account"
                          ? _c("user-account-form", {
                              on: { logout: _vm.logout }
                            })
                          : _vm.activeForm == "contact"
                            ? _c("user-contact-us-form")
                            : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
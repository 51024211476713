<template>

    <transition name="scale-in">
        <message-box
            v-if="loggedInSuccessful"
            icon="check-circle"
            icon-class="text-success"
            :title="$t('Login.successMessage')"
        >
            <button v-html="$t('Common.ok')" class="btn btn-primary mt-3" @click.prevent="refreshPage" />
        </message-box>

        <form-generator
            v-else
            :url="endpointUrl"
            :fields="formFields"
            :config="formConfig"
            @forgot-password="linkActionForgot"
            @register="linkActionRegister"
            @success="onSuccess"
        />
    </transition>

</template>


<script>

import Service from '../../service';
import JanrainIntegration from '../../integrations/janrain';

import FormGenerator from './../Forms/FormGenerator.vue';

export default {
    name: 'LoginFormStep1',

    components: {
        FormGenerator,
    },

    data() {
        return {
            loggedInSuccessful: false,
            endpointUrl: Service.prepareUrl('login'),
            formConfig: {
                title: this.$i18n.i18next.t('Login.loginAcc'),
                submitTitle: this.$i18n.i18next.t('Login.login'),
                errorMessage: this.$i18n.i18next.t('Login.errorMessage'),
                links: [
                    {
                        title: this.$i18n.i18next.t('User.register'),
                        action: 'register',
                    },
                    {
                        title: this.$i18n.i18next.t('FormFields.forgotPasswordTitle'),
                        action: 'forgot-password',
                    },
                ],
            },
            formFields: {
                email: {
                    type: 'email',
                    label: this.$i18n.i18next.t('FormFields.email'),
                    required: true,
                },
                password: {
                    type: 'password',
                    label: this.$i18n.i18next.t('FormFields.password'),
                    required: true,
                },
                keepLogged: {
                    type: 'checkbox',
                    label: this.$i18n.i18next.t('FormFields.keepLogged'),
                    checked: true,
                },
            },
        }
    },

    methods: {
        linkActionForgot() {
            this.$emit('forgot-password');
        },

        linkActionRegister() {
            this.$emit('register');
        },

        /**
         * Wait some time with login successfull message and reload
         * to show previously hidden content.
         */
        async onSuccess({ response }) {
            const data = response.data.data || {};

            if (data.sso_code) {
                await JanrainIntegration.login(data.sso_code);
            }

            this.loggedInSuccessful = true;
            setTimeout(this.refreshPage, 2000);
        },

        refreshPage() {
            window.location.reload();
        },
    },
}

</script>

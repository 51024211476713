var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "c-hero-banner" }, [
    _c("div", { staticClass: "c-hero-banner__content container" }, [
      _c(
        "div",
        { staticClass: "row justify-content-between align-items-center" },
        [
          _c("div", { staticClass: "col-12 col-md-7" }, [
            _c("img", {
              staticClass: "c-hero-banner__content__logo",
              attrs: {
                src:
                  "/site/templates/img/general/rare-disease-nexus-logo-white.svg"
              }
            }),
            _vm._v(" "),
            _c("h1", [_vm._v(_vm._s(_vm.headline))])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "c-hero-banner__buttons [ col-12 col-md-5 col-xl-4 ]"
            },
            _vm._l(_vm.buttons, function(button, index) {
              return _c(
                "a",
                {
                  key: index,
                  staticClass: "btn btn-lg btn-raised",
                  class: ["btn-" + button.color].concat(
                    button.image ? ["btn-with-image"] : []
                  ),
                  attrs: { href: button.url }
                },
                [
                  button.image
                    ? _c("span", { staticClass: "btn-image" }, [
                        _c("img", {
                          attrs: { src: button.image, alt: button.name }
                        })
                      ])
                    : _vm._e(),
                  _vm._v(
                    "\n                    " +
                      _vm._s(button.name) +
                      "\n                "
                  )
                ]
              )
            }),
            0
          )
        ]
      )
    ]),
    _vm._v(" "),
    _vm.scrollDownTarget
      ? _c(
          "a",
          {
            staticClass: "c-hero-banner__cta b-action-btn",
            attrs: { href: _vm.scrollDownTarget }
          },
          [_c("icon", { attrs: { glyph: "chevron-down", "is-solid": true } })],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", { staticClass: "mb-1 mt-2 text-center" }, [
        _vm._v("\n        " + _vm._s(_vm.$t("SelectCountry.title")) + "\n    ")
      ]),
      _vm._v(" "),
      _vm.isLoading
        ? _c("loader")
        : _c(
            "div",
            [
              _c("form-generator", {
                attrs: { fields: _vm.formFields, config: _vm.formConfig },
                on: { success: _vm.save }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <modal
        width="500"
        height="auto"
        class="legacy-country-modal"
        :name="modalName"
        :scrollable="true"
        :clickToClose="false"
    >
        <div class="b-modal-content">
            <h2 class="mb-1 mt-2">{{ $t('LegacyCountry.title') }}</h2>
            <p v-if="mapToNewCountryCode()">
                {{ $t('LegacyCountry.description')}}
            </p>
            <p v-else>
                {{ $t('LegacyCountry.description_alt')}}
            </p>
            <a
                href="#"
                class="btn btn-primary"
                @click="buttonClickHandler"
            >
                {{ $t('LegacyCountry.button') }}
            </a>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'RedirectLegacyCountryModal',

    data() {
        return {
            modalName: 'redirect-legacy-country-modal',
            legacyCountries: {
                'United Arab Emirates': 'ae',
                'Saudi Arabia': 'sa',
                'Kuwait': 'kw',
                'Oman': 'om',
                'Bahrain': 'bh',
                'Qatar': 'qa',
                'Marocco': false,
                'Taiwan': false,
                'Brazil': false,
                'Argentina': false,
                'India': false,
                'Egypt': 'eg',
            },
        }
    },

    methods: {
        currentCountry() {
            return localStorage && localStorage.getItem('country') || null;
        },

        isLegacyCountry() {
            return this.legacyCountries.hasOwnProperty(this.currentCountry());
        },

        mapToNewCountryCode() {
            return this.legacyCountries[this.currentCountry()];
        },

        buttonClickHandler() {
            // Delete current country if there is no alternative. Go
            // back home afterwards.
            if (!this.mapToNewCountryCode()) {
                this.clearCountry();
                this.doNavigateHome();
                return;
            }

            return this.doNavigate();
        },

        clearCountry() {
            localStorage.removeItem('country');
        },

        doNavigateHome() {
            window.location.href = '/';
        },

        doNavigate() {
            window.location.href = 'https://www.campus.sanofi/' + this.mapToNewCountryCode() + '/science/rare-diseases';
        },
    },

    created() {
        if (this.isLegacyCountry()) {
            setTimeout(() => {
                this.$modal.show(this.modalName);

                setTimeout(() => {
                    // Delete current country if there is no alternative. Go
                    // back home afterwards.
                    if (!this.mapToNewCountryCode()) {
                        this.clearCountry();
                        this.doNavigateHome();
                        return;
                    }

                    this.doNavigate();
                }, 5000);
            }, 500);
        }
    },
};
</script>

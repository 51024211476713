var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("h4", [_vm._v(_vm._s(_vm.$t("Rating.welcomeTitle")))]),
    _vm._v(" "),
    _c("p", [
      _vm._v("\n        " + _vm._s(_vm.$t("Rating.welcomeText")) + "\n    ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c("div", [
        _c(
          "button",
          { staticClass: "btn btn-primary", on: { click: _vm.startRate } },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("Rating.btnStartRate")) +
                "\n            "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-3" }, [
        _c("button", { staticClass: "btn", on: { click: _vm.denyRating } }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$t("Rating.btnDenyRate")) +
              "\n            "
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "accent-icon" }, [
      _c("img", {
        staticClass: "icon",
        attrs: { src: "/site/templates/img/general/rating-survey-start.svg" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
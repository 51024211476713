<template>

    <div class="c-video-player">
        <div
            v-if="!videoId || !accountId"
            class="c-video-player__error"
        >
            <strong>{{ $t('SingleVideo.cannotBeDisplayed') }}</strong><br>
            {{ $t('SingleVideo.settingMissing') }}
        </div>

        <div
            v-else
            ref="video"
        ></div>
    </div>

</template>


<script>

import brightcovePlayerLoader from '@brightcove/player-loader';

export default {
    name: 'BrightcovePlayer',

    props: {
        url: String,
        accountId: {
            type: String,
            required: true,
        },
        videoId: {
            type: String,
            required: true,
        },
        playerId: {
            type: String,
            default: 'default'
        },
        embedId: {
            type: String,
            default: 'default'
        },
        controls: {
            type: Boolean,
            default: true,
        },
        autoplay: Boolean,
    },

    data() {
        return {
            player: null,
            isPlayerReady: false,
            isPlaying: false,
            playbackSeconds: 0,
            playbackProgress: 0, // Percent
            playbackDuration: 0,
        }
    },

    watch: {
        isPlaying(state) {
            this.$emit('state-changed', state);
        },

        playbackDuration(duration) {
            this.$emit('duration-changed', duration);
        },
    },

    methods: {
        initBcPlayer() {
            brightcovePlayerLoader({
                refNode: this.$refs.video,
                refNodeInsert: 'replace',
                accountId: this.accountId,
                playerId: this.playerId,
                embedId: this.embedId,
                videoId: this.videoId
            })
                .then(({ ref }) => {
                    this.player = ref;

                    ref.controls(this.controls);
                    ref.autoplay(this.autoplay);

                    ref.on('loadedmetadata', () => {
                        this.isPlayerReady = true;
                        this.playbackDuration = Math.round(ref.duration() * 1000) / 1000;
                    });

                    ref.on('timeupdate', () => {
                        this.playbackSeconds = ref.currentTime();
                        this.playbackProgress = Math.round(this.playbackSeconds / this.playbackDuration * 10000) / 100;
                    });

                    ref.on('play', () => {
                        this.isPlaying = true;
                    });

                    ref.on('pause', () => {
                        this.isPlaying = false;
                    });
                })
                .catch(console.warn);
        },

        play() {
            this.player.play();
            this.isPlaying = true;
        },

        replay() {
            this.player.currentTime(0);
            this.play();
        },

        pause() {
            this.player.pause();
            this.isPlaying = false;
        },

        toggle() {
            (this.isPlaying) ? this.pause() : this.play();
        },
    },

    mounted() {
        this.initBcPlayer();
    },

    destroyed() {
        if (this.player) {
            this.player.dispose();
        }
    },
}

</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-modal-action-buttons" }, [
    _vm.backAction
      ? _c("button", {
          staticClass: "c-modal-action-buttons__back",
          on: { click: _vm.back }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.closeModal
      ? _c("button", {
          staticClass: "c-modal-action-buttons__close",
          on: { click: _vm.close }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-5" },
    [
      _c(
        "tile-list-page",
        {
          attrs: {
            "page-title": _vm.pageTitle,
            headline: _vm.headline,
            list: _vm.diseasesTranslated
          },
          on: { "tile-click-action": _vm.openRequestModal }
        },
        [
          !_vm.isDataFetched
            ? _c("loader", { staticClass: "mt-5" })
            : !_vm.diseases
              ? _c("message-box", {
                  attrs: {
                    title: _vm.$t("Diseases.listEmpty"),
                    icon: "times-circle"
                  }
                })
              : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "modal",
        { attrs: { name: _vm.requestModalName, width: 400, height: "auto" } },
        [
          _c("modal-action-buttons", {
            attrs: { "close-modal": _vm.requestModalName }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "b-modal-content text-center" },
            [
              _c(
                "transition",
                { attrs: { name: "fade-in" } },
                [
                  _vm.isRequestInProgress
                    ? _c(
                        "div",
                        [
                          _c("p", [_vm._v(_vm._s(_vm.$t("Common.sending")))]),
                          _vm._v(" "),
                          _c("loader")
                        ],
                        1
                      )
                    : !_vm.isRequestMade
                      ? _c(
                          "message-box",
                          { attrs: { message: _vm.confirmationMessage } },
                          [
                            _c("button", {
                              staticClass: "btn",
                              domProps: {
                                innerHTML: _vm._s(_vm.$t("Common.no"))
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.closeRequestModal($event)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("button", {
                              staticClass: "btn btn-success",
                              domProps: {
                                innerHTML: _vm._s(_vm.$t("Common.yes"))
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.submitRequest($event)
                                }
                              }
                            })
                          ]
                        )
                      : _vm.isRequestSuccessfull
                        ? [
                            _vm.isSouthAfrica
                              ? _c("message-box", {
                                  attrs: {
                                    title: _vm.$t(
                                      "FormFields.requestSubmittedThankYou"
                                    ),
                                    message:
                                      _vm.$t("Common.emailSent") +
                                      "<br><br>" +
                                      _vm.$t(
                                        "GetInTouch.southAfricanSpecialistWillContact"
                                      ),
                                    icon: "check-circle",
                                    "icon-class": "text-success"
                                  }
                                })
                              : _c("message-box", {
                                  attrs: {
                                    title: _vm.$t(
                                      "FormFields.requestSubmittedThankYou"
                                    ),
                                    message:
                                      _vm.$t("Common.emailSent") +
                                      "<br><br>" +
                                      _vm.$t(
                                        "GetInTouch.specialistWillContact"
                                      ),
                                    icon: "check-circle",
                                    "icon-class": "text-success"
                                  }
                                })
                          ]
                        : !_vm.isRequestSuccessfull
                          ? _c("message-box", {
                              attrs: {
                                title: _vm.$t("FormFields.requestFailed"),
                                message: _vm.$t("Common.tryAgain")
                              }
                            })
                          : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
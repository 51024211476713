<template>

    <div class="c-modal-action-buttons">
        <button
            v-if="backAction"
            class="c-modal-action-buttons__back"
            @click="back"
        ></button>

        <button
            v-if="closeModal"
            class="c-modal-action-buttons__close"
            @click="close"
        ></button>
    </div>

</template>


<script>

export default {
    name: 'ModalActionButtons',

    props: {
        backAction: {
            type: [String, Boolean],
            default: false
        },
        closeModal: {
            type: String,
            default: false
        },
    },

    methods: {
        back() {
            this.$emit(this.backAction);
        },

        close() {
            this.$modal.hide(this.closeModal);
        },
    },
}

</script>

<template>
    <modal
        width="500"
        height="auto"
        class="c-exit-modal"
        :name="modalName"
        :scrollable="true"
    >
        <modal-action-buttons
            :close-modal="modalName"
        />

        <div class="b-modal-content">
            <select-country :languages="languages" @no-redirect="close" />
        </div>
    </modal>
</template>

<script>
import ModalActionButtons from '../Common/ModalActionButtons.vue';
import SelectCountry from './SelectCountry.vue';

export default {
    name: 'SelectCountryModal',

    components: {
        SelectCountry,
        ModalActionButtons,
    },

    props: {
        cmsLanguages: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            modalName: 'select-country-modal',
            languages: JSON.parse(this.cmsLanguages),
        }
    },

    methods: {
        hasSetCountryAlready() {
            return Boolean(
                localStorage.getItem('country') &&
                localStorage.getItem('channelId')
            );
        },

        close() {
            setTimeout(() => {
                this.$modal.hide(this.modalName);
            }, 1000);
        }
    },

    created() {
        if (!this.hasSetCountryAlready()) {
            setTimeout(() => {
                this.$modal.show(this.modalName);
            }, 2500);
        }
    },
};
</script>

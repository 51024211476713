var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.emailSent
    ? _c("div", [
        _c("div", { staticClass: "vue-modal" }, [
          _c("img", {
            attrs: { src: "/site/templates/img/general/email-sent.svg" }
          }),
          _vm._v(" "),
          _c(
            "div",
            { domProps: { innerHTML: _vm._s(_vm.$t("Forgot.sentEmail")) } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("Forgot.sentEmail")) +
                  "\n        "
              )
            ]
          )
        ])
      ])
    : _c(
        "div",
        [
          _c("form-generator", {
            attrs: {
              fields: _vm.formFields,
              config: _vm.formConfig,
              url: _vm.endpointUrl
            },
            on: { "go-back": _vm.goBack, success: _vm.onSuccess }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
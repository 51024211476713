var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("loader", { staticClass: "my-4" })
        : _c("form-generator", {
            attrs: {
              url: _vm.endpointUrl,
              fields: _vm.formFields,
              config: _vm.formConfig
            },
            on: { success: _vm.onSuccess },
            scopedSlots: _vm._u([
              {
                key: "footer",
                fn: function() {
                  return [
                    _vm.isUK
                      ? _c(
                          "div",
                          { staticClass: "privacy-police mt-3 mb-5 text-left" },
                          [
                            _c("h4", [_vm._v("Privacy Notice")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n                    We will use the personal data you provide above to register you and provide you with access to the RD Nexus UK Site.\n                    Sanofi will process your personal data on the basis that it is necessary for our legitimate interests (for provision of administration and IT services and network security,\n                    to keep our website updated and relevant, to study how customers use our products/services and to develop our business).\n                    Aventis Pharma Limited is the controller and responsible for your personal data. Please see our Privacy Policy for more detailed information about our data processing activities.\n                    "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "https://www.sanofi.co.uk/privacy-policy",
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("Privacy Policy")]
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n                    If you tick the box above to receive emails from Sanofi, we will also use your personal data to send you information by email about goods and services\n                    which we feel may be of interest to you on the basis that it is necessary for our legitimate interests (to develop our products/services and grow our business).\n                    For more details specifically about how we use your data to send you these emails, please see our separate Staying in Touch Privacy Notice.\n                    "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "https://www.sanofi.co.uk/en/about-us/sanofi-in-the-uk/staying-in-touch",
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("Staying in Touch Privacy Notice")]
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
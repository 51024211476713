<template>

    <modal
        class="ratings-wrapper"
        width="800"
        height="auto"
        scrollable
        adaptive
        :name="modalName"
        :pivot-y="0.1"
    >
        <modal-action-buttons :close-modal="modalName" />

        <div class="b-modal-content">
            <component
                :is="activeScreen"
                @startRate="startRate"
                @closeModal="closeModal"
                @endRate="endRate"
            ></component>
        </div>
    </modal>

</template>


<script>

import apiUrl from '../../utility/apiUrl';
import axios from 'axios'

import WelcomeScreen from './WelcomeScreen.vue';
import RatingsScreen from './RatingsScreen.vue';
import ThanksScreen from './ThanksScreen.vue';
import ModalActionButtons from '../Common/ModalActionButtons.vue';

export default {
    name: 'RatingModal',

    components: {
        WelcomeScreen,
        RatingsScreen,
        ThanksScreen,
        ModalActionButtons,
    },

    data() {
        return {
            modalName: 'rating-modal',
            activeScreen: 'welcome-screen',
            timeSince: {
                interval: null,
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
                intervals: {
                    second: 1000,
                    minute: 1000 * 60,
                    hour: 1000 * 60 * 60,
                    day: 1000 * 60 * 60 * 24
                }
            },
            userHasVoted: window.siteData.user.userRatingData.show_rating,
            date: new Date(window.siteData.user.userRatingData.login_time)
        }
    },

    created() {
        const ratingHasQuestions = sessionStorage.getItem('ratingHasQuestions');

        if (ratingHasQuestions === 'true') {
            this.checkToStartCounter();
        } else {
            this.checkIfHasQuestionFirst();
        }
    },

    methods: {
        closeModal() {
            this.$modal.hide(this.modalName);
        },

        showModal() {
            this.$modal.show(this.modalName);
        },

        startRate() {
            this.activeScreen = 'ratings-screen';
        },

        endRate() {
            this.activeScreen = 'thanks-screen';
        },

        updateDiffs() {
            //lets figure out our diffs
            let diff = Math.abs(Date.now() - this.date.getTime());
            this.timeSince.days = Math.floor(diff / this.timeSince.intervals.day);
            diff -= this.timeSince.days * this.timeSince.intervals.day;
            this.timeSince.hours = Math.floor(diff / this.timeSince.intervals.hour);
            diff -= this.timeSince.hours * this.timeSince.intervals.hour;
            this.timeSince.minutes = Math.floor(diff / this.timeSince.intervals.minute);
            diff -= this.timeSince.minutes * this.timeSince.intervals.minute;
            this.timeSince.seconds = Math.floor(diff / this.timeSince.intervals.second);

            // After 5 minutes
            if ((this.timeSince.hours == 0) && this.timeSince.minutes > 0) {
                this.showModal();
                this.clearIntervalupdateDiffs();
            }
        },

        clearIntervalupdateDiffs() {
            clearInterval(this.timeSince.interval);
        },

        checkToStartCounter() {
            if (this.userHasVoted && sessionStorage.getItem('disableRatingModal') === null) {
                this.timeSince.interval = setInterval(() => {
                    this.updateDiffs();
                }, 1000);
            }
        },

        checkIfHasQuestionFirst() {
            axios.get(apiUrl('get-channel-rating-questions'))
                .then(response => {
                    if (response.data.data && response.data.data.length > 0) {
                        sessionStorage.setItem('ratingHasQuestions', 'true');
                        this.checkToStartCounter();
                    } else {
                        sessionStorage.setItem('ratingHasQuestions', 'false');
                    }
                });
        }
    },
}

</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      !_vm.currentUser
        ? _c("not-logged-user-message", { staticClass: "mb-5" })
        : _c("div", [
            _c("div", { staticClass: "u-medium-content text-center mt-5" }, [
              _c("h2", [_vm._v(_vm._s(_vm.$t("Elearning.introText")))])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "container-xl mt-5 mb-5" },
              [
                _c("hr", { staticClass: "mb-5" }),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "fade-in" } },
                  [
                    _vm.isLoading
                      ? _c("loader")
                      : _vm.elearnings
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "row" },
                              _vm._l(_vm.elearnings, function(
                                entry,
                                entryIndex
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: entry.uuid || entryIndex,
                                    staticClass: "col-xs-12 col-md-6 mb-4"
                                  },
                                  [
                                    _c("elearning-card", {
                                      attrs: { entry: entry, "is-covered": "" }
                                    })
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          ])
                        : _c("message-box", {
                            attrs: {
                              title: _vm.$t("Common.emptyList"),
                              icon: "times-circle"
                            }
                          })
                  ],
                  1
                )
              ],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
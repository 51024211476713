export default () => {
    return {
        "Common": {
            "yes": "Si",
            "no": "No",
            "ok": "OK",
            "confirm": "Confirmar",
            "cancel": "Cancelar",
            "success": "Éxito",
            "error": "Error",
            "search": "Buscar",
            "next": "Siguiente",
            "filterDisease": "Filtrar por enfermedad",
            "filterYear": "Filtrar por año",
            "incorrectEmail": "¡Se requiere un e-mail válido!",
            "terms": "Términos y Condiciones",
            "details": "Detalles",
            "subject": "Tema",
            "loading": "Cargando...",
            "searchResults": "Resultados",
            "noResults": "No hay resultados",
            "comingSoon": "Próximamente...",
            "message": "Mensaje",
            "emailSent": "Se ha enviado un correo electrónico a su bandeja de entrada.",
            "send": "Mandar",
            "sending": "Enviando...",
            "tryAgain": "Inténtalo de nuevo.",
            "emptyList": "Esta lista esta vacia",
            "featureNotAvailableForChannel": "This feature is not available for current country",
        },
        "User": {
            "myProfile": "Mi perfil",
            "register": "Registrar",
        },
        "MonthNames": {
            1: "Enero",
            2: "Febrero",
            3: "Marzo",
            4: "Abril",
            5: "Mayo",
            6: "Junio",
            7: "Julio",
            8: "Agosto",
            9: "Septiembre",
            10: "Octubre",
            11: "Noviembre",
            12: "Diciembre",
        },
        "FormFields": {
            "firstName": "Nombre",
            "lastName": "Apellido",
            "password": "Contraseña",
            "passwordConfirm": "Contraseña confirmada",
            "title": "Título",
            "expertise": "Área de experiencia",
            "country": "País",
            "institution": "Institución",
            "email": "E-mail",
            "termsGdpr": "Acepto",
            "saveCompleted": "La acción se completó con éxito",
            "saveFailed": "La acción no fue posible",
            "keepLogged": "Mantenme conectado",
            "forgotPasswordTitle": "¿Olvidaste tu contraseña?",
            "passwordStrength": "Contraseña demasiado debil. Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 8 o más caracteres",
            "yourPasswordSet": "Su contraseña se ha establecido correctamente! Serás redirigido a la pantalla de inicio de sesión automáticamente.",
            "agree": "Acepto recibir futuras comunicaciones sobre nuevos videos y nuevas actualizaciones educativas",
            "allRequired": "Registro, todos los campos se consideran obligatorios",
            "someRequired": "* Required fields",
            "submit": "Enviar",
            "requestSubmittedThankYou": "Gracias por presentar tu pedido.",
            "requestFailed": "Solicitud fallida",
            "generalError": "El campo de {{field}} no es válido.",
        },
        "RegisterNoBig": {
            "title": "Registro",
            "terms": "Por favor, lea los términos y condiciones",
            "errorMessage": "Tu registro no fue exitoso. Por favor inténtalo de nuevo",
            "successMessage": "Tu registro fue exitoso",
            "thankYou": `
                <h4>Gracias por registrar,</h4>
                <p>Por favor revise tu email para más información.</p>
                <p><strong>Esperamos verte pronto en la plataforma.</strong></p>
            `
        },
        "RegisterNoBigStep2": {
            "errorsInForm": "Hay errores en el formulario.",
            "emailExists": "Este correo electrónico ya está registrado.",
        },
        "Login": {
            "login": "Iniciar sesión",
            "loginAcc": "Ingrese a su cuenta",
            "errorMessage": "Correo o contraseña equivocada",
            "successMessage": "¡Inicio de sesión correcto!",
            "returnToLoginForm": "Regresar al formulario de inicio de sesión",
            "featureRequiresLogin": "Para utilizar esta función, debe iniciar sesión.",
        },
        "Forgot": {
            "title": "OLVIDASTE TU CONTRASEÑA?",
            "fillEmail": "Por favor introduzca su dirección de correo electrónico",
            "sentEmail": `
                <p class="vue-modal__password-reset-success"><strong>Le enviamos un correo electrónico,</strong></p>
                <p class="vue-modal__password-reset-success">revise su bandeja de entrada para continuar.</p>
            `,
            "resetErrorMessage": "Este correo electrónico no existe en nuestros registros.",
            "resetSuccessMessage": "Tu contraseña fue restablecida con exito",
            "setUpNew": "Configurar nueva contraseña",
            "setUp": "CONFIGURAR SU CONTRASEÑA",
            "goTo": "PAGINA DE INICIO"
        },
        "UserAccount": {
            "errorMessage": "Tus datos no fueron guardados. Inténtalo de nuevo.",
            "successMessage": "Tus datos se han guardado con éxito",
            "contactUsLink": "Contáctenos para cambiar tu {{input}}",
            "update": "ACTUALIZAR",
            "logout": "Cerrar sesión",
            "contactUs": "Contáctenos",
            "requestEmail": "Me gustaría cambiar mi dirección de correo electrónico",
            "requestCountry": "Me gustaria cambiar mi pais",
            "placeholderEmailMessage": "Por favor escriba su nueva dirección de correo electrónico y cualquier comentario adicional",
            "placeholderCountryMessage": "Por favor escriba su nuevo país y cualquier comentario adicional",
            "msgThanks": `
                <p><b>Gracias por tu mensaje.</b></p>
                <p>Nos pondremos en contacto con usted</p>
                <p>lo antes posible por correo electrónico</p>
            `
        },
        "NotFound": {
            "notFound": "Esta página no fue encontrada",
            "back": "LLÉVAME DE VUELTA",
        },
        "Favorites": {
            "favoriteVideos": "Videos favoritos",
            "iconText": "Usa el icono del corazón para agregar tus videos favoritos"
        },
        "Home": {
            "title": "Página de inicio",
            "favorites": "Favoritos",
        },
        "VideoChannel": {
            "title": "Videos",
            "featuredVideos": "Vídeos destacados",
            "featuredVideosSlogan": "Nuestras mejores opciones para ti",
            "newUploads": "Nuevos vídeos",
            "newUploadsSlogan": "Nos aseguramos de que nuestro contenido<br><strong>esté actualizado</strong>",
            "moreVideos": "Más videos",
            "moreVideosSlogan": "Hay mucho más que<br><strong>queremos mostrarte</strong>",
            "allVideos": "Todos los videos",
        },
        "Diagnostics": {
            "confirmRequestTest": "¿Esta Seguro que desea solicitar un test diagnóstico?",
            "confirmContactSpecialist": "¿Esta seguro que desea contactar con un especialista de Sanofi Genzyme?",
        },
        "GetInTouch": {
            "discussCase": "Discuta un caso con un Experto",
            "findExpert": "Ingrese la siguiente información para encontrar el experto que mejor se adapte a sus necesidades.",
            "region": "Region",
            "country": "Country",
            "disease": "Disease",
            "consultationType": "Consultation type",
            "specialistWillContact": "Un especialista de Sanofi Genzyme se comunicará con usted a la brevedad.",
        },
        "Notifications": {
            "title": "Notificationes",
            "hasNew": "Hay <span class=\"count-num\">{{notificationsCount}}</span> nuevos vídeos para ti.",
            "noNew": "Ya tienes todos nuestros últimos videos.",
            "clear": "clear"
        },
        "Languages": {
            "en_US": "Inglés",
            "fr_Fr": "Francés",
            "es-Es": "Español",
            "pt_Br": "Portugués"
        },
        "SingleVideo": {
            "watch": "VER EL VIDEO",
            "related": "Videos relacionados",
            "msgTitle": "Tienes alguna pregunta sobre este video?",
            "msgPlaceholder": "Puedes escribir tus comentarios o preguntas aquí...",
            "msgThanks": `
                <p><b>Gracias por tu mensaje.</b></p>
                <p>Nos pondremos en contacto con usted</p>
                <p>lo antes posible por correo electrónico</p>
            `,
            "notFound": "Video no encontrado",
        },
        "Footer": {
            "follow": "Seguinos",
            "contact": "Contacto",
            "sitemap": "Mapa del sitio",
            "legal": "Aviso Legal",
            "privacy": "Privacidad y protección de datos",
            "cookies": "Politica de Cookies",
            "rights": "Todos los derechos reservados",
            "corporate": "Código de ética empresarial",
            "websites": "Nuestros sitios web",
            "publications": "Publicaciones",
            "zinc": "Promomats",
            "cookiesText": "Esta sitio web utiliza cookies para rastrear a su audiencia y mejorar su contenido. Al continuar navegando en este sitio web, usted acepta el uso de dichas cookies.",
            "cookiesMore": "MÁS INFORMACIÓN SOBRE LOS COOKIES",
            "cookiesOK": "OK",
            "siteIntendedFor": "For Healthcare Professionals only",
        },
        "Rating": {
            "welcomeTitle": "Nos encantaría escuchar tu opinión sobre nuestra plataforma de video.",
            "welcomeText": "Puede por favor responder algunas preguntas - sólo tomará un par de minutos.",
            "btnStartRate": "POR SUPUESTO",
            "btnDenyRate": "No, gracias",
            "ratingInfoText": "Haz clic en las estrellas para pasar a la siguiente pregunta.",
            "rateVeryUnlikely": "Muy Improbable",
            "rateUnlikely": "Improbable",
            "rateNeutrally": "Neutralmente",
            "rateLikely": "Probable",
            "rateVeryLikely": "Muy Probable",
            "thanksTitle": "<strong>Gracias</strong> por tus comentarios,",
            "thanksText": "Agradecemos que se haya tomado el tiempo de responder nuestras preguntas."
        },
        "ChannelComingSoon": {
            "comingSoon": "PRÓXIMAMENTE",
            "willAddSoon": "Pronto agregaremos videos para este canal.",
            "letYouKnow": "Te avisaremos tan pronto como estén disponibles.",
        },
        "Diseases": {
            "gaucher": {
                "fullName": "Enfermedad de Gaucher",
                "freeDiagnosticTest": "Solicitar test de diagnóstico para la Enfermedad de Gaucher.",
                "contactSpecialist": "Conozca a un especialista de Sanofi Genzyme acerca de Gaucher.",
            },
            "pompe": {
                "fullName": "Enfermedad de Pompe",
                "freeDiagnosticTest": "Solicitar test de diagnóstico para la Enfermedad de Pompe.",
                "contactSpecialist": "Conozca a un especialista de Sanofi Genzyme acerca de Pompe.",
            },
            "fabry": {
                "fullName": "Enfermedad de Fabry",
                "freeDiagnosticTest": "Solicitar test de diagnóstico para la Enfermedad de Fabry.",
                "contactSpecialist": "Conozca a un especialista de Sanofi Genzyme acerca de Fabry.",
            },
            "mps1": {
                "fullName": "Mucopolysaccharidoses",
                "freeDiagnosticTest": "Solicitar test de diagnóstico para la Enfermedad de MPS.",
                "contactSpecialist": "Conozca a un especialista de Sanofi Genzyme acerca de MPS.",
            },
            "moreInformationInludingBrochure": "Solicitar más información. Incluye folleto.",
        },
        "Events": {
            "allEvents": "Todos los eventos",
            "upcomingEvents": "Próximos eventos",
            "registerForTheEvent": "Register for the event",
        },
    };
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "c-video-details" },
    [
      _c("subpage-banner", { attrs: { header: _vm.videoTitle, smaller: "" } }, [
        _vm.video
          ? _c("div", { staticClass: "c-video-details__header" }, [
              _c("ul", { staticClass: "b-inline-list" }, [
                _c("li", [_vm._v(_vm._s(_vm.video.speaker))]),
                _vm._v(" "),
                _c("li", [_vm._v(_vm._s(_vm.eventTime))])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "c-video-details__header__favorite" }, [
                _c("i", {
                  staticClass: "fa-heart",
                  class: _vm.video.is_favorite ? "fas" : "far",
                  attrs: { "aria-hidden": "true" },
                  on: { click: _vm.favoriteVideo }
                })
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade-in" } },
        [
          !_vm.currentUser
            ? _c("not-logged-user-message")
            : _vm.isLoading
              ? _c("loader", { staticClass: "mt-5" })
              : _vm.video
                ? _c(
                    "div",
                    { staticClass: "container-xl c-video-details__container" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-lg-9" },
                          [
                            _c("video-player", { attrs: { video: _vm.video } }),
                            _vm._v(" "),
                            _c("p", { staticClass: "mt-3" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.video.approval_code) +
                                  "\n                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "c-video-details__description" },
                              [
                                _c("h3", [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.$t("Common.details")) +
                                      "\n\n                            "
                                  ),
                                  _vm.messagingEnabled
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "b-action-btn c-video-details__message-btn",
                                          on: { click: _vm.openForm }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-comment-alt"
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("video-message", {
                                  attrs: {
                                    "modal-name": _vm.videoMessageModalName,
                                    "video-id": _vm.videoId,
                                    "video-title": _vm.videoTitle
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "c-video-details__description__text",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.video.description)
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-lg-3" }, [
                          _c(
                            "aside",
                            { staticClass: "c-video-details__aside" },
                            [
                              !_vm.isUk
                                ? _c("h3", { staticClass: "h6" }, [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.$t("SingleVideo.related")) +
                                        "\n                        "
                                    )
                                  ])
                                : _c("h3", { staticClass: "h6" }, [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$t("SingleVideo.relatedUk")
                                        ) +
                                        "\n                        "
                                    )
                                  ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "row" },
                                _vm._l(_vm.video.related_videos, function(
                                  relatedVideo,
                                  videoIndex
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: videoIndex,
                                      staticClass:
                                        "col-sm-6 col-lg-12 col-ul-6 mt-4"
                                    },
                                    [
                                      _c("video-card", {
                                        attrs: { video: relatedVideo }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                : !_vm.isLoading && !_vm.video
                  ? _c(
                      "div",
                      [
                        _c("message-box", {
                          staticClass: "mt-5",
                          attrs: {
                            icon: "times-circle",
                            title: _vm.$t("SingleVideo.notFound")
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
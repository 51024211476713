var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "form",
      staticClass: "c-default-form",
      attrs: { method: "POST" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      }
    },
    [
      _c("div", { staticClass: "c-default-form__title" }, [
        _vm.config.title
          ? _c("h4", { staticClass: "c-default-form__title__header" }, [
              _c("strong", [_vm._v(_vm._s(_vm.config.title))])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.config.subtitle
          ? _c("p", [_vm._v(_vm._s(_vm.config.subtitle))])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade-in" } },
        [
          _vm.formSaved
            ? _c(
                "message-box",
                {
                  attrs: {
                    icon: "check-circle",
                    "icon-class": "text-success",
                    title: _vm.successMessage
                  }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary mt-3",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.resetFormState($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Common.ok")))]
                  )
                ]
              )
            : _c(
                "div",
                [
                  _c("transition", { attrs: { name: "fade-in" } }, [
                    _vm.formErrors
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v(_vm._s(_vm.errorMessage))
                          ]),
                          _vm._v(" "),
                          _c("small", [
                            _c(
                              "ul",
                              { staticClass: "m-0 text-left" },
                              [
                                _vm._l(
                                  Object.values(_vm.parsedErrors),
                                  function(errors) {
                                    return _vm._l(errors, function(error) {
                                      return _c("li", { key: error }, [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(error) +
                                            "\n                                "
                                        )
                                      ])
                                    })
                                  }
                                )
                              ],
                              2
                            )
                          ])
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("transition", { attrs: { name: "fade-in" } }, [
                    _vm.emailsInvalid
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _c("small", [
                            _vm._v(_vm._s(_vm.$t("Common.incorrectEmail")))
                          ])
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _vm._l(_vm.fields, function(input, inputName) {
                        return [
                          input.type == "radio"
                            ? _c(
                                "div",
                                { key: inputName, staticClass: "col-12" },
                                [
                                  _c("inline-radio-select", {
                                    attrs: {
                                      name: inputName,
                                      options: input.options
                                    },
                                    model: {
                                      value: _vm.fieldValues[inputName],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.fieldValues,
                                          inputName,
                                          $$v
                                        )
                                      },
                                      expression: "fieldValues[inputName]"
                                    }
                                  })
                                ],
                                1
                              )
                            : input.type == "checkbox"
                              ? _c(
                                  "div",
                                  {
                                    key: inputName,
                                    class: [
                                      _vm.config.columns &&
                                      !input.forceFullWidth
                                        ? "col-12 col-sm-6"
                                        : "col-12"
                                    ]
                                  },
                                  [
                                    _c("checkbox", {
                                      key: inputName,
                                      attrs: {
                                        name: inputName,
                                        label: input.label,
                                        checked: input.checked,
                                        required: input.required,
                                        "is-large": input.isLarge
                                      },
                                      model: {
                                        value: _vm.fieldValues[inputName],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.fieldValues,
                                            inputName,
                                            $$v
                                          )
                                        },
                                        expression: "fieldValues[inputName]"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : input.type == "dropdown"
                                ? _c(
                                    "div",
                                    {
                                      key: inputName,
                                      class: [
                                        _vm.config.columns &&
                                        !input.forceFullWidth
                                          ? "col-12 col-sm-6"
                                          : "col-12"
                                      ]
                                    },
                                    [
                                      _c("custom-select", {
                                        attrs: {
                                          label: input.label,
                                          name: inputName,
                                          options: input.options,
                                          default: input.default,
                                          "float-label": true
                                        },
                                        model: {
                                          value: _vm.fieldValues[inputName],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.fieldValues,
                                              inputName,
                                              $$v
                                            )
                                          },
                                          expression: "fieldValues[inputName]"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : input.type == "textarea"
                                  ? _c(
                                      "div",
                                      { key: inputName, staticClass: "col-12" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "form-group has-float-label"
                                          },
                                          [
                                            input.label
                                              ? _c("span", [
                                                  _vm._v(_vm._s(input.label))
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.fieldValues[inputName],
                                                  expression:
                                                    "fieldValues[inputName]"
                                                }
                                              ],
                                              attrs: {
                                                placeholder: input.placeholder
                                              },
                                              domProps: {
                                                value:
                                                  _vm.fieldValues[inputName]
                                              },
                                              on: {
                                                blur: _vm.onInputBlur,
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.fieldValues,
                                                    inputName,
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  : input.type == "terms"
                                    ? _c(
                                        "div",
                                        {
                                          key: inputName,
                                          staticClass: "col-12"
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "b-scrollable-text",
                                            domProps: {
                                              innerHTML: _vm._s(input.content)
                                            }
                                          })
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          key: inputName,
                                          class: [
                                            _vm.config.columns &&
                                            !input.forceFullWidth
                                              ? "col-12 col-sm-6"
                                              : "col-12"
                                          ]
                                        },
                                        [
                                          _c("custom-text-input", {
                                            ref: inputName,
                                            refInFor: true,
                                            attrs: {
                                              type: input.type,
                                              name: inputName,
                                              label: input.label,
                                              default: input.default,
                                              required: input.required,
                                              disabled: input.disabled,
                                              placeholder: input.placeholder,
                                              tooltip: input.tooltip
                                            },
                                            on: { blur: _vm.onInputBlur },
                                            model: {
                                              value: _vm.fieldValues[inputName],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.fieldValues,
                                                  inputName,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "fieldValues[inputName]"
                                            }
                                          })
                                        ],
                                        1
                                      )
                        ]
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.requiredFields.length == _vm.fields.length
                    ? _c("p", { staticClass: "p-2" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("FormFields.allRequired")) +
                            "\n            "
                        )
                      ])
                    : _vm.requiredFields.length
                      ? _c("p", { staticClass: "p-2" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("FormFields.someRequired")) +
                              "\n            "
                          )
                        ])
                      : _vm._e(),
                  _vm._v(" "),
                  _vm._t("footer"),
                  _vm._v(" "),
                  _c("div", { staticClass: "c-default-form__actions" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-primary c-default-form__actions__submit",
                        attrs: {
                          type: "submit",
                          disabled: !_vm.canSubmitForm || _vm.submitting
                        }
                      },
                      [
                        _vm.submitting
                          ? _c("i", {
                              staticClass:
                                "fas fa-circle-notch fa-spin c-default-form__actions__loader"
                            })
                          : _vm._e(),
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.config.submitTitle ||
                                _vm.$t("FormFields.submit")
                            ) +
                            "\n                "
                        )
                      ]
                    )
                  ])
                ],
                2
              )
        ],
        1
      ),
      _vm._v(" "),
      _vm.config.links && _vm.config.links.length
        ? _c("div", { staticClass: "c-default-form__bottom" }, [
            _c(
              "ul",
              { staticClass: "b-inline-list" },
              _vm._l(_vm.config.links, function(link, linkIndex) {
                return _c(
                  "li",
                  { key: linkIndex, staticClass: "b-inline-list__item" },
                  [
                    _c(
                      "a",
                      {
                        class: { "font-weight-bold": link.isBold },
                        attrs: { href: link.url || "#" },
                        on: {
                          click: function($event) {
                            return _vm.linkAction($event, link.action)
                          }
                        }
                      },
                      [_vm._v(_vm._s(link.title))]
                    )
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "i",
    {
      staticClass: "c-icon",
      class: [
        _vm.iconStyle,
        _vm.fullGlyphName,
        _vm.fullSizeName,
        _vm.hoverable ? "fa-hoverable" : ""
      ]
    },
    [
      _c("transition", { attrs: { name: "scale-in" } }, [
        _vm.counter
          ? _c("span", { staticClass: "c-icon__counter" }, [
              _vm._v(_vm._s(_vm.counter))
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
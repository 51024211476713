<template>

    <div>
        <form-generator
            :url="accountChangeApiUrl"
            :fields="formFields"
            :config="formConfig"
            @success="onUserDataSaved"
            @logout="$emit('logout')"
        />
    </div>

</template>


<script>

import Service from '../../service';

import FormGenerator from '../Forms/FormGenerator.vue';

export default {
    name: 'UserAccountForm',

    components: {
        FormGenerator,
    },

    data() {
        return {
            accountChangeApiUrl: Service.prepareUrl('update-user-profile'),
            formConfig: {
                columns: true,
                title: this.$i18n.i18next.t('User.myProfile'),
                submitTitle: this.$i18n.i18next.t('UserAccount.update'),
                links: [
                    {
                        action: 'logout',
                        title: this.$i18n.i18next.t('UserAccount.logout'),
                        isBold: true,
                    },
                ],
            },
            formFields: {
                firstname: {
                    type: 'text',
                    label: this.$i18n.i18next.t('FormFields.firstName'),
                    required: true,
                },
                lastname: {
                    type: 'text',
                    label: this.$i18n.i18next.t('FormFields.lastName'),
                    required: true,
                },
                title: {
                    type: 'text',
                    label: this.$i18n.i18next.t('FormFields.title'),
                    required: true,
                },
                expertise: {
                    type: 'text',
                    label: this.$i18n.i18next.t('FormFields.expertise'),
                    required: true,
                },
                institution: {
                    type: 'text',
                    label: this.$i18n.i18next.t('FormFields.institution'),
                    required: true,
                },
                email: {
                    type: 'email',
                    label: this.$i18n.i18next.t('FormFields.email'),
                    required: false,
                    disabled: true,
                    tooltip: this.$i18n.i18next.t('UserAccount.contactUsLink', {input: 'email'}),
                },
                country: {
                    type: 'text',
                    label: this.$i18n.i18next.t('FormFields.country'),
                    required: true,
                    disabled: true,
                    tooltip: this.$i18n.i18next.t('UserAccount.contactUsLink', {input: 'country'}),
                },
            },
        };
    },

    methods: {
        /**
         * Set default values for each of the form fields.
         */
        resetFormValues(userData) {
            if (!userData) {
                return;
            }

            // Flatten the object because some of the user's attributes are stored in the `info` sub-object
            userData = {
                ...userData,
                ...userData.info,
            };

            Object.keys(this.formFields).forEach((fieldName) => {
                if (userData[fieldName]) {
                    this.formFields[fieldName].default = userData[fieldName];
                }
            });
        },

        onUserDataSaved({ response }) {
            this.resetFormValues((response.data && response.data.success) ? response.data.data : false);
        },
    },

    created() {
        this.resetFormValues(siteData.user);
    },
}

</script>

<template>

    <div class="c-subpage-banner" :class="{'has-image': bannerImageSrc}">
        <div class="container-xl">
            <div class="c-subpage-banner__content">
                <div>
                    <h1
                        v-if="header"
                        class="c-subpage-banner__header"
                        :class="{'h2': smaller}"
                    >{{ header }}</h1>
                </div>
            </div>

            <div class="c-subpage-banner__buttons">
                <a
                    v-for="(button, index) in buttons"
                    class="btn btn-raised"
                    :class="[`btn-${button.color}`]"
                    :key="index"
                    :href="button.url"
                >
                    {{ button.name }}
                </a>
            </div>

            <slot />
        </div>

        <div v-if="bannerImageSrc" class="c-subpage-banner__image">
            <responsive-image
                :src="bannerImageSrc"
                :alt="header || ''"
            />
        </div>
    </div>

</template>


<script>

import ResponsiveImage from '../Common/ResponsiveImage.vue';

export default {
    name: 'SubpageBanner',

    components: {
        ResponsiveImage,
    },

    props: {
        header: String,
        smaller: Boolean,
        bannerImageSrc: [String, Array],
    },

    data() {
        return {
            buttons: siteData.suggestedPages || [], // Taken from global scope head.php
        }
    }
}

</script>

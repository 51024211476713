export default () => {
    return {
        "Common": {
            "yes": "Oui",
            "no": "Non",
            "ok": "OK",
            "confirm": "Confirmer",
            "cancel": "Annuler",
            "success": "Accompli",
            "error": "Erreur",
            "search": "Rechercher",
            "next": "Suivant",
            "previous": "Précédent",
            "date": "Date",
            "filterDisease": "Filtrer par maladies",
            "filterYear": "Filtrer par années",
            "incorrectEmail": "Une adresse e-mail valide est requise!",
            "terms": "Conditions générales",
            "details": "Détails",
            "subject": "Objet",
            "loading": "Chargement en cours...",
            "searchResults": "Résultats de la recherche",
            "noResults": "Aucun résultat trouvé",
            "comingSoon": "Disponible prochainement...",
            "message": "Message",
            "emailSent": "Un email a été envoyé sur votre boite de réception",
            "send": "Envoyer",
            "sending": "Envoi en cours",
            "tryAgain": "Veuillez réessayer",
            "emptyList": "Cette liste est vide",
            "featureNotAvailableForChannel": "Cette fonctionnalité n'est pas disponible dans votre pays",
            "overview": "Aperçu",
            "relatedInformation": "Information connexe",
            "duration": "Durée",
        },
        "User": {
            "myProfile": "Mon profil",
            "register": "S'inscrire",
        },
        "MonthNames": {
            1: "Janvier",
            2: "Février",
            3: "Mars",
            4: "Avril",
            5: "Mai",
            6: "Juin",
            7: "Juillet",
            8: "Août",
            9: "Septembre",
            10: "Octobre",
            11: "Novembre",
            12: "Décembre",
        },
        "FormFields": {
            "firstName": "Prénom",
            "lastName": "Nom",
            "password": "Mot de passe",
            "passwordConfirm": "Confirmation du mot de passe",
            "title": "Civilité",
            "expertise": "Domaine d’expertise",
            "country": "Pays",
            "city": "Ville",
            "institution": "Institution",
            "email": "E-mail",
            "termsGdpr": "J’ACCEPTE",
            "phone": "Numéro de téléphone portable",
            "saveCompleted": "L’enregistrement a réussi",
            "saveFailed": "L’enregistrement a échoué",
            "keepLogged": "Maintenir la connexion",
            "forgotPasswordTitle": "Mot de passe oublié ?",
            "passwordStrength": "Mot de passe trop faible. Votre mot de passe doit contenir au moins un chiffre, une lettre majuscule, une lettre minuscule, et doit contenir, au moins, 8 caractères.",
            "yourPasswordSet": "Votre mot de passe a été correctement défini ! Vous allez être redirigé(e) automatiquement vers l’écran de connexion.",
            "agree": "J’accepte de recevoir de futures communications à propos des nouvelles vidéos et de l’actualité en matière d’éducation.",
            "newsletterSignUp": "Je m’inscris à la newsletter.",
            "personalDataProcessing":"En m’enregistrant sur ce formulaire, j'accepte que mes informations personnelles soient utilisées",
            "allRequired": "Tous les champs sont obligatoires",
            "someRequired": "* Champs Obligatoires",
            "submit": "Envoyer",
            "requestSubmittedThankYou":"Merci d'avoir envoyé votre demande.",
            "requestFailed":"La demande a échoué",
            "generalError": "Le champ {{field}} n'est pas valide.",
        },
        "RegisterNoBig": {
            "title": "Inscription",
            "terms": "Veuillez lire les conditions générales",
            "errorMessage": "L’inscription a échoué. Veuillez Réessayer.",
            "successMessage": "L’inscription a réussi.",
            "thankYou": `
                <h4>Merci de vous être inscrit(e),</h4>
                <p>Pour plus d’informations, veuillez consulter votre boîte de réception.</p>
                <p><strong>Nous espérons vous voir prochainement sur la plate-forme.</strong></p>
            `
        },
        "RegisterNoBigStep2": {
            "errorsInForm": "Il y a des erreurs dans le formulaire.",
            "emailExists": "Cette adresse e-mail est déjà enregistrée.",
        },
        "Login": {
            "login": "Connexion",
            "loginAcc": "Connexion à votre compte",
            "errorMessage": "Adresse e-mail ou mot de passe incorrect",
            "successMessage": "Connexion réussie",
            "returnToLoginForm": "Revenir au formulaire de connexion",
            "featureRequiresLogin": "Pour utiliser cette fonctionnalité, vous devez être connecté",
        },
        "Forgot": {
            "title": "Mot de passe oublié ?",
            "fillEmail": "Indiquez votre adresse e-mail",
            "sentEmail": `
                <p class="vue-modal__password-reset-success"><strong>Nous vous avons envoyé un e-mail,</strong></p>
                <p class="vue-modal__password-reset-success">consultez votre boîte de réception pour continuer.</p>
            `,
            "resetErrorMessage": "Cette adresse e-mail ne figure pas dans nos registres",
            "resetSuccessMessage": "Le mot de passe a été réinitialisé",
            "setUpNew": "Définir un nouveau mot de passe",
            "setUp": "Définir votre mot de passe",
            "goTo": "Accéder à la plate-forme de vidéos"
        },
        "UserAccount": {
            "errorMessage": "L’enregistrement de vos données a échoué. Veuillez réessayer.",
            "successMessage": "Vos données ont été enregistrées correctement.",
            "contactUsLink": "Contactez-nous pour modifier votre {{input}}",
            "update": "MISE À JOUR",
            "logout": "Déconnexion",
            "contactUs": "Contactez-nous",
            "requestEmail": "Je voudrais changer mon adresse e-mail",
            "requestCountry": "Je voudrais changer le nom du pays",
            "placeholderEmailMessage": "Veuillez écrire votre nouvelle adresse e-mail et ajouter des commentaires supplémentaires",
            "placeholderCountryMessage": "Veuillez écrire le nom de votre nouveau pays et ajouter des commentaires supplémentaires",
            "msgThanks": `
                <p><b>Merci pour votre message,</b></p>
                <p>nous vous recontacterons dès que possible</p>
                <p>par e-mail.</p>
            `
        },
        "NotFound": {
            "notFound": "page introuvable",
            "back": "Revenir en arrière",
        },
        "Favorites": {
            "favoriteVideos": "Vidéos favorites",
            "iconText": "Utilisez l’icône en forme de cœur pour ajouter vos vidéos favorites",
            "add": "Ajouter aux Favoris",
            "remove": "Retirer des favoris",
        },
        "Home": {
            "title": "Accueil",
            "favorites": "Favoris",
        },
        "VideoChannel": {
            "title": "Chaîne Vidéo",
            "featuredVideos": "Vidéos en vedette",
            "featuredVideosSlogan": "Notre meilleure sélection pour vous",
            "newUploads": "Nouvelles vidéos",
            "newUploadsSlogan": "Nous nous assurons que notre contenu <br><strong>est actualisé</strong>",
            "moreVideos": "Plus de vidéos",
            "moreVideosSlogan": "Il y a tellement plus que nous voudrions <br><strong> vous montrer</strong>",
            "allVideos": "Toutes les vidéos",
        },
        "Diagnostics": {
            "confirmRequestTest": "Veuillez confirmer que vous souhaitez recevoir un test de diagnostic",
            "confirmContactSpecialist": "Etes-vous sûr de vouloir contacter un spécialiste Sanofi en Genzyme?",
        },
        "GetInTouch": {
            "discussCase": "Discuter du cas avec un expert",
            "findExpert": "Veuillez saisir les informations suivantes pour trouver l'expert le mieux adapté à vos besoins.",
            "region": "Région",
            "country": "Pays",
            "disease": "Maladie",
            "consultationType": "Type de consultation",
            "specialistWillContact": "Un spécialiste Sanofi en Genzyme vous contactera sous peu.",
        },
        "Notifications": {
            "title": "Notifications",
            "hasNew": "Nous avons téléchargé des <span class=\"count-num\">{{notificationsCount}}</span> nouvelles vidéos pour vous.",
            "noNew": "Vous avez déjà visionné nos vidéos les plus récentes.",
            "clear": "clear"
        },
        "Languages": {
            "en_US": "Anglais",
            "fr_Fr": "Français",
            "es-Es": "Espagnol",
            "pt_Br": "Portugais"
        },
        "SingleVideo": {
            "watch": "Regarder la vidéo",
            "related": "Vidéos associées",
            "msgTitle": "Avez-vous des questions concernant cette vidéo ?",
            "msgPlaceholder": "Vous pouvez écrire vos commentaires ou questions ici...",
            "msgThanks": `
                <p><b>Merci pour votre message,</b></p>
                <p>nous vous recontacterons dès que possible</p>
                <p>par e-mail.</p>
            `,
            "notFound": "Vidéo introuvable",
            "cannotBeDisplayed": "La vidéo ne peut pas être affichée",
            "notSupported": "Le type de vidéo sélectionné ne convient pas",
            "settingMissing": "L'un des paramètres vidéo requis fait défaut",
        },
        "Footer": {
            "follow": "Nous suivre",
            "contact": "Contacter",
            "sitemap": "Plan du site",
            "legal": "Mentions légales",
            "privacy": "Protection des données et de la vie privée",
            "cookies": "Politique en matière de cookies",
            "rights": "Tous droits réservés",
            "corporate": "Code de déontologie de la société",
            "websites": "Nos sites Web",
            "publications": "Publications",
            "zinc": "Promomats",
            "cookiesText": "Ce site Web utilise des cookies pour suivre ses utilisateurs et améliorer son contenu. En continuant de naviguer sur ce site Web, vous acceptez l’utilisation de ces cookies.",
            "cookiesMore": "Plus d’informations sur les cookies",
            "cookiesOK": "OK",
            "siteIntendedFor": "Pour les professionnels de la santé uniquement",
        },
        "Rating": {
            "welcomeTitle": "Nous aimerions savoir ce que vous pensez de notre plateforme vidéo",
            "welcomeText": "Pouvez-vous répondre à quelques questions - cela ne prendra que quelques minutes",
            "btnStartRate": "SÛR",
            "btnDenyRate": "Non merci",
            "ratingInfoText": "Cliquez sur les étoiles pour passer à la question suivante",
            "rateVeryUnlikely": "Très Improbable",
            "rateUnlikely": "Peu Probable",
            "rateNeutrally": "Neutre",
            "rateLikely": "Probable",
            "rateVeryLikely": "Très Probable",
            "thanksTitle": "<strong>Merci</strong> pour votre avis,",
            "thanksText": "Nous vous remercions de prendre le temps de répondre à nos questions."
        },
        "ChannelComingSoon": {
            "comingSoon": "Arrive bientôt",
            "willAddSoon": "Nous ajouterons bientôt des vidéos pour cette chaîne.",
            "letYouKnow": "Nous vous informerons dès qu'elles seront disponibles.",
        },
        "Diseases": {
            "gaucher": {
                "fullName": "Maladie de Gaucher",
                "freeDiagnosticTest": "Demandez votre test de diagnostic pour la maladie de Gaucher",
                "contactSpecialist": "Contacter le spécialiste Sanofi à propos de la maladie de Gaucher",
            },
            "pompe": {
                "fullName": "Maladie de Pompe",
                "freeDiagnosticTest": "Demandez votre test de diagnostic pour la maladie de Pompe.",
                "contactSpecialist": "Contactez un spécialiste Sanofi à propos de la maladie de Pompe.",
            },
            "fabry": {
                "fullName": "Maladie de Fabry",
                "freeDiagnosticTest": "Demandez votre test de diagnostic pour la maladie de Fabry.",
                "contactSpecialist": "Contacter le spécialiste Sanofi à propos de la maladie de Fabry.",
            },
            "mps1": {
                "fullName": "Mucopolisacaridose",
                "freeDiagnosticTest": "Demandez votre test de diagnostic pour MPS.",
                "contactSpecialist": "Contactez le spécialiste Sanofi à propos de MPS.",
            },
            "moreInformationInludingBrochure": "Demandez des informations supplémentaires ainsi que la brochure.",
        },
        "Events": {
            "allEvents": "Tous les évènements",
            "details": "Détails de l'évènement",
            "upcomingEvents": "Évènements à venir",
            "registerForTheEvent": "S'inscrire",
            "speakers":"Orateurs",
            "agenda": "Agenda",
            "moreAboutMe": "À propos de moi",
            "successfullyRegistered": "Merci de vous être inscrit",
            "registrationEmailSent": "Un email de confirmation vous a été envoyé à votre adresse email."
        },
        "Elearning": {
            "details": "Elearning details",
            "finished": "Elearning finished",
            "accredited": "Accredited",
            "introText": "Start learning and earn accreditation points today",
            "doesNotExist": "This elearning does not exist",
            "startElearning": "Start the e-Learning",
            "preExam": "Pre video test questions",
            "postExam": "Post video test questions",
            "examFinished": "Test finished",
            "examFailed": "You failed...",
            "examPassed": "Congratulations, you passed",
            "examComplete": "Complete test",
            "examRetake": "Retake test",
            "watchAgain": "Watch again",
            "attemptsLeft": "Attempts left",
            "accreditationProcess": `
                <ol class="u-ordered-list">
                    <li>Before this e-Learning module begins, we ask that you take a brief quiz. These results are not scored.</li>
                    <li>Following this pre-test will be the video for you to view.</li>
                    <li>After viewing the video, you will then take a post-exam. The answers you provide are evaluated.</li>
                    <li>When you have completed the test, view your results! To gain accreditation you must score <strong>70%</strong> or higher.</li>
                </ol>
            `,
            "savingTheProgress": "Saving the progress...",
            "examLoadingResults": "Calculating results",
            "noAttemptsLeft": "Unfortunately, there are no more attempts left and access to video has been limited.",
            "goBackToListing": "See all e-learnings",
            "accredited": "Accredited",
            "completed": "Completed",
            "disabled": "Inactive",
            "points": "Points"
        },
    };
}

<template>

    <modal
        width="800"
        height="auto"
        scrollable
        adaptive
        :name="modalName"
    >
        <modal-action-buttons
            :back-action="(currentStep > 1) ? 'go-back' : null"
            :close-modal="modalName"
            @go-back="switchToPreviousStep"
        />

        <div class="b-modal-content">
            <h4 class="mb-4 text-center">{{ $t('RegisterNoBig.title') }}</h4>

            <progress-steps
                :steps-number="stepsNumber"
                :current-step="currentStep"
            />

            <reg-step-1
                v-if="currentStep === 1"
                :languages="languages"
                :is-from-next-step="goneBackToStepOne"
                @success="switchToNextStep"
                @go-back="switchToPreviousStep"
            />

            <reg-step-2
                v-if="currentStep === 2"
                @success="switchToNextStep"
                @go-back="switchToPreviousStep"
            />

            <reg-step-3
                v-if="currentStep === 3"
                @go-back="switchToPreviousStep"
            />
        </div>
    </modal>

</template>


<script>

// Registration steps
import RegStep1 from '../Register/RegisterStep1.vue';
import RegStep2 from '../Register/RegisterStep2.vue';
import RegStep3 from '../Register/RegisterStep3.vue';

import ModalActionButtons from '../Common/ModalActionButtons.vue';
import ProgressSteps from '../Common/ProgressSteps.vue';

const stepsComponents = { RegStep1, RegStep2, RegStep3 };

export default {
    name: 'RegisterModal',

    components: {
        ...stepsComponents,
        ModalActionButtons,
        ProgressSteps,
    },

    props: {
        cmsLanguages: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            modalName: 'register-modal',
            currentStep: 1, // Starting step number
            goneBackToStepOne: false,
            languages: JSON.parse(this.cmsLanguages),
        }
    },

    computed: {
        stepsNumber() {
            return Object.keys(stepsComponents).length;
        },
    },

    mounted() {
        this.checkRegisterInProgress();
    },

    methods: {
        /**
         * Switch to next step if it exists
         */
        switchToNextStep() {
            if (this.currentStep !== this.stepsNumber) {
                this.currentStep++;
            }
        },

        switchToPreviousStep() {
            if (this.currentStep > 1) {
                this.currentStep--;
            }

            if (this.currentStep === 1) {
                this.goneBackToStepOne = true;
            }
        },

        checkRegisterInProgress() {
            if (localStorage.getItem('registering')) {
                localStorage.removeItem('registering');
                this.currentStep = 2;
                this.$modal.show(this.modalName);
            }
        },
    },
}

</script>

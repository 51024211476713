var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "message-box",
    {
      attrs: {
        title: _vm.$t("Login.featureRequiresLogin"),
        icon: "user-circle"
      }
    },
    [
      _c(
        "button",
        {
          staticClass: "btn btn-primary mt-3",
          on: {
            click: function($event) {
              return _vm.$modal.show("login-modal")
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("Login.login")))]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary mt-3",
          on: {
            click: function($event) {
              return _vm.$modal.show("register-modal")
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("User.register")))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
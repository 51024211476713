import Vue from 'vue/dist/vue';
import VueI18Next from '@panter/vue-i18next';
import i18next from 'i18next';

Vue.use(VueI18Next);

import initEn from './en.js';
import initEs from './es.js';
import initFr from './fr.js';
import initPt from './pt.js';
import initIn from './in.js';

i18next.init({
    lng: siteData.currentLanguage, // Set language based on ProcessWire's language
    fallbackLng: 'en',
    resources: {
        en: {translation: initEn()},
        es: {translation: initEs()},
        fr: {translation: initFr()},
        pt: {translation: initPt()},
        in: {translation: initIn()},
    }
});

export default new VueI18Next(i18next);

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "c-video-list-section" }, [
    _vm.bannerImageSrc
      ? _c(
          "div",
          { staticClass: "c-video-list-section__banner row" },
          [
            _vm.bannerText
              ? _c("h2", {
                  staticClass: "c-video-list-section__banner__text",
                  domProps: { innerHTML: _vm._s(_vm.bannerText) }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("responsive-image", {
              staticClass: "c-video-list-section__banner__image",
              attrs: { src: _vm.bannerImageSrc, alt: _vm.title || "" }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "container-xl c-video-list-section__content" },
      [
        _vm.title
          ? _c("h3", { domProps: { innerHTML: _vm._s(_vm.title) } })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "fade-in" } },
          [
            _vm.isLoading
              ? _c("loader", { staticClass: "mt-5 mb-5" })
              : _vm.videos && _vm.videos.length
                ? _c("video-list", {
                    attrs: {
                      videos: _vm.videosLimited,
                      "number-of-featured": _vm.numberOfFeatured
                    },
                    on: {
                      "change-favorite": function($event) {
                        return _vm.$emit("change-favorite")
                      }
                    }
                  })
                : _c("message-box", {
                    attrs: {
                      title: _vm.$t("Common.emptyList"),
                      icon: "times-circle"
                    }
                  })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>

    <div id="page-links">
        <subpage-banner
            :header="pageTitle"
            :banner-image-src="[
                {size: 1920, src: '/site/templates/img/general/top-banner.jpg'},
                {size: 768, src: '/site/templates/img/general/ipad-top-banner.jpg'},
                {size: 375, src: '/site/templates/img/general/mobile-top-banner.jpg'},
            ]"
        />

        <div class="container-xl mt-5">
            <h4 v-if="headline" v-html="headline" class="text-center mb-4"></h4>

            <div class="row mt-5">
                <div class="col-md-6 col-lg-3 mt-3" v-for="(link, index) in list" :key="index">
                    <strong>{{ link.title }}</strong><br>
                    <a :href="link.url" target="_blank">{{ link.url }}</a>
                </div>
            </div>
        </div>
    </div>

</template>


<script>

import SiteHeader from '../SiteHeader/SiteHeader.vue';
import SubpageBanner from '../SubpageBanner/SubpageBanner.vue';

export default {
    name: 'Links',

    components: {
        SiteHeader,
        SubpageBanner,
    },

    props: {
        pageTitle: {
            type: String,
        },
        headline: {
            type: String,
        },
        /**
         * Array of links [{url: '', title: ''}, ...]
         */
        list: {
            type: Array,
        },
    },
}

</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      staticClass: "notifications-modal",
      attrs: {
        width: "466",
        height: "auto",
        name: _vm.modalName,
        scrollable: "",
        adaptive: ""
      }
    },
    [
      _c(
        "div",
        { staticClass: "b-modal-content vue-modal" },
        [
          _c("modal-action-buttons", {
            attrs: { "close-modal": _vm.modalName }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "py-3 notification-content" }, [
            _c("h4", [
              _c("strong", [_vm._v(_vm._s(_vm.$t("Notifications.title")))])
            ]),
            _vm._v(" "),
            _vm.notifications.length
              ? _c(
                  "div",
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-between"
                      },
                      [
                        _c("span", {
                          staticClass: "mr-2",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("Notifications.hasNew", {
                                notificationsCount: _vm.notifications.length
                              })
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass:
                              "btn btn-sm btn-primary px-2 px-md-1 text-light",
                            staticStyle: { "min-height": "auto" },
                            on: { click: _vm.clearNotifications }
                          },
                          [_vm._v(_vm._s(_vm.$t("Notifications.clear")))]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.notifications, function(notification) {
                      return _c(
                        "div",
                        { key: notification.id, staticClass: "my-4" },
                        [
                          _c("elearning-card", {
                            attrs: { entry: notification }
                          })
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              : _c("div", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("Notifications.noNew")) +
                      "\n            "
                  )
                ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
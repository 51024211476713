<template>

    <modal
        class="notifications-modal"
        width="466"
        height="auto"
        :name="modalName"
        scrollable
        adaptive
    >
        <div class="b-modal-content vue-modal">
            <modal-action-buttons :close-modal="modalName" />

            <div class="py-3 notification-content">
                <h4><strong>{{ $t('Notifications.title') }}</strong></h4>

                <div v-if="notifications.length">
                    <p class="d-flex align-items-center justify-content-between">
                        <span v-html="$t('Notifications.hasNew', { notificationsCount: notifications.length })" class="mr-2"></span>
                        <a @click="clearNotifications" class="btn btn-sm btn-primary px-2 px-md-1 text-light" style="min-height: auto;">{{ $t('Notifications.clear') }}</a>
                    </p>
                    <div v-for="notification in notifications" :key="notification.id" class="my-4">
                        <elearning-card :entry="notification" />
                    </div>
                </div>

                <div v-else>
                    {{ $t('Notifications.noNew') }}
                </div>
            </div>
        </div>
    </modal>

</template>


<script>

import Service from '../../service';
import ModalActionButtons from '../Common/ModalActionButtons.vue';
import ElearningCard from '../Elearning/ElearningCard.vue';

export default {
    name: 'NotificationsModal',

    components: {
        ModalActionButtons,
        ElearningCard,
    },

    data() {
        return {
            modalName: 'notifications-modal',
            notifications: [],
        }
    },

    methods: {
        async fetchNotifications() {
            this.notifications = await Service.getNotifications();
        },

        clearNotifications() {
            this.$parent.$emit('toggleSlideNavigation');

            if (this.notifications.length) {
                Service.clearNotifications();
                this.notifications = [];
                window.GlobalEventBus.$emit('clearNotifications');
            }

            this.closeModal();
        },

        closeModal() {
            this.$modal.hide(this.modalName);
        },
    },

    created() {
        this.fetchNotifications();
    },
}

</script>

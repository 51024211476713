var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        width: "800",
        height: "auto",
        name: _vm.modalName,
        scrollable: "",
        adaptive: ""
      }
    },
    [
      _c("modal-action-buttons", { attrs: { "close-modal": _vm.modalName } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "b-modal-content" },
        [
          _c(
            "transition",
            { attrs: { name: "fade-in" } },
            [
              _vm.registered
                ? _c(
                    "message-box",
                    {
                      attrs: {
                        icon: "check-circle",
                        "icon-class": "text-success",
                        title: _vm.$t("Events.successfullyRegistered"),
                        message: _vm.$("Events.registrationEmailSent")
                      }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mt-3",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.closeModal($event)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Common.ok")))]
                      )
                    ]
                  )
                : _c("form-generator", {
                    attrs: {
                      url: _vm.endpointUrl,
                      fields: _vm.formFields,
                      config: _vm.formConfig
                    },
                    on: { success: _vm.registeredSuccessfully }
                  })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
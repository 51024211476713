<template>

    <div class="c-video-details">
        <subpage-banner
            :header="(currentElearning) ? currentElearning.title : $t('Elearning.details')"
            smaller
        />

        <div class="container-xl c-video-details__container mb-5">
            <not-logged-user-message
                v-if="!currentUser"
                class="mb-5"
            />
            <div v-else class="row">
                <!--
                    Vide details column
                -->
                <main class="col-lg-9">
                    <transition name="fade-in">
                        <loader v-if="isLoading" class="my-5" />

                        <div v-else-if="video">
                            <elearning-video
                                :video="video"
                                :pre-exam-questions="preExamQuestions"
                                :during-exam-questions="duringExamQuestions"
                                :accreditation-uuid="accreditationUuid"
                                :is-already-completed="currentElearning.isCompleted"
                                class="mb-5"
                            />

                            <h3>{{ $t('Common.details') }}</h3>
                            <div v-html="currentElearning.description"></div>
                        </div>

                        <message-box
                            v-else
                            icon="times-circle"
                            :title="$t('Elearning.doesNotExist')"
                        />
                    </transition>
                </main>

                <!--
                    Sidebar
                -->
                <aside class="col-lg-3">
                    <h3 class="h6">{{ $t('Common.relatedInformation') }}</h3>

                    <div v-if="isLoading" class="text-center mt-4">
                        <loader />
                    </div>
                    <div v-else class="row">
                        <div
                            v-for="entry in relatedElearnings"
                            :key="entry.id"
                            class="col-sm-6 col-lg-12 col-ul-6 mt-4"
                        >
                            <elearning-card :entry="entry" />
                        </div>
                    </div>
                </aside>
            </div>
        </div>

        <modal
            width="600"
            height="auto"
            scrollable
            adaptive
            :click-to-close="false"
            :name="blockedModalName"
        >
            <div class="b-modal-content text-center">
                <h4 class="mb-4">{{ $t('Elearning.examFinished') }}</h4>
                <div class="w-50 m-auto text-center">
                    <h5 class="p-2">{{ $t('Elearning.noAttemptsLeft') }}</h5>
                    <a href="/elearning/" class="btn btn-primary text-white">{{ $t('Elearning.goBackToListing') }}</a>
                </div>        
            </div>
        </modal>
    </div>

</template>


<script>

import Service from '../../service';
import currentUser from '../../utility/currentUser.js';

import ElearningVideo from './ElearningVideo.vue';
import ElearningCard from './ElearningCard.vue';

export default {
    name: 'ElearningDetailsPage',

    components: {
        ElearningVideo,
        ElearningCard,
    },

    props: {
        videoUuid: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            currentUser,
            isLoading: false,
            video: null,
            preExamQuestions: null,
            duringExamQuestions: null,
            elearnings: null,
            blockedModalName: "blocked-modal",
        }
    },

    computed: {
        relatedElearnings() {
            if (this.elearnings && this.elearnings.length) {
                return this.elearnings
                    .filter(elerning => elerning.uuid !== this.currentElearning.uuid && !elerning.isDisabled)
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .slice(0, 4);
            }
            return [];
        },

        currentElearning() {
            return (this.elearnings)
                ? this.elearnings.find(({ uuid }) => uuid === this.videoUuid)
                : null;
        },

        accreditationUuid() {
            return (this.currentElearning)
                ? this.currentElearning.accreditation_uuid
                : null;
        },
    },

    methods: {
        /**
         * Currently there is no separate endpoint that provides one elearning so here we are fetching all elearnings
         * so than we can choose one and display it.
         */
        async fetchElearnings() {
            this.elearnings = await Service.getElearnings();
            return true;
        },

        /**
         * This data contains both video data and list of questions that should be asked during the playbackf the video
         */
        async fetchDuringExamData() {
            const { questions, video } = await Service.getElearningDuringExam(this.videoUuid);
            this.video = video;
            this.duringExamQuestions = questions;
            return true;
        },

        /**
         * List of questions that should be asked before actual playback and after the playback finish.
         */
        async fetchPreExamData() {
            if (this.accreditationUuid) {
                const { questions } = await Service.getElearningAccreditation(this.accreditationUuid);
                this.preExamQuestions = questions;
            }
            return true;
        },

        

        async fetchElearningData() {
            this.isLoading = true;

            await Promise.all([
                this.fetchElearnings(),
                this.fetchDuringExamData(),
            ]);

            await this.fetchPreExamData();

            // Block access to e-learning if it's blocked
            if (this.currentElearning.isDisabled) {
                this.$modal.show(this.blockedModalName);
            }

            this.isLoading = false;
        },
    },

    created() {
        this.fetchElearningData();
    },
};

</script>

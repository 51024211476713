var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-generator", {
        attrs: {
          url: _vm.accountChangeApiUrl,
          fields: _vm.formFields,
          config: _vm.formConfig
        },
        on: {
          success: _vm.onUserDataSaved,
          logout: function($event) {
            return _vm.$emit("logout")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
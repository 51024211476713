var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      staticClass: "c-exit-modal",
      attrs: {
        width: "500",
        height: "auto",
        name: _vm.modalName,
        scrollable: true
      }
    },
    [
      _c("modal-action-buttons", { attrs: { "close-modal": _vm.modalName } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "b-modal-content" },
        [
          _c("select-country", {
            attrs: { languages: _vm.languages },
            on: { "no-redirect": _vm.close }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
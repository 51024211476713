<template>

    <div>
        <div v-if="!questions.length" class="all-question-loader">
            <loader />
        </div>

        <template v-else>
            <template v-if="questions.length > 1">
                <progress-steps
                    :steps-number="questions.length"
                    :current-step="activeQuestionIndex + 1"
                    :steps-checked="questionsVoted"
                />

                <p><small>{{ $t('Rating.ratingInfoText') }}</small></p>
            </template>

            <loader v-if="loading" />

            <div v-else class="single-question">
                <div class="title-text">
                    {{ activeQuestion.body }}
                </div>
                <div class="ratings-stars">
                    <div
                        v-for="(starVote, index) in starVotes"
                        class="rate-item"
                        :key="index"
                        @click="voteForQuestion(starVote)"
                    >
                        <div class="icon"></div>
                        <div class="text">{{ starVote.label }}</div>
                    </div>
                </div>
            </div>
        </template>
    </div>

</template>


<script>

    import apiUrl from '../../utility/apiUrl';
    import axios from 'axios';
    import ProgressSteps from '../Common/ProgressSteps.vue';

    export default {
        name: 'RatingsScreen',

        components: {
            ProgressSteps,
        },

        data() {
            return {
                // TODO Get Questions
                questions: [],
                starVotes: [
                    {rate: '1', label: this.$i18n.i18next.t("Rating.rateVeryUnlikely")},
                    {rate: '2', label: this.$i18n.i18next.t("Rating.rateUnlikely")},
                    {rate: '3', label: this.$i18n.i18next.t("Rating.rateNeutrally")},
                    {rate: '4', label: this.$i18n.i18next.t("Rating.rateLikely")},
                    {rate: '5', label: this.$i18n.i18next.t("Rating.rateVeryLikely")}
                ],
                activeQuestionIndex: 0,
                questionIds: [],
                questionRatings: [],
                loading: false
            }
        },

        computed: {
            activeQuestion() {
                return this.questions[this.activeQuestionIndex];
            },

            questionsVoted() {
                return this.questions
                    .reduce((storedIndexes, question, index) => {
                        if (question.voted) {
                            storedIndexes.push(index + 1);
                        }
                        return storedIndexes;
                    }, []);
            },
        },

        methods: {
            goNextQuestion() {
                this.loading = true;

                setTimeout(() => {
                    if (this.activeQuestionIndex < this.questions.length - 1) {
                        this.activeQuestionIndex += 1;
                        this.loading = false;
                    } else {
                        // this.postQuestions();
                    }
                }, 500);
            },

            voteForQuestion({ rate }) {
                this.$set(this.activeQuestion, 'voted', rate); // Force to update computed data
                this.storeQuestionsAnswers(this.activeQuestion.id, rate);
                this.goNextQuestion();
            },

            storeQuestionsAnswers(id, rate) {
                this.questionIds.push(id);
                this.questionRatings.push(rate);
            },

            async postQuestions() {
                await axios.post(apiUrl('rate-questions'), {
                    questionIds: this.questionIds,
                    questionRatings: this.questionRatings
                })
                    .then(response => {
                        this.$emit('endRate');
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },

            getQuestions() {
                axios.get(apiUrl('get-channel-rating-questions'))
                    .then(response => {
                        this.questions = response.data.data;
                    });
            }
        },

        created() {
            this.getQuestions();
        },
    }

</script>

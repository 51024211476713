<template>

    <modal
        width="800"
        height="auto"
        :name="modalName"
        scrollable
        adaptive
    >
        <modal-action-buttons :close-modal="modalName" />

        <div class="b-modal-content">
            <transition name="fade-in">
                <message-box
                    v-if="registered"
                    icon="check-circle"
                    icon-class="text-success"
                    :title="$t('Events.successfullyRegistered')"
                    :message="$('Events.registrationEmailSent')"
                >
                    <button class="btn btn-primary mt-3" @click.prevent="closeModal">{{ $t('Common.ok') }}</button>
                </message-box>

                <form-generator
                    v-else
                    :url="endpointUrl"
                    :fields="formFields"
                    :config="formConfig"
                    @success="registeredSuccessfully"
                />
            </transition>
        </div>
    </modal>

</template>


<script>

import apiUrl from '../../utility/apiUrl';
import currentUser from '../../utility/currentUser';

import FormGenerator from '../Forms/FormGenerator.vue';
import ModalActionButtons from '../Common/ModalActionButtons.vue';

export default {
    name: 'EventRegistrationForm',

    components: {
        FormGenerator,
        ModalActionButtons,
    },

    props: {
        modalName: {
            type: String,
            default: 'event-registration-form',
        },
    },

    data() {
        return {
            currentUser,
            registered: false,
            endpointUrl: apiUrl('handle-event-registration-form'),
            formFields: {
                email: {
                    type: 'text',
                    label: this.$i18n.i18next.t('FormFields.email'),
                    required: true,
                    forceFullWidth: true,
                },
                firstname: {
                    type: 'text',
                    label: this.$i18n.i18next.t('FormFields.firstName'),
                    required: true,
                },
                lastname: {
                    type: 'text',
                    label: this.$i18n.i18next.t('FormFields.lastName'),
                    required: true,
                },
                phone: {
                    type: 'text',
                    label: this.$i18n.i18next.t('FormFields.phone'),
                },
                institution: {
                    type: 'text',
                    label: this.$i18n.i18next.t('FormFields.institution'),
                },
                country: {
                    type: 'text',
                    label: this.$i18n.i18next.t('FormFields.country'),
                },
                city: {
                    type: 'text',
                    label: this.$i18n.i18next.t('FormFields.city'),
                },
                gdpr: {
                    type: 'checkbox',
                    label: this.$i18n.i18next.t('FormFields.personalDataProcessing'),
                    required: true,
                },
                newsletter: {
                    type: 'checkbox',
                    label: this.$i18n.i18next.t('FormFields.newsletterSignUp'),
                },
            },
            formConfig: {
                columns: true,
                title: this.$i18n.i18next.t('Events.registerForTheEvent'),
                submitTitle: this.$i18n.i18next.t('User.register'),
            },
        }
    },

    methods: {
        registeredSuccessfully() {
            this.registered = true;
        },

        closeModal() {
            this.$modal.hide(this.modalName);
        },
    },

    created() {
        const { email, firstname, lastname, info } = this.currentUser;

        this.formFields.email.default = email;
        this.formFields.firstname.default = firstname;
        this.formFields.lastname.default = lastname;
        this.formFields.institution.default = info.institution;
        this.formFields.country.default = info.country;
    },
}

</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideDropdown,
          expression: "hideDropdown"
        }
      ],
      staticClass: "c-custom-select form-group",
      class: {
        "is-open": _vm.dropdownVisible,
        "has-float-label": _vm.floatLabel
      },
      attrs: { tabindex: "0" },
      on: { click: _vm.toggleOpen }
    },
    [
      _vm.showReset && _vm.selected
        ? _c("i", {
            staticClass: "c-custom-select__reset fas fa-undo",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.clearSelected($event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.floatLabel
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selected,
                  expression: "selected"
                }
              ],
              staticClass: "float-label"
            },
            [_c("span", [_vm._v(_vm._s(_vm.label))])]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "c-custom-select__label" }, [
        _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.selectedLabel))]),
        _vm._v(" "),
        _c("i", { staticClass: "fas fa-chevron-down" })
      ]),
      _vm._v(" "),
      _c(
        "ul",
        [
          _vm.areOptionsArray
            ? _vm._l(_vm.options, function(name, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class: { active: name == _vm.selected },
                    on: {
                      click: function($event) {
                        return _vm.selectOption(name)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " + _vm._s(name) + "\n            "
                    )
                  ]
                )
              })
            : _vm._l(_vm.options, function(name, value, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class: { active: _vm.selected == value },
                    on: {
                      click: function($event) {
                        return _vm.selectOption(value)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " + _vm._s(name) + "\n            "
                    )
                  ]
                )
              })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticClass: "c-custom-text-input form-group has-float-label" },
    [
      _c("input", {
        staticClass: "form-control",
        class: [{ "is-invalid": _vm.isInvalid }],
        attrs: {
          type: _vm.type,
          name: _vm.name,
          required: _vm.required,
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
          "aria-describedby": "tooltip_" + _vm.name
        },
        domProps: { value: _vm.value },
        on: { input: _vm.onChange, blur: _vm.onBlur }
      }),
      _vm._v(" "),
      _c("span", [
        _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
        _vm._v("\n        " + _vm._s(_vm.label) + "\n    ")
      ]),
      _vm._v(" "),
      _vm.tooltip
        ? _c(
            "span",
            {
              staticClass: "tooltip tooltip-over-input",
              attrs: { role: "tooltip", id: "tooltip_" + _vm.name }
            },
            [
              _c("i", { staticClass: "fas fa-exclamation-circle" }),
              _vm._v(" "),
              _c("span", { staticClass: "tooltip-text" }, [
                _vm._v("\n            " + _vm._s(_vm.tooltip) + "\n        ")
              ])
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
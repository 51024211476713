import '../styles/main.scss';
import Vue from 'vue/dist/vue';
import VModal from 'vue-js-modal';
import VTooltip from 'v-tooltip';
import './component-registry';
import i18n from './i18n';
import { formatDate, timeFromSeconds } from './utility/filters';
import { loadCss } from './utility/loadExternalResource';

// Load 3rd party CSS
loadCss('https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i&subset=cyrillic,cyrillic-ext,devanagari,greek,greek-ext,latin-ext,vietnamese');
loadCss('https://use.fontawesome.com/releases/v5.0.13/css/all.css');

// Vue plugin declarations
Vue.use(VModal);
Vue.use(VTooltip);

// Setup global Vue instance filters
Vue.filter('formatDate', formatDate);
Vue.filter('timeFromSeconds', timeFromSeconds);

// Since our app is not fully JS app, we need an event bus to communicate to different parts
const GlobalEventBus = new Vue();
window.GlobalEventBus = GlobalEventBus;

const app = new Vue({
    el: '#app',

    i18n,

    methods: {

        /**
         * Add class, that indicates page is scrolled to #app element.
         * This class can be used in other components to differentiate if user
         * is at the top of the page or not.
         */
        checkIfPageIsScrolled() {
            this.$el.classList.toggle('is-page-scrolled', (window.pageYOffset > 20));
        },

        /**
         * Timeout debounce
         * @param {Function} callback
         * @returns {Function}
         */
        debounce(callback) {
            let timeout;

            return () => {
                clearTimeout(timeout);
                timeout = setTimeout(callback.bind(this), 40); // 40ms idle
            };
        },
    },

    mounted() {
        this.checkIfPageIsScrolled();
        window.addEventListener('scroll', this.debounce(this.checkIfPageIsScrolled));
    },
});

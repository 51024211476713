var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-single-text-page" },
    [
      _c("subpage-banner", { attrs: { header: _vm.title, smaller: "" } }),
      _vm._v(" "),
      _c("div", { staticClass: "container-xl mt-5" }, [
        _c("div", { staticClass: "u-medium-content" }, [_vm._t("default")], 2)
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
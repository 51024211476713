export default () => {
    return {
        "Common": {
            "yes": "Yes",
            "no": "No",
            "ok": "OK",
            "confirm": "Confirm",
            "cancel": "Cancel",
            "success": "Success",
            "error": "Error",
            "search": "Search",
            "next": "Next",
            "previous": "Previous",
            "date": "Date",
            "filterDisease": "Filter by disease",
            "filterYear": "Filter by year",
            "incorrectEmail": "A valid email is required!",
            "terms": "Terms and Conditions",
            "details": "Details",
            "subject": "Subject",
            "loading": "Loading...",
            "searchResults": "Search results",
            "noResults": "No results found",
            "comingSoon": "Coming soon...",
            "message": "Message",
            "emailSent": "An email has been sent to your inbox.",
            "send": "Send",
            "sending": "Sending...",
            "tryAgain": "Please try again.",
            "emptyList": "This list is empty",
            "featureNotAvailableForChannel": "This feature is not available for current country",
            "overview": "Overview",
            "relatedInformation": "Related information",
            "duration": "Duration",
            "back": "Back",
            "noTableData": "No data to show yet",
        },
        "User": {
            "myProfile": "My profile",
            "register": "Register",
            "registrationSuccessTitle": "Registration Successfull",
            "registrationSuccessMessage": "You have now successfully registered to RDNexus",
            "registrationFailedTitle": "Registration failed",
            "registrationFailedMessage": "Please refresh page or contact support for help",
            "loggingOut": "Logging out",
            "loggedOut": "You have been successfully logged out",
        },
        "MonthNames": {
            1: "January",
            2: "February",
            3: "March",
            4: "April",
            5: "May",
            6: "June",
            7: "July",
            8: "August",
            9: "September",
            10: "October",
            11: "November",
            12: "December",
        },
        "FormFields": {
            "firstName": "First name",
            "lastName": "Last name",
            "password": "Password",
            "passwordConfirm": "Password confirm",
            "title": "Title",
            "expertise": "Area of expertise",
            "country": "Country",
            "city": "City",
            "institution": "Institution",
            "email": "E-mail",
            "termsGdpr": "I Accept",
            "phone": "Mobile phone number",
            "saveCompleted": "Save completed",
            "saveFailed": "Save failed",
            "keepLogged": "Keep me logged in",
            "forgotPasswordTitle": "Forgot your password?",
            "passwordStrength": "Password too weak. Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters",
            "yourPasswordSet": "Your password is successfully set! You will be redirected to login screen automatically.",
            "agree": "I agree to receive future communications on new videos and educational updates.",
            "newsletterSignUp": "Sign me up for the newsletter.",
            "personalDataProcessing": "By registering for this form, I consent to processing my personal information.",
            "allRequired": "All fields are considered mandatory",
            "someRequired": "* Required fields",
            "submit": "Submit",
            "requestSubmittedThankYou": "Thank you for submitting your request.",
            "requestFailed": "Request failed",
            "generalError": "The {{field}} field is invalid.",
        },
        "RegisterNoBig": {
            "title": "Registration",
            "terms": "Please read the T&Cs",
            "errorMessage": "Registration failed. Please try again",
            "successMessage": "Registration successful",
            "thankYou": `
                <h4>Thank you for registering,</h4>
                <p>Please check your inbox for more information.</p>
                <p><strong>We hope to see you soon in the platform</strong></p>
            `
        },
        "RegisterNoBigStep2": {
            "errorsInForm": "There are errors in the form.",
            "emailExists": "This email address is already registered.",
        },
        "SelectCountry": {
            "title": "Country",
            "subtitle": "Please select your country",
            "save": "Save",
        },
        "LegacyCountry": {
            "title": "We're sorry..",
            "description": "This platform is no longer in use. You will be forwarded to the new website of Rare Disease Nexus.",
            "description_alt": "The currently requested country on this platform is no longer in use. You will be forwarded to the homepage.",
            "button": "Continue",
        },
        "Login": {
            "login": "Login",
            "loginAcc": "Login to your account",
            "errorMessage": "Wrong email or password",
            "successMessage": "Login successful",
            "returnToLoginForm": "Return to login form",
            "featureRequiresLogin": "In order to use this feature you should be logged in.",
        },
        "Forgot": {
            "title": "Forgot Your Password?",
            "fillEmail": "Please fill in your email address",
            "sentEmail": `
                <p class="vue-modal__password-reset-success"><strong>We sent you an email,</strong></p>
                <p class="vue-modal__password-reset-success">please check your inbox to continue.</p>
            `,
            "resetErrorMessage": "This email does not exist in our records",
            "resetSuccessMessage": "Password reset successful",
            "setUpNew": "Set up new password",
            "setUp": "Set up your password",
            "goTo": "Go to the video platform"
        },
        "UserAccount": {
            "errorMessage": "Saving your data failed. Please try again.",
            "successMessage": "Your data have been saved successfuly",
            "contactUsLink": "Contact us in order to change your {{input}}",
            "update": "Update",
            "logout": "Logout",
            "contactUs": "Contact us",
            "requestEmail": "I would like to change my email address",
            "requestCountry": "I would like to change my country",
            "placeholderEmailMessage": "Please write your new email address and any additional comments",
            "placeholderCountryMessage": "Please write your new country and any additional comments",
            "msgThanks": `
                <p><b>Thank you for your message,</b></p>
                <p>we will get back to you as soon as</p>
                <p>possible via email</p>
            `,
        },
        "NotFound": {
            "notFound": "page not found",
            "back": "Take me back",
        },
        "Favorites": {
            "favoriteVideos": "Favorite videos",
            "iconText": "Use the heart icon to add your favorite videos",
            "add": "Add to favorites",
            "remove": "Remove from favorites",
        },
        "Home": {
            "title": "Home",
            "favorites": "Favorites",
        },
        "VideoChannel": {
            "title": "Video Channel",
            "featuredVideos": "Featured videos",
            "featuredVideosSlogan": "Our top picks for you",
            "newUploads": "New uploads",
            "newUploadsSlogan": "We make sure our content is<br><strong>up to date</strong>",
            "moreVideos": "More videos",
            "moreVideosSlogan": "There is so much more we want<br><strong>to show you</strong>",
            "allVideos": "All videos",
        },
        "Diagnostics": {
            "confirmRequestTest": "Please confirm if you want to process this request.",
            "confirmContactSpecialist": "Are you sure you want to contact a Sanofi Genzyme specialist?",
        },
        "GetInTouch": {
            "discussCase": "Discuss Case With Expert",
            "findExpert": "Please enter the following information to find expert best suited to your needs.",
            "region": "Region",
            "country": "Country",
            "disease": "Disease",
            "consultationType": "Consultation type",
            "specialistWillContact": "A Sanofi Genzyme Specialist will contact you shortly.",
            "southAfricanSpecialistWillContact": "A Sanofi Medical Specialist will contact you shortly.",
        },
        "Notifications": {
            "title": "Notifications",
            "hasNew": "We uploaded <span class=\"count-num\">{{notificationsCount}}</span> new videos for you.",
            "noNew": "You are already up to date with our latest videos.",
            "clear": "clear",
        },
        "Languages": {
            "en_US": "English",
            "fr_Fr": "French",
            "es-Es": "Spanish",
            "pt_Br": "Portuguese"
        },
        "SingleVideo": {
            "watch": "Watch Video",
            "related": "Related Videos",
            "relatedUk": "Related Videos/Podcasts",
            "msgTitle": "Do you have a question regarding this video?",
            "msgPlaceholder": "You can write your comments or questions here...",
            "msgThanks": `
                <p><b>Thank you for your message,</b></p>
                <p>we will get back to you as soon as possible via email</p>
            `,
            "notFound": "Video not found",
            "cannotBeDisplayed": "Video cannot be displayed",
            "notSupported": "Selected type of video is not supported",
            "settingMissing": "One of the required video settings is missing",
        },
        "Footer": {
            "follow": "Follow us",
            "contact": "Contact",
            "sitemap": "Sitemap",
            "legal": "Legal notice",
            "privacy": "Privacy & Data Protection",
            "cookies": "Cookies policy",
            "rights": "All rights reserved",
            "corporate": "Corporate code of ethics",
            "websites": "Our websites",
            "publications": "Publications",
            "zinc": "Promomats",
            "cookiesText": "This website uses cookies to track its audience and improve its content. By continuing to browse this website, you agree to the use of such cookies.",
            "cookiesMore": "More information on Cookies",
            "cookiesOK": "OK",
            "siteIntendedFor": "For Healthcare Professionals only",
        },
        "Rating": {
            "welcomeTitle": "We would love to hear what you think about our video platform",
            "welcomeText": "Can you please answer a few questions - it will only take a couple of minutes",
            "btnStartRate": "SURE",
            "btnDenyRate": "No thanks",
            "ratingInfoText": "Click on the stars to go to the next question",
            "rateVeryUnlikely": "Very Unlikely",
            "rateUnlikely": "Unlikely",
            "rateNeutrally": "Neutrally",
            "rateLikely": "Likely",
            "rateVeryLikely": "Very Likely",
            "thanksTitle": "<strong>Thank you</strong> for your feedback,",
            "thanksText": "We appreciate you taking the time to answer our questions."
        },
        "ChannelComingSoon": {
            "comingSoon": "Coming Soon",
            "willAddSoon": "We will add videos for this channel soon.",
            "letYouKnow": "We'll let you know as soon as they are available.",
        },
        "Diseases": {
            "gaucher": {
                "fullName": "Gaucher Disease",
                "freeDiagnosticTest": "Request your diagnostic test for Gaucher Disease.",
                "contactSpecialist": "Contact Sanofi specialist about Gaucher Disease.",
                "personImage": "/site/templates/img/diseases/default/gaucher.jpg",
            },
            "fabry": {
                "fullName": "Fabry Disease",
                "freeDiagnosticTest": "Request your diagnostic test for Fabry Disease.",
                "contactSpecialist": "Contact Sanofi specialist about Fabry Disease.",
                "personImage": "/site/templates/img/diseases/default/fabry.jpg",
            },
            "pompe": {
                "fullName": "Pompe Disease",
                "freeDiagnosticTest": "Request your diagnostic test for Pompe Disease.",
                "contactSpecialist": "Contact Sanofi specialist about Pompe Disease.",
                "personImage": "/site/templates/img/diseases/default/pompe.jpg",
            },
            "mps1": {
                "fullName": "Mucopolysaccharidoses",
                "freeDiagnosticTest": "Request your diagnostic test for MPS.",
                "contactSpecialist": "Contact Sanofi specialist about MPS.",
                "personImage": "/site/templates/img/diseases/default/mps1.jpg",
            },
            "moreInformationInludingBrochure": "Request more information. Including brochure.",
            "listEmpty": "The diseases list is empty",
        },
        "Events": {
            "allEvents": "All events",
            "details": "Event details",
            "upcomingEvents": "Upcoming events",
            "registerForTheEvent": "Register for the event",
            "speakers": "Speakers",
            "agenda": "Agenda",
            "moreAboutMe": "More about me",
            "successfullyRegistered": "Thank you for registering",
            "registrationEmailSent": "An email has been sent to you as a confirmation of your registration."
        },
        "Elearning": {
            "details": "Elearning details",
            "finished": "Elearning finished",
            "accredited": "Accredited",
            "introText": "Start learning and earn accreditation points today",
            "doesNotExist": "This elearning does not exist",
            "startElearning": "Start the e-Learning",
            "preExam": "Pre video test questions",
            "postExam": "Post video test questions",
            "examFinished": "Test finished",
            "examFailed": "You failed...",
            "examPassed": "Congratulations, you passed",
            "examComplete": "Complete test",
            "examRetake": "Retake test",
            "watchAgain": "Watch again",
            "attemptsLeft": "Attempts left",
            "accreditationProcess": `
                <ol class="u-ordered-list">
                    <li>Before this e-Learning module begins, we ask that you take a brief quiz. These results are not scored.</li>
                    <li>Following this pre-test will be the video for you to view.</li>
                    <li>After viewing the video, you will then take a post-exam. The answers you provide are evaluated.</li>
                    <li>When you have completed the test, view your results! To gain accreditation you must score <strong>70%</strong> or higher.</li>
                </ol>
            `,
            "savingTheProgress": "Saving the progress...",
            "examLoadingResults": "Calculating results",
            "noAttemptsLeft": "Unfortunately, there are no more attempts left and access to video has been limited.",
            "goBackToListing": "See all e-learnings",
            "accredited": "Accredited",
            "completed": "Completed",
            "disabled": "Inactive",
            "points": "Points"
        },
        "ExitModal": {
            "nowLeaving": "You are now leaving the {{ hostname }} website",
            "content": `
                <p>By clicking on this link, you will be leaving this Sanofi hosted website, and,
                depending on the link, will be redirected to either another Sanofi website (for example, to view the Sanofi Privacy Policy) or an independent third-party website.</p>
                <p>Sanofi has no control over the content or availability of third-party sites or resources that are linked to,
                and accepts no liability or responsibility for them or for any loss or damage that may arise from your use of them.
                The existence of a link between this website and any third party site or resource in no way implies that Sanofi in any way approves of the content of these sites or resources,
                or any use to which such content maybe put.</p>
                <p>Please ensure that you read the legal and privacy policy sections of any website to which you link.</p>`,
            "proceed": "Proceed",
        },
        "Activities": {
            'title': 'Activities',
            'upcomingEventsTitle': 'Upcoming Events',
            'pastEventsTitle': 'Past Events',
            'eventTitleLabel': 'Event Title',
            'eventStatusLabel': 'Status',
            'eventRegistrationLabel': 'Registration',
            'accreditations': 'E-Learnings',
            'eventWatchTimeLabel': 'Watch time',
            'accreditationResultLabel': 'Result',
            'accreditationPassed': 'Passed',
            'accreditationFailed': 'Failed',
            'accreditationPointsLabel': 'Accred. points',
            'dateLabel': 'Date',
            'emailOptin': 'Future email communication',
            'lastLogin': 'Last Login:',
        },
    };
}

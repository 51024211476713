var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "elearning",
      class: {
        completed: _vm.entry.isCompleted,
        disabled: _vm.entry.isDisabled
      }
    },
    [
      _c(
        "card",
        {
          attrs: {
            href: _vm.elearningDetailsUrl,
            tag: _vm.entry.accredited ? _vm.$t("Elearning.accredited") : null,
            title: _vm.entry.title,
            description: _vm.entry.description,
            "cover-image": _vm.entry.thumbnail_url,
            "marked-by-icon": _vm.entry.finished ? "check-circle" : null,
            "marked-by-text": _vm.entry.finished
              ? _vm.$t("Elearning.finished")
              : null,
            "is-covered": _vm.isCovered,
            "cover-icon": "play",
            "tag-icon": "graduation-cap",
            "only-logged-in": ""
          }
        },
        [
          _vm.entry.is_accredited && !_vm.entry.isDisabled
            ? _c("div", { staticClass: "b-accreditation-mark" }, [
                _vm.entry.isCompleted
                  ? _c("span", { staticClass: "e-accreditation-mark--title" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Elearning.completed").toUpperCase())
                      )
                    ])
                  : _c("span", { staticClass: "e-accreditation-mark--title" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Elearning.accredited").toUpperCase())
                      )
                    ]),
                _vm._v(" "),
                _c("div", { staticClass: "e-accreditation-mark--stamp" }, [
                  _c("p", [
                    _c(
                      "span",
                      { staticClass: "e-accreditation-mark--stamp-mark" },
                      [_vm._v(_vm._s(_vm.entry.accreditation_points))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "e-accreditation-mark--stamp-note" },
                      [_vm._v(_vm._s(_vm.$t("Elearning.points").toUpperCase()))]
                    )
                  ])
                ])
              ])
            : _vm.entry.isDisabled
              ? _c("div", { staticClass: "b-accreditation-mark" }, [
                  _c("span", { staticClass: "e-accreditation-mark--title" }, [
                    _vm._v(_vm._s(_vm.$t("Elearning.disabled").toUpperCase()))
                  ])
                ])
              : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
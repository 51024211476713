<template>

    <a
        class="b-tile"
        :class="[
            (layout) ? `b-tile--${layout}` : '',
            (theme) ? `b-tile--${theme}` : '',
        ]"
        :href="href"
        @click="(event) => handleClick(event, id)"
    >
        <div class="b-tile__image" v-if="image">
            <img :src="image">
        </div>

        <div class="b-tile__content">
            <div>
                <p class="h6 b-tile__content__subtitle" v-if="subtitle" v-html="subtitle"></p>
                <h4 v-if="headline" v-html="headline"></h4>
                <p class="b-tile__content__summary" v-if="summary" v-html="summary"></p>
            </div>
        </div>
    </a>

</template>


<script>

export default {
    name: 'Tile',

    props: {
        id: [String, Number],
        image: String,
        headline: String,
        subtitle: String, // Small header placed before headline
        summary: String,
        href: String,
        layout: String, // Overall look of the tile
        theme: String, // Color theme
    },

    methods: {
        handleClick(event, id) {
            if (!this.href) {
                event.preventDefault();
            }

            this.$emit('tile-click-action', id);
        },
    }
}

</script>

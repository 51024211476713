var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "c-site-footer" }, [
    _c("div", { staticClass: "c-site-footer__top" }, [
      _c("div", { staticClass: "container-xl" }, [
        _c("div", { staticClass: "c-site-footer__row" }, [
          _c("img", {
            staticClass: "c-site-footer__logo",
            attrs: { src: "/site/templates/img/general/sanofi-logo-white.png" }
          }),
          _vm._v(" "),
          _vm.socialMediaLinks.length
            ? _c(
                "div",
                { staticClass: "c-site-footer__social" },
                [
                  _c("span", { staticClass: "c-site-footer__column" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("Footer.follow")) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.socialMediaLinks, function(
                    socialMediaLink,
                    socialMediaLinkIndex
                  ) {
                    return _c(
                      "a",
                      {
                        key: socialMediaLinkIndex,
                        attrs: {
                          href: socialMediaLink.url,
                          title: socialMediaLink.name,
                          target: "_blank"
                        }
                      },
                      [
                        _c("icon", {
                          attrs: { glyph: socialMediaLink.icon, "is-brand": "" }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-site-footer__bottom" }, [
      _c("div", { staticClass: "container-xl" }, [
        _c("div", { staticClass: "c-site-footer__row" }, [
          _vm.footerLinks
            ? _c(
                "nav",
                { staticClass: "c-site-footer__column c-site-footer__nav" },
                _vm._l(_vm.footerLinks, function(footerLink) {
                  return _c(
                    "a",
                    {
                      key: footerLink.url,
                      attrs: { href: footerLink.url, target: "_blank" }
                    },
                    [_vm._v(_vm._s(footerLink.label))]
                  )
                }),
                0
              )
            : _c(
                "nav",
                { staticClass: "c-site-footer__column c-site-footer__nav" },
                [
                  _c("a", { attrs: { href: "/legal-notice" } }, [
                    _vm._v(_vm._s(_vm.$t("Footer.legal")))
                  ]),
                  _vm._v(" "),
                  _c("a", { attrs: { href: "/privacy-policy" } }, [
                    _vm._v(_vm._s(_vm.$t("Footer.privacy")))
                  ]),
                  _vm._v(" "),
                  _c("a", { attrs: { href: "/cookies-policy" } }, [
                    _vm._v(_vm._s(_vm.$t("Footer.cookies")))
                  ])
                ]
              ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "text-right c-site-footer__column c-site-footer__text"
            },
            [
              _vm.footerText
                ? _c("div", { domProps: { innerHTML: _vm._s(_vm.footerText) } })
                : _vm._e(),
              _vm._v(" "),
              _vm.metas.zincCode || this.zinc
                ? _c("p", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(this.zinc || _vm.metas.zincCode) +
                        "\n                    "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Copyright© Sanofi " +
                    _vm._s(new Date().getFullYear()) +
                    ". " +
                    _vm._s(_vm.$t("Footer.rights"))
                )
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./SingleTextPage.vue?vue&type=template&id=394178fa&"
import script from "./SingleTextPage.vue?vue&type=script&lang=js&"
export * from "./SingleTextPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "SingleTextPage.vue"
export default component.exports
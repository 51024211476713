<template>

    <div class="c-video-player">
        <div ref="video"></div>
    </div>

</template>


<script>
import useDebounce from '../../utility/debounce';
import { loadScript } from '../../utility/loadExternalResource';

const VIMEO_API_SCRIPT_URL = 'https://player.vimeo.com/api/player.js';
const VIMEO_VIDEO_PLAYER_URL = 'https://vimeo.com/';

export default {
    name: 'VideoPlayer',

    props: {
        videoId: Number|String,
        videoUrl: String,
        controls: {
            type: Boolean,
            default: true,
        },
        background: Boolean, // Hides controls, disables ability to click on the video to play it and autoplays vid.
        autoplay: Boolean,
    },

    data() {
        return {
            player: null,
            isPlayerReady: false,
            isPlaying: false,
            playbackSeconds: 0,
            playbackProgress: 0, // Percent
            playbackDuration: 0,
        }
    },

    computed: {
        url() {
            return (this.videoId)
                ? `${VIMEO_VIDEO_PLAYER_URL}${this.videoId}`
                : this.videoUrl;
        },
    },

    watch: {
        isPlaying(state) {
            this.$emit('state-changed', state);
        },

        playbackDuration(duration) {
            this.$emit('duration-changed', duration);
        },
    },

    methods: {
        initVimeoPlayer() {
            this.player = new Vimeo.Player(this.$refs.video, {
                url: this.url,
                controls: this.controls,
                background: this.background,
                autoplay: this.autoplay,
            });

            this.player.ready().then(() => {
                this.player.getDuration().then((duration) => {
                    this.isPlayerReady = true;
                    this.playbackDuration = duration;

                    this.$emit('ready', true);
                });

                this.player.on('timeupdate', (time) => {
                    this.playbackSeconds = time.seconds;
                    this.playbackProgress = time.percent;

                    // { seconds, percent, duration }
                    this.$emit('time-changed', time);
                });

                this.player.on('play', () => {
                    this.isPlaying = true;
                });

                this.player.on('pause', () => {
                    this.isPlaying = false;
                });
            });
        },

        play() {
            this.player.play();
            this.isPlaying = true;
        },

        replay() {
            this.player.setCurrentTime(0);
            this.play();
        },

        pause() {
            this.player.pause();
            this.isPlaying = false;
        },

        toggle() {
            (this.isPlaying) ? this.pause() : this.play();
        },

        setCurrentTime(seconds) {
            this.player.setCurrentTime(seconds);
        }
    },

    created() {
        // Check if Vimeo player exists in global scope. If not load the script.
        if (typeof Vimeo !== 'undefined') {
            this.$nextTick(() => {
                this.initVimeoPlayer();
            });
        } else {
            // Prevent of multiple loading vimeo player script when there is more that one video on one page.
            window.Vimeo = {};

            loadScript(VIMEO_API_SCRIPT_URL)
                .then(this.initVimeoPlayer)
                .catch(console.error);
        }
    },
}

</script>

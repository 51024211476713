<template>

    <div class="c-loader"></div>

</template>


<script>

export default {
    name: 'Loader',
}

</script>

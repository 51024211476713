var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-inline-radio-select form-group" },
    [
      _vm._l(_vm.filteredOptions, function(label, radioValue) {
        return Object.keys(_vm.filteredOptions).length > 1
          ? [
              _c("input", {
                key: "radio_" + radioValue,
                ref: radioValue,
                refInFor: true,
                staticClass: "c-inline-radio-select__radio",
                attrs: { type: "radio", name: _vm.name, id: radioValue },
                domProps: { value: radioValue },
                on: { change: _vm.onChange }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  key: "label_" + radioValue,
                  staticClass: "c-inline-radio-select__label",
                  attrs: { for: radioValue }
                },
                [_vm._v("\n            " + _vm._s(label) + "\n        ")]
              )
            ]
          : _vm._e()
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>

    <img
        :src="bannerImageSrc"
        :srcset="bannerImageSrcset"
        :alt="alt"
    >

</template>


<script>

export default {
    props: {
        /**
         * Url of image or array of srcset images provided in this form:
         * [{src: 'img1.jpg', size: 1680}, {src: 'img2.jpg', size: 480}]
         */
        src: {
            type: [Array, String],
            required: true,
        },
        alt: {
            type: String,
        },
    },

    computed: {
        bannerImageSrc() {
            return (Array.isArray(this.src))
                ? this.src[0].src
                : this.src;
        },

        bannerImageSrcset() {
            return (Array.isArray(this.src))
                ? this.src.map(entry => {
                    return `${entry.src} ${entry.size || 1}w`;
                }).join(',')
                : this.src;
        },
    },
}

</script>

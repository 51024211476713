var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        width: "500",
        height: "auto",
        name: _vm.modalName,
        scrollable: true,
        adaptive: true
      }
    },
    [
      _c("modal-action-buttons", { attrs: { "close-modal": _vm.modalName } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "b-modal-content" },
        [
          _vm.loginVisible
            ? _c("login-form", {
                on: {
                  "forgot-password": _vm.passwordReset,
                  register: _vm.register
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.resetVisible
            ? _c("reset-password-form", {
                on: { "go-back": _vm.forgotPasswordBack }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.setPasswordVisible ? _c("set-password") : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        width: "400",
        height: "auto",
        scrollable: "",
        adaptive: "",
        name: _vm.modalName
      }
    },
    [
      _c("modal-action-buttons", { attrs: { "close-modal": _vm.modalName } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "b-modal-content" },
        [
          _vm.success
            ? _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  attrs: { src: "/site/templates/img/general/paper-plane.svg" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "mt-4",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("SingleVideo.msgThanks"))
                  }
                })
              ])
            : _c("form-generator", {
                attrs: {
                  url: _vm.formEndpointUrl,
                  fields: _vm.formFields,
                  config: _vm.formConfig,
                  "additional-values": _vm.formAdditionalValues
                },
                on: { success: _vm.onSuccess }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    {
      class: {
        "mb-5": !_vm.isUk && _vm.currentUser
      },
      attrs: { id: "home" }
    },
    [
      _c("hero-banner", {
        attrs: {
          headline: _vm.headline,
          "scroll-down-target": "#under-banner-target"
        }
      }),
      _vm._v(" "),
      _c("div", { attrs: { id: "under-banner-target" } }),
      _vm._v(" "),
      _vm.currentUser && _vm.showEvents
        ? _c(
            "div",
            { staticClass: "t-bg-light pt-5 pb-5" },
            [
              _c("events-list-section", {
                attrs: {
                  title: _vm.$t("Events.upcomingEvents"),
                  events: _vm.featuredEvents,
                  "is-loading": _vm.isEventsLoading,
                  limit: 4,
                  "number-of-featured": 1
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "text-center mt-3" }, [
                _vm.eventsPageUrl
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-lg btn-primary",
                        attrs: { href: _vm.eventsPageUrl }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("Events.allEvents")) +
                            "\n                "
                        ),
                        _c("icon", {
                          staticClass: "ml-3",
                          attrs: { glyph: "arrow-alt-circle-right" }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isUk && _vm.currentUser
        ? _c(
            "div",
            [
              _c("video-list-section", {
                attrs: {
                  title: _vm.$t("VideoChannel.featuredVideos"),
                  videos: _vm.featuredVideos,
                  "is-loading": _vm.isVideosLoading,
                  limit: 3,
                  "number-of-featured": 1
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "text-center mt-3" }, [
                _vm.videoChannelPageUrl
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-lg btn-primary",
                        attrs: { href: _vm.videoChannelPageUrl }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("VideoChannel.allVideos")) +
                            "\n                "
                        ),
                        _c("icon", {
                          staticClass: "ml-3",
                          attrs: { glyph: "arrow-alt-circle-right" }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
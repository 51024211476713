var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "page-tiles" } },
    [
      _c("subpage-banner", {
        attrs: { header: _vm.$t("GetInTouch.discussCase") }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container-xl mt-5" },
        [
          _vm.currentUser
            ? [
                _c(
                  "div",
                  { staticClass: "u-narrow-content" },
                  [
                    _vm.isDataFetched
                      ? _c("form-generator", {
                          attrs: {
                            url: _vm.submitEndpointUrl,
                            fields: _vm.formFields,
                            "additional-values": _vm.formAdditionalValues,
                            config: {
                              title: _vm.$t("GetInTouch.findExpert")
                            }
                          }
                        })
                      : _c("loader")
                  ],
                  1
                )
              ]
            : _c("not-logged-user-message")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-video-list" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _vm._l(_vm.videos, function(video, index) {
          return [
            _c(
              "div",
              {
                key: video.id || index,
                staticClass: "mb-4",
                class: [
                  index < _vm.numberOfFeatured
                    ? "col-lg-6"
                    : "col-sm-6 col-lg-3"
                ]
              },
              [
                _c("video-card", {
                  attrs: {
                    video: video,
                    "is-covered": index < _vm.numberOfFeatured
                  },
                  on: {
                    "change-favorite": function($event) {
                      return _vm.$emit("change-favorite")
                    }
                  }
                })
              ],
              1
            )
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
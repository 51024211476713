var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { attrs: { id: "favorites" } },
    [
      _c("subpage-banner", {
        attrs: {
          header: _vm.$t("Favorites.favoriteVideos"),
          "banner-image-src": [
            { src: "/site/templates/img/general/favorites.jpg", size: "1920" }
          ]
        }
      }),
      _vm._v(" "),
      _vm.isLoading ? _c("loader", { staticClass: "mt-5 mb-5" }) : _vm._e(),
      _vm._v(" "),
      _vm.videos && _vm.videos.length
        ? _c(
            "div",
            [
              _c("video-list-section", {
                attrs: { videos: _vm.videos },
                on: { "change-favorite": _vm.fetchVideos }
              })
            ],
            1
          )
        : !_vm.isLoading
          ? _c(
              "div",
              { staticClass: "container-xl mt-5" },
              [
                _c("message-box", {
                  attrs: {
                    title: _vm.$t("Common.emptyList"),
                    message: _vm.$t("Favorites.iconText"),
                    icon: "heart"
                  }
                })
              ],
              1
            )
          : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>

    <modal
        width="800"
        height="auto"
        scrollable
        adaptive
        :click-to-close="clickToClose"
        :name="name"
    >
        <modal-action-buttons v-if="clickToClose" :close-modal="name" />

        <div class="b-modal-content">
            <!--
                Overview
            -->
            <div v-if="overview && !isOverviewVisited">
                <h4 class="mb-4">{{ $t('Common.overview') }}</h4>
                <div v-html="overview"></div>

                <hr class="mt-4">

                <div class="text-right">
                    <button class="btn btn-success" @click.prevent="markOverviewAsVisited">
                        {{ $t('Common.next') }}
                        <icon glyph="chevron-right" is-solid class="ml-2" />
                    </button>
                </div>
            </div>

            <!--
                Pre exam
            -->
            <div v-else>
                <h4 class="mb-4">{{ title }}</h4>

                <progress-steps
                    class="mb-4"
                    :steps-number="questions.length"
                    :current-step="currentQuestionNumber + 1"
                />

                <transition name="fade-in">
                    <div :key="currentQuestionNumber">
                        <p><strong>{{ currentQuestion.value }}</strong></p>

                        <form-radio-group
                            v-model="currentQuestionAnswer"
                            text-field="value"
                            value-field="id"
                            :options="currentQuestion.answers"
                        />
                    </div>
                </transition>

                <div class="u-spread-elements mt-4">
                    <button
                        v-if="currentQuestionNumber > 0"
                        class="btn"
                        @click.prevent="previousStep"
                    >
                        <icon glyph="chevron-left" is-solid class="mr-2" />
                        {{ $t('Common.previous') }}
                    </button>

                    <button
                        class="btn btn-success u-spread-elements__right"
                        :disabled="isProcessing || !currentQuestionAnswer"
                        @click.prevent="nextStep"
                    >
                        {{ $t('Common.next') }}
                        <icon glyph="chevron-right" is-solid class="ml-2" />
                    </button>
                </div>
            </div>
        </div>
    </modal>

</template>


<script>

import ModalActionButtons from '../Common/ModalActionButtons.vue';
import ProgressSteps from '../Common/ProgressSteps.vue';
import FormRadioGroup from '../Forms/FormRadioGroup.vue';

export default {
    name: 'ElearningExamModal',

    components: {
        ModalActionButtons,
        ProgressSteps,
        FormRadioGroup,
    },

    props: {
        overview: String,
        questions: {
            type: Array,
            required: true,
        },
        title: String,
        uuid: String,
        name: {
            type: String,
            default: 'exam-modal',
        },
        clickToClose: {
            type: Boolean,
            default: true
        },
    },

    data() {
        return {
            currentQuestionAnswer: "",
            answers: {},
            currentQuestionNumber: 0,
            isOverviewVisited: false,
            isProcessing: false,
        }
    },

    computed: {
        currentQuestion() {
            return this.questions[this.currentQuestionNumber];
        },
    },

    methods: {
        start() {
            this.$modal.show(this.name);
            this.currentQuestionNumber = 0;
            this.answers = {};
        },

        hide() {
            this.$modal.hide(this.name);
            this.isProcessing = false;
        },

        nextStep() {
            this.answers[this.currentQuestion.id] = {};
            this.currentQuestion.answers.forEach((answer) => {
                let result = answer.id === this.currentQuestionAnswer ? 1 : 0;
                this.answers[this.currentQuestion.id][answer.id] = result;
            });
            this.currentQuestionAnswer = "";
            if (this.currentQuestionNumber < this.questions.length - 1) {
                this.currentQuestionNumber++;
            } else {
                this.isProcessing = true;
                this.$emit('finished', {answers: this.answers, uuid: this.uuid});
            }
        },

        previousStep() {
            this.currentQuestionAnswer = "";
            if (this.currentQuestionNumber > 0) {
                this.currentQuestionNumber--;
            }
        },

        markOverviewAsVisited() {
            this.isOverviewVisited = true;
        },
    },
}

</script>

<template>

    <div class="c-filters">
        <!--
            Search input
        -->
        <label class="form-group c-filters__search">
            <input
                v-model="search"
                type="text"
                class="form-control"
                :placeholder="$t('Common.search')"
            >
            <i class="fas fa-undo" @click="clearSearch" v-if="search"></i>
            <i class="fas fa-search"></i>
        </label>

        <!--
            Disease select
        -->
        <div class="form-group">
            <custom-select
                v-model="disease"
                class="c-filters__select"
                name="disease"
                :options="diseaseOptions"
                :label="$t('Common.filterDisease')"
                :show-reset="true"
            />
        </div>

        <!--
            Year select
        -->
        <div class="form-group">
            <custom-select
                v-model="year"
                class="c-filters__select"
                name="year"
                :options="yearOptions"
                :label="$t('Common.filterYear')"
                :show-reset="true"
            />
        </div>
    </div>

</template>


<script>

import CustomSelect from '../Common/CustomSelect.vue';

export default {
    name: 'Filters',

    components: {
        CustomSelect,
    },

    props: {
        videos: {
            type: Array,
            required: true
        },
    },

    data() {
        return {
            diseases: [],
            years: [],
            search: null,
            disease: null,
            year: null,
            activeItemFilter: '',
            preSelectedDisease: '',
            preSelectedYear: '',
        }
    },

    computed: {
        /**
         * Get all tags used in fetched videos.
         * structure:
         *
         * @returns {Object} {<tagId1>: <tagTitle2>, <tagId2>: <tagTitle2>, ...}
         */
        diseaseOptions() {
            return this.videos
                .map(entry => entry.tags)
                .flat()
                .reduce((stack, current) => {
                    if (!stack[current.id]) {
                        stack[current.id] = current.title;
                    }
                    return stack;
                }, {});
        },

        /**
         * Get event_date entries from fetched videos, convert them from UTC
         * to YYYY and filter off duplicates.
         *
         * @returns {Array} [<year1>, <year2>, ...]
         */
        yearOptions() {
            return this.videos
                .map(entry => this.parseYear(entry.event_date))
                .reduce((stack, current) => {
                    if (stack.indexOf(current) < 0) {
                        stack.push(current);
                    }
                    return stack;
                }, []);
        },

        /**
         * By default emit false to inform that user is not searching anything.
         * There is a difference between false (not searching)
         * and empty array (no results)
         * @return {Array|Boolean}
         */
        filteredVideos() {
            let filteredVideos = false;

            if (this.search || this.disease || this.year) {
                filteredVideos = [...this.videos];

                // Filtering process is arranged from the fastest to slowest

                if (this.year) {
                    filteredVideos = filteredVideos.filter(entry => {
                        return (parseInt(this.parseYear(entry.event_date)) === parseInt(this.year));
                    });
                }

                if (this.disease) {
                    let diseaseId = parseInt(this.disease);

                    filteredVideos = filteredVideos.filter(entry => {
                        return (entry.tags
                            .map(subEntry => subEntry.id)
                            .indexOf(diseaseId) > -1);
                    });
                }

                // Don't search one letter strings
                if (this.search && this.search.length > 1) {
                    filteredVideos = filteredVideos.filter(entry => {
                        return (entry.title.toLowerCase().indexOf(this.search) !== -1 || entry.speaker.toLowerCase().indexOf(this.search) !== -1);
                    });
                }
            }


            return filteredVideos;
        },
    },

    watch: {
        filteredVideos(newVal, old) {
            this.emitResult();
        },
    },

    methods: {
        emitResult() {
            this.$emit('filter', this.filteredVideos);
        },

        clearSearch() {
            this.search = null;
            this.emitResult();
        },

        parseYear(timestamp) {
            return new Date(timestamp).getFullYear();
        },
    },
}

</script>

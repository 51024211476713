var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-video-player" }, [
    !_vm.videoId || !_vm.accountId
      ? _c("div", { staticClass: "c-video-player__error" }, [
          _c("strong", [
            _vm._v(_vm._s(_vm.$t("SingleVideo.cannotBeDisplayed")))
          ]),
          _c("br"),
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("SingleVideo.settingMissing")) +
              "\n    "
          )
        ])
      : _c("div", { ref: "video" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>

    <div class="c-video-list">
        <div class="row">
            <template v-for="(video, index) in videos">
                <div
                    class="mb-4"
                    :class="[((index < numberOfFeatured) ? 'col-lg-6' : 'col-sm-6 col-lg-3')]"
                    :key="video.id || index"
                >
                    <video-card
                        :video="video"
                        :is-covered="(index < numberOfFeatured)"
                        @change-favorite="$emit('change-favorite')"
                    />
                </div>
            </template>
        </div>
    </div>

</template>


<script>

import VideoCard from './VideoCard.vue';

export default {
    name: 'VideoList',

    components: {
        VideoCard,
    },

    props: {
        videos: {
            type: Array,
            required: true
        },
        numberOfFeatured: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            used: []
        }
    },

    methods: {
        setUsed(value) {
            this.used[value] = true;
        },
    },
}

</script>

<template>

    <message-box
        :title="$t('Login.featureRequiresLogin')"
        icon="user-circle"
    >
        <button
            class="btn btn-primary mt-3"
            @click="$modal.show('login-modal')"
        >{{ $t('Login.login') }}</button>

        <button
            class="btn btn-primary mt-3"
            @click="$modal.show('register-modal')"
        >{{ $t('User.register') }}</button>
    </message-box>

</template>


<script>

export default {
    name: 'NotLoggedUserMessage',
}

</script>

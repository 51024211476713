<template>

    <main id="favorites">
        <subpage-banner
            :header="$t('Favorites.favoriteVideos')"
            :banner-image-src="[
                {src: '/site/templates/img/general/favorites.jpg', size: '1920'},
            ]"
        />

        <!-- <div class="favorites-banner">
        </div> -->

        <loader
            v-if="isLoading"
            class="mt-5 mb-5"
        />

        <div
            v-if="videos && videos.length"
        >
            <video-list-section
                :videos="videos"
                @change-favorite="fetchVideos"
            />
        </div>

        <div
            v-else-if="!isLoading"
            class="container-xl mt-5"
        >
            <message-box
                :title="$t('Common.emptyList')"
                :message="$t('Favorites.iconText')"
                icon="heart"
            />
        </div>
    </main>

</template>


<script>

import Service from '../../service';

import SubpageBanner from '../SubpageBanner/SubpageBanner.vue';
import VideoListSection from '../Video/VideoListSection.vue';

export default {
    name: 'Favorites',

    components: {
        SubpageBanner,
        VideoListSection,
    },

    data() {
        return {
            isLoading: false,
            videos: [],
        }
    },

    methods: {
        async fetchVideos() {
            this.isLoading = true;
            this.videos = await Service.getFavoriteVideos();
            this.isLoading = false;
        }
    },

    created() {
        this.fetchVideos();
    },
}

</script>

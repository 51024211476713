var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        width: "800",
        height: "auto",
        scrollable: "",
        adaptive: "",
        "click-to-close": _vm.clickToClose,
        name: _vm.name
      }
    },
    [
      _vm.clickToClose
        ? _c("modal-action-buttons", { attrs: { "close-modal": _vm.name } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "b-modal-content" }, [
        _vm.overview && !_vm.isOverviewVisited
          ? _c("div", [
              _c("h4", { staticClass: "mb-4" }, [
                _vm._v(_vm._s(_vm.$t("Common.overview")))
              ]),
              _vm._v(" "),
              _c("div", { domProps: { innerHTML: _vm._s(_vm.overview) } }),
              _vm._v(" "),
              _c("hr", { staticClass: "mt-4" }),
              _vm._v(" "),
              _c("div", { staticClass: "text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.markOverviewAsVisited($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("Common.next")) +
                        "\n                    "
                    ),
                    _c("icon", {
                      staticClass: "ml-2",
                      attrs: { glyph: "chevron-right", "is-solid": "" }
                    })
                  ],
                  1
                )
              ])
            ])
          : _c(
              "div",
              [
                _c("h4", { staticClass: "mb-4" }, [_vm._v(_vm._s(_vm.title))]),
                _vm._v(" "),
                _c("progress-steps", {
                  staticClass: "mb-4",
                  attrs: {
                    "steps-number": _vm.questions.length,
                    "current-step": _vm.currentQuestionNumber + 1
                  }
                }),
                _vm._v(" "),
                _c("transition", { attrs: { name: "fade-in" } }, [
                  _c(
                    "div",
                    { key: _vm.currentQuestionNumber },
                    [
                      _c("p", [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.currentQuestion.value))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("form-radio-group", {
                        attrs: {
                          "text-field": "value",
                          "value-field": "id",
                          options: _vm.currentQuestion.answers
                        },
                        model: {
                          value: _vm.currentQuestionAnswer,
                          callback: function($$v) {
                            _vm.currentQuestionAnswer = $$v
                          },
                          expression: "currentQuestionAnswer"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "u-spread-elements mt-4" }, [
                  _vm.currentQuestionNumber > 0
                    ? _c(
                        "button",
                        {
                          staticClass: "btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.previousStep($event)
                            }
                          }
                        },
                        [
                          _c("icon", {
                            staticClass: "mr-2",
                            attrs: { glyph: "chevron-left", "is-solid": "" }
                          }),
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("Common.previous")) +
                              "\n                "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success u-spread-elements__right",
                      attrs: {
                        disabled: _vm.isProcessing || !_vm.currentQuestionAnswer
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.nextStep($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("Common.next")) +
                          "\n                    "
                      ),
                      _c("icon", {
                        staticClass: "ml-2",
                        attrs: { glyph: "chevron-right", "is-solid": "" }
                      })
                    ],
                    1
                  )
                ])
              ],
              1
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    _vm._l(_vm.list, function(tile, index) {
      return _c(
        "div",
        { key: index, staticClass: "col-lg-6 mb-5" },
        [
          _c("tile", {
            attrs: {
              id: tile.id,
              image: tile.image,
              headline: tile.headline,
              subtitle: tile.subtitle,
              summary: tile.summary,
              href: tile.href,
              layout: _vm.layout,
              theme: tile.theme
            },
            on: {
              "tile-click-action": function(payload) {
                return _vm.$emit("tile-click-action", payload)
              }
            }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
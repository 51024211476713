<template>

    <label
        class="c-custom-select form-group"
        :class="{'is-open': dropdownVisible, 'has-float-label': floatLabel}"
        @click="toggleOpen"
        v-click-outside="hideDropdown"
        tabindex="0"
    >
        <i
            class="c-custom-select__reset fas fa-undo"
            v-if="showReset && selected"
            @click.stop="clearSelected"
        ></i>

        <span class="float-label" v-if="floatLabel" v-show="selected">
            <span>{{ label }}</span>
        </span>

        <div class="c-custom-select__label">
            <span v-if="required">*</span>
            <span>{{ selectedLabel }}</span>
            <i class="fas fa-chevron-down"></i>
        </div>

        <ul>
            <template v-if="areOptionsArray">
                <li
                    v-for="(name, index) in options"
                    :key="index"
                    :class="{active: (name == selected)}"
                    @click="selectOption(name)"
                >
                    {{ name }}
                </li>
            </template>
            <template v-else>
                <li
                    v-for="(name, value, index) in options"
                    :key="index"
                    :class="{active: (selected == value)}"
                    @click="selectOption(value)"
                >
                    {{ name }}
                </li>
            </template>
        </ul>
    </label>

</template>


<script>

export default {
    name: 'CustomSelect',

    props: {
        name: {
            type: String,
            required: true
        },

        /**
         * Object {value1:'Name 1', value2:'Name 2', ...}
         * or Array of string ['Name 1', 'Name 2', ...]
         */
        options: {
            type: [Array, Object],
            required: true
        },
        label: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        default: {
            type: String
        },
        floatLabel: {
            type: Boolean
        },
        showReset: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            selected: null,
            dropdownVisible: false,
        }
    },

    computed: {
        areOptionsArray() {
            return Array.isArray(this.options);
        },

        selectedLabel() {
            return (this.selected)
                ? (this.areOptionsArray)
                    ? this.selected
                    : this.options[this.selected]
                : this.label;
        },
    },

    methods: {
        selectOption(newValue) {
            // Allow of changing option to one of available options.
            // Change can be done by key or value of option.
            for (let option in this.options) {
                if (option == newValue || this.options[option] == newValue) {
                    this.selected = (this.areOptionsArray) ? newValue : option;
                }
            }

            // Allow of binding v-model to this component
            this.$emit('input', this.selected);
        },

        toggleOpen() {
            this.dropdownVisible = !this.dropdownVisible;
        },

        hideDropdown() {
            this.dropdownVisible = false;
        },

        clearSelected() {
            this.selected = null;
            this.selectOption(null);
        },
    },

    directives: {
        'click-outside': {
            bind(el, binding, vNode) {
                // Provided expression must evaluate to a function.
                if (typeof binding.value !== 'function') {
                    const compName = vNode.context.name;
                    let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
                    if (compName) {
                        warn += `Found in component '${compName}'`
                    }
                }
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble;
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e)
                    }
                };
                el.__vueClickOutside__ = handler;

                // add Event Listeners
                document.addEventListener('click', handler)
            },

            unbind(el, binding) {
                // Remove Event Listeners
                document.removeEventListener('click', el.__vueClickOutside__);
                el.__vueClickOutside__ = null
            }
        }
    },

    created() {
        if (this.default) {
            this.selectOption(this.default);
        }
    },
}

</script>

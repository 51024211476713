<template>

    <div>
        <vimeo-player
            v-if="sourceType === 'vimeo'"
            v-on="$listeners"
            :video-id="video.vimeoId"
            :video-url="video.url"
            :controls="controls"
            ref="player"
        />
        <brightcove-player
            v-else-if="sourceType === 'brightcove'"
            v-on="$listeners"
            :video-id="video.external_id"
            :controls="controls"
            :account-id="video.brightcove.account_id"
            ref="player"
        />
        <div
            v-else
            class="c-video-player"
        >
            <div class="c-video-player__error">
                <strong>{{ $t('SingleVideo.cannotBeDisplayed') }}</strong><br>
                {{ $t('SingleVideo.notSupported') }}
            </div>
        </div>
    </div>

</template>


<script>

/**
 * This component is mostly an abstraction level for showing one of the available video services.
 * All event handlers applied to this component will be passed through to the child service component.
 * Child components should contain all methods that exists in this component
 * to be able of manipulating the video state.
 */

import BrightcovePlayer from './BrightcovePlayer.vue';
import VimeoPlayer from './VimeoPlayer.vue';

export default {
    components: {
        BrightcovePlayer,
        VimeoPlayer,
    },

    props: {
        video: {
            type: Object,
            required: true,
        },
        controls: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        /**
         * Little hack for differences in the API responses.
         * One of them responds with the type and other not.
         *
         * @returns String|null
         */
        sourceType() {
            if (this.video.source) {
                return this.video.source.toLowerCase();
            } else if (this.video.url && this.video.url.includes('vimeo')) {
                return 'vimeo';
            }
            return null;
        },

        player() {
            return this.$refs.player || {};
        },
    },

    methods: {
        play() {
            this.player.play();
        },

        pause() {
            this.player.pause();
        },

        replay() {
            this.player.replay();
        },

        toggle() {
            this.player.toggle();
        },

        setCurrentTime(seconds) {
            this.player.setCurrentTime(seconds);
        },
    },
}

</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "c-custom-checkbox" }, [
    _c("input", {
      staticClass: "form-control",
      attrs: {
        type: "checkbox",
        id: _vm.name,
        name: _vm.name,
        required: _vm.required
      },
      domProps: { checked: _vm.checked },
      on: { change: _vm.onChange }
    }),
    _vm._v(" "),
    _c("span", { staticClass: "c-custom-checkbox__marker" }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "c-custom-checkbox__label",
        class: { "font-weight-bold": _vm.isLarge }
      },
      [
        _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
        _vm._v(" "),
        _c("span", { domProps: { innerHTML: _vm._s(_vm.label) } })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>

    <form
        ref="form"
        class="c-default-form"
        method="POST"
        @submit.prevent="submit"
    >
        <!--
            Title
        -->
        <div class="c-default-form__title">
            <h4 v-if="config.title" class="c-default-form__title__header"><strong>{{ config.title }}</strong></h4>
            <p v-if="config.subtitle">{{ config.subtitle }}</p>
        </div>

        <transition name="fade-in">
            <message-box
                v-if="formSaved"
                icon="check-circle"
                icon-class="text-success"
                :title="successMessage"
            >
                <button class="btn btn-primary mt-3" @click.prevent="resetFormState">{{ $t('Common.ok') }}</button>
            </message-box>

            <div v-else>

                <!--
                    Errors
                -->
                <transition name="fade-in">
                    <div v-if="formErrors" class="alert alert-danger">
                        <p class="m-0">{{ errorMessage }}</p>
                        <small>
                            <ul class="m-0 text-left">
                                <template v-for="errors in Object.values(parsedErrors)">
                                    <li v-for="error in errors" :key="error">
                                        {{ error }}
                                    </li>
                                </template>
                            </ul>
                        </small>
                    </div>
                </transition>

                <transition name="fade-in">
                    <div v-if="emailsInvalid" class="alert alert-danger">
                        <small>{{ $t('Common.incorrectEmail') }}</small>
                    </div>
                </transition>

                <!--
                    Row with columns
                -->
                <div class="row">

                    <!--
                        Inputs placed in columns
                    -->
                    <template
                        v-for="(input, inputName) in fields"
                    >
                        <div
                            v-if="input.type == 'radio'"
                            class="col-12"
                            :key="inputName"
                        >
                            <inline-radio-select
                                v-model="fieldValues[inputName]"
                                :name="inputName"
                                :options="input.options"
                            />
                        </div>

                        <div
                            v-else-if="input.type == 'checkbox'"
                            :class="[(config.columns && !input.forceFullWidth) ? 'col-12 col-sm-6' : 'col-12']"
                            :key="inputName"
                        >
                            <checkbox
                                v-model="fieldValues[inputName]"
                                :key="inputName"
                                :name="inputName"
                                :label="input.label"
                                :checked="input.checked"
                                :required="input.required"
                                :is-large="input.isLarge"
                            />
                        </div>

                        <div
                            v-else-if="input.type == 'dropdown'"
                            :class="[(config.columns && !input.forceFullWidth) ? 'col-12 col-sm-6' : 'col-12']"
                            :key="inputName"
                        >
                            <custom-select
                                v-model="fieldValues[inputName]"
                                :label="input.label"
                                :name="inputName"
                                :options="input.options"
                                :default="input.default"
                                :float-label="true"
                            />
                        </div>

                        <div
                            v-else-if="input.type == 'textarea'"
                            class="col-12"
                            :key="inputName"
                        >
                            <label class="form-group has-float-label">
                                <span v-if="input.label">{{ input.label }}</span>
                                <textarea
                                    v-model="fieldValues[inputName]"
                                    :placeholder="input.placeholder"
                                    @blur="onInputBlur"
                                ></textarea>
                            </label>
                        </div>

                        <div
                            v-else-if="input.type == 'terms'"
                            class="col-12"
                            :key="inputName"
                        >
                            <div
                                v-html="input.content"
                                class="b-scrollable-text"
                            ></div>
                        </div>

                        <div
                            v-else
                            :class="[(config.columns && !input.forceFullWidth) ? 'col-12 col-sm-6' : 'col-12']"
                            :key="inputName"
                        >
                            <custom-text-input
                                v-model="fieldValues[inputName]"
                                :ref="inputName"
                                :type="input.type"
                                :name="inputName"
                                :label="input.label"
                                :default="input.default"
                                :required="input.required"
                                :disabled="input.disabled"
                                :placeholder="input.placeholder"
                                :tooltip="input.tooltip"
                                @blur="onInputBlur"
                            />
                        </div>
                    </template>
                </div>

                <!--
                    Info about required fields
                -->
                <p v-if="requiredFields.length == fields.length" class="p-2">
                    {{ $t('FormFields.allRequired') }}
                </p>
                <p v-else-if="requiredFields.length" class="p-2">
                    {{ $t('FormFields.someRequired') }}
                </p>

                <!--
                    Additional information can go here
                -->
                <slot name="footer"></slot>

                <!--
                    Action buttons
                -->
                <div class="c-default-form__actions">
                    <button
                        type="submit"
                        class="btn btn-primary c-default-form__actions__submit"
                        :disabled="!canSubmitForm || submitting"
                    >
                        <i class="fas fa-circle-notch fa-spin c-default-form__actions__loader" v-if="submitting"></i>
                        {{ config.submitTitle || $t('FormFields.submit') }}
                    </button>
                </div>

            </div>
        </transition>

        <!--
            Bottom links
        -->
        <div class="c-default-form__bottom" v-if="config.links && config.links.length">
            <ul class="b-inline-list">
                <li
                    v-for="(link, linkIndex) in config.links"
                    class="b-inline-list__item"
                    :key="linkIndex"
                >
                    <a
                        :href="link.url || '#'"
                        :class="{'font-weight-bold': link.isBold}"
                        @click="linkAction($event, link.action)"
                    >{{ link.title }}</a>
                </li>
            </ul>
        </div>
    </form>

</template>


<script>

import axios from 'axios';

import Checkbox from '../Common/CustomCheckbox.vue';
import CustomSelect from '../Common/CustomSelect.vue';
import CustomTextInput from '../Common/CustomTextInput.vue';
import InlineRadioSelect from '../Common/InlineRadioSelect.vue';

export default {
    name: 'FormGenerator',

    components: {
        Checkbox,
        CustomTextInput,
        InlineRadioSelect,
        CustomSelect,
    },

    props: {
        // If URL is not provided the form will always emit success when it's filled in correctly
        url: String,
        fields: {
            type: Object,
            required: true,
        },
        /**
         * You can pass here object {key:val, ...} and they will be also submitted.
         * Works simmilar to hidden inputs.
         */
        additionalValues: Object,
        config: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            submitting: false,
            formSaved: false,
            formErrors: false,
            emailsInvalid: false,
            fieldValues: {},
            bigNumData: false,
        }
    },

    watch: {
        fieldValues(val, oldVal) {
            this.verifyProvidedEmails();
        },
    },

    computed: {
        successMessage() {
            return this.config.successMessage || this.$i18n.i18next.t('FormFields.saveCompleted');
        },

        errorMessage() {
            return this.config.errorMessage || this.$i18n.i18next.t('FormFields.saveFailed');
        },

        requiredFields() {
            return Object.keys(this.fields)
                .filter(fieldName => this.fields[fieldName].required === true);
        },

        emailFields() {
            return this.requiredFields
                .filter(fieldName => this.fields[fieldName].type == 'email');
        },

        canSubmitForm() {
            const needToBeFilled = this.requiredFields.filter((field) => {
                if (this.fieldValues.hasOwnProperty(field) && this.fieldValues[field]) {
                    return false;
                }
                return true;
            });

            return !needToBeFilled.length;
        },

        /**
         * Parses the form errors returned by api calls and matches the error messages to these errors.
         * Allows for custom translatable local error messages.
         * If a specific error message is not provided it will use the FormFields.generalError string, replacing {{field}} with the field name.
         *
         * If the api results in errors like this:
         * ```
         * {
         *      email: {
         *          _isUnique: 'message does not matter',
         *      },
         *      country: 'invalid country',
         * }
         * ```
         *
         * and the config.errorMessages looks like this:
         * ```
         * {
         *      email: {
         *          _isUnique: 'this is a custom (translatable) error message.',
         *      },
         * }
         * ```
         *
         * parsed errors would be:
         * ```
         * {
         *      email: [
         *          'this is a custom (translatable) error message.',
         *      ],
         *      country: [
         *          'The country field is invalid.'
         *      ],
         * }
         * ```
         */
        parsedErrors() {
            if (!this.config.errorMessages || Array.isArray(this.formErrors)) {
                return {};
            }

            return Object.entries(this.formErrors).reduce((errs, [errorKey, errorValue]) => {
                // An error can either be a string (error directly) or an object with specific errors
                if (typeof errorValue === 'string') {
                    // If there is a default error message set for this field, use that as the error
                    if (this.config.errorMessages[errorKey] && this.config.errorMessages[errorKey].default) {
                        errs[errorKey] = [this.config.errorMessages[errorKey].default];
                        return errs;
                    }

                    // Use the general error if there is no default set for the error
                    errs[errorKey] = [this.$i18n.i18next.t('FormFields.generalError', { field: errorKey })];
                    return errs;
                }

                // Makes sure we only set 1 default message when no specific messages are available
                // multiple specific messages are allowed though
                let hasSetValue = false;

                // Map over all specific error keys for this field
                errs[errorKey] = Object.keys(errorValue)
                .map((key) => {
                    // If there is an error message set for this specific error, use that.
                    if (this.config.errorMessages[errorKey] && this.config.errorMessages[errorKey][key]) {
                        hasSetValue = true;
                        return this.config.errorMessages[errorKey][key];
                    }

                    // If the errored field has no error message set yet
                    if (!hasSetValue) {
                        hasSetValue = true;

                        // If there is a default for this errored field, use it
                        if (this.config.errorMessages[errorKey].default) {
                            return this.config.errorMessages[errorKey].default;
                        }

                        // Use general error if there is no default set for the error
                        return this.$i18n.i18next.t('FormFields.generalError', { field: errorKey });
                    }

                    return null;
                })
                .filter((message) => typeof message === 'string'); // Filter out non string values

                return errs;
            }, {});
        },

        hiddenValues() {
            return Object.entries(this.fields)
                .filter(([,field]) => field.type === 'hidden' && field.value)
                .reduce((fields, [name, field]) => {
                    fields[name] = field.value;
                    return fields;
                }, {});
        },

    },

    methods: {
        linkAction(event, action) {
            if (action) {
                event.preventDefault();
                this.$emit(action);
            }
        },

        verifyProvidedEmails() {
            for (let emailField of this.emailFields) {
                if (this.$refs[emailField][0].isInvalid) {
                    this.emailsInvalid = true;
                    return true;
                }
            }
            this.emailsInvalid = false;
            return false;
        },

        onInputBlur() {
            this.verifyProvidedEmails();
        },

        async submit() {
            this.resetFormState();

            this.submitting = true;

            const response = await axios.post(this.url, {...this.hiddenValues, ...this.fieldValues, ...this.additionalValues})
                .catch((error) => error.response || error);

            this.submitting = false;

            // If URL is not provided always succeed
            if (!this.url || (response && response.data && response.data.success === true)) {
                this.formSaved = true;
            } else {
                if (response.data.errors) {
                    this.formErrors = response.data.errors;
                } else if (response.statusText) {
                    this.formErrors = [response.statusText];
                } else {
                    this.formErrors = [this.errorMessage];
                }
            }

            this.$emit((this.formSaved) ? 'success' : 'error', {
                response,
                formModel: this.fieldValues,
            });

            return this.formSaved;
        },

        resetFormState() {
            this.submitting = false;
            this.formSaved = false;
            this.formErrors = false;
        },
    },
}

</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("subpage-banner", { attrs: { smaller: "" } }),
      _vm._v(" "),
      _c("main", { staticClass: "c-page-404" }, [
        _c("div", { staticClass: "c-page-404__top" }, [
          _c("h1", [_vm._v("404")]),
          _vm._v(" "),
          _c("h5", [_vm._v(_vm._s(_vm.$t("NotFound.notFound")))])
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "c-page-404__bottom" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { onClick: "location.href='/'" }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("NotFound.back")) +
                  "\n            "
              )
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-page-404__animation" }, [
      _c("div", { staticClass: "ghost-copy" }, [
        _c("div", { staticClass: "one" }),
        _vm._v(" "),
        _c("div", { staticClass: "two" }),
        _vm._v(" "),
        _c("div", { staticClass: "three" }),
        _vm._v(" "),
        _c("div", { staticClass: "four" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ghost" }, [
        _c("div", { staticClass: "face" }, [
          _c("div", { staticClass: "eye" }),
          _vm._v(" "),
          _c("div", { staticClass: "eye-right" }),
          _vm._v(" "),
          _c("div", { staticClass: "mouth" })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "shadow" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }